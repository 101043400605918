<script>
import * as Sentry from "@sentry/vue";

import ActivityEditor from '@/components/admin/activity/ActivityEditor'

export default {
  name: 'ActivityCreate',
  components:{
    ActivityEditor
  },
  data() {
    return {
      activity: undefined
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      const data = this.$store.getters['admin/activities/byID'](this.$route.params.id) || undefined
      this.activity = data
    },
    async deleteAction() {
      const ok = await this.$store.dispatch('admin/confirm', {
        title: this.$t('admin.activityPage.dialogs.deleteActivity.title'),
        message: this.$t('admin.activityPage.dialogs.deleteActivity.message', {
          type: this.$t(`admin.activity.type.${this.activity.type}`),
          name: this.$l(this.activity.content.name)
        })
      })
      if (!ok)
        return
      // console.log('DEL Q?', this.quiz, this.quiz.id)
      this.$store.commit('ui/setBusy', true)
      await this.$store.dispatch('admin/activities/delete', this.activity.id)
      this.$store.commit('ui/setBusy', false)
      this.$router.push({ name: 'Activities' })
    },
 
    async save() {
      this.loading = true
      this.$store.commit('ui/setBusy', true)
      let qdoc = null
      let qerr = null
      try {
        qdoc = await this.$store.dispatch('admin/activities/save', {
          ...this.activity
        })
      } catch (err) {
        Sentry.captureException(err)
        qerr = err
        // console.error('save error', err)
      }
      this.loading = false
      this.$store.commit('ui/setBusy', false)
      if (qdoc?.id) {
        this.$router.push({ name: 'ActivityEditor', params: {id: qdoc.id}})
      } else {
        this.$store.dispatch('admin/alert',{
          title: this.$t('admin.error.error'),
          message: `${this.$t('admin.error.saveFailed')}: ${qerr.toString()}`
        })
      }
    }
  },
  watch: {
    '$route.params.id'() {
      this.reload('route')
    },
    '$store.state.admin.activities.data'(next, prev) {
      //console.log('next store', next, prev)
      if (next && !Object.keys(prev || {}).length)
        this.reload('store')
    }
  },
}
</script>

<template>
  <div class="h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <div class="create-activity-wrapper h-full overflow-y-auto custom-scrollbar">
      <ActivityEditor
        v-model="activity"
        @save="save"
        @deleteMe="deleteAction"
        mode="edit"
      />
      <!--
      <div>upd: {{activity}}</div>
      -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-activity-wrapper{
  padding: 20px;
}
</style>
