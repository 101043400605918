<script>
/** TextField component requires because Vuetify 3 still doesn't has it */
import DropdownIcon from '@/assets/imgs/icons/dropdown-arror.svg'
import waitFor from '@/utils/waitFor'

export default {
  name: 'DropdownMenu',
  components: {
    DropdownIcon
  },
  props: {
    /** options set key => label */
    options: {
      type: Object,
      default: () => ({})
    },
    /** options icons set key => icon */
    icons: {
      type: Object,
      default: () => ({})
    },
    /** options classes set key => class */
    classes: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: Number,
      default: 80
    }
  },
  emits: [
    'select'
  ],
  data: () => ({
    open: false
  }),
  computed: {
    optionsList() {
      return Object.keys(this.options).map(key => ({
        value: key,
        label: this.options[key],
        cls: this.classes[key]
      }))
    },
    hasIcons() {
      return !!Object.keys(this.icons || {}).length
    }
  },
  methods: {
    async toggleDropdown() {
      await waitFor(0)
      if (this.disabled)
        return
      this.open = !this.open
    },
    clickOutside() {
      this.open = false
    }
  }
}
</script>

<template>
<v-btn @click="toggleDropdown" class="dropdown-wrapper">
  <DropdownIcon :class="{open: open}" class="toggle-button"/>
  <div v-if="open" class="dropdown-wrapper" :style="{minWidth: minWidth+'px'}" v-click-outside="clickOutside">
    <div class="dropdown-item" v-for="opt, i of optionsList" :key="i">
      <button @click.stop.prevent="$emit('select',opt.value); open = false;" class="dropdown-button pa-2" :class="opt.cls">
        <component v-if="hasIcons" :is="icons[opt.value]"/>
        {{opt.label}}
      </button>
    </div>
  </div>
</v-btn>
</template>

<style scoped lang="scss">

.dropdown-wrapper {
  position: relative;

  svg.toggle-button {
    fill: #ccc;

    &.open {
      fill: var(--v-theme-grey-2d);
    }
  }

  .dropdown-wrapper {
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: translate(0, calc(100% + 2px));
    width: 100%;
    padding: 10px 5px;
    box-shadow: #B1B1B158 0px 6px 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 10;
    background: #fff;

    .dropdown-item {

      .dropdown-button {
        width: 100%;
        text-align: left;
        padding: 4px 8px;
        border-radius: 4px;

        &:hover {
          color: #000;
          background-color: rgb(var(--v-theme-admin-grey-2));
        }

        svg {
          position: relative;
          top: 9px;
        }
      }
    }
  }
}
</style>
