<script>

import AdminVoiceOfGodSidebar from '@/components/admin/voiceofgod/AdminVoiceOfGodSidebar'
import AdminVoiceOfGodUserList from '@/components/admin/voiceofgod/AdminVoiceOfGodUserList'
import AdminVoiceOfGodContent from '@/components/admin/voiceofgod/AdminVoiceOfGodContent'

export default {
  name: 'VoiceOfGod',
  components:{
    AdminVoiceOfGodSidebar,
    AdminVoiceOfGodUserList,
    AdminVoiceOfGodContent
  },
  data: () => ({
    filter: -1,
    selected: [],
    sending: false
  }),
  computed:{
    usersList() {
      const list = this.$store.getters['admin/registered']
      return list
    }
  },
  methods:{
    updateFilter(next) {
      // console.log('set next filter', next)
      this.filter = next
    },
    updateSelected(next) {
      // console.log('update selected', next)
      this.selected = next
    },
    async sendMediaNotification(content) {
      if (!this.selected.length)
        return
      //const cnt = JSON.parse(JSON.stringify(content))
      this.sending = true
      const cnt = content
      console.log('sendMediaNotification', cnt)
      const details = await this.$store.dispatch('user/getVoiceOfGodDocument', cnt.recordingBlob);
      console.log('content', content)
      const data = JSON.parse(JSON.stringify({
        to: this.selected,
        content: {...details,voiceOfGod: true, title: cnt.notificationTitle},
        immidiate: content.immidiate
      }))

      await this.$store.dispatch('admin/pushMediaNotification',data)
      this.sending = false
    },
    async sendTextNotification(content) {
      if (!this.selected.length)
        return
      this.sending = true
      const data = JSON.parse(JSON.stringify({
        to: this.selected,
        content
      }))
      await this.$store.dispatch('admin/pushTextNotification',data)
      this.sending = false
     }
  }
}
</script>

<template>
  <div class="vog-wrapper h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <AdminVoiceOfGodSidebar :filter="filter" @update:filter="updateFilter" />
    <AdminVoiceOfGodUserList :filter="filter" @update:selected="updateSelected" />
    <AdminVoiceOfGodContent :sending="sending" :selectedUserLength="this.selected.length" @sendTextNotification="sendTextNotification" @sendMediaNotification="sendMediaNotification"  />
  </div>
</template>

<style lang="scss" scoped>
.vog-wrapper{
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-columns: 230px 280px minmax(500px, 1fr);
}
</style>
