<script>
// import IconBell from '@/assets/imgs/icons/admin/topbar-bell.svg'
import { Scene } from '@/lib/enums'

import CampusIcon from '@/assets/imgs/icons/navigation/courtyard.svg'
import ApartmentIcon from '@/assets/imgs/icons/navigation/apartment.svg'
import OfficeIcon from '@/assets/imgs/icons/navigation/office.svg'
import LoftIcon from '@/assets/imgs/icons/navigation/loft.svg'
import PoolIcon from '@/assets/imgs/icons/navigation/rooftop.svg'
import StoreIcon from '@/assets/imgs/icons/navigation/store.svg'
import WarehouseIcon from '@/assets/imgs/icons/navigation/warehouse.svg'

import NotificationButton from '@/components/notification/NotificationButton'

export default {
  name: 'AdminNavBar',
  components: {
    // IconBell,
    NotificationButton,
    CampusIcon,
    ApartmentIcon,
    OfficeIcon,
    LoftIcon,
    PoolIcon,
    StoreIcon,
    WarehouseIcon
  },
  data: () => ({
    menuOpen: false
  }),
  setup: () => ({ Scene }),
  methods: {
    closeMenu() {
      this.menuOpen = false
    }
  }
}
</script>

<template>
  <div class="d-flex justify-space-between align-center px-10 admin-navbar admin-shadow bg-white admin-rounded shadow">
      <h1 class="page-title">{{$t(`admin.pageTitle.${$route.meta.pageTitle}`)}}</h1>
    <div class="d-flex align-items-center">
      <NotificationButton class="admin-notification-button"/>
      <!--
      <v-btn flat icon elevation="0" class="mr-5">
        <IconBell/>
      </v-btn>
      -->
      <div class="user-avatar" :style="{
        backgroundImage: `url(${$store.state.user.data?.avatar})`
      }"/>
      <div class="user-info mx-2 d-flex flex-column justify-center">
        <div class="user-name">{{$store.state.user.data?.displayName}}</div>
        <div class="user-role">{{$t(`roles.${$store.state.user.data?.role||2}`)}}</div>
      </div>
      <div class="dropdown-container" v-click-outside="closeMenu">
        <v-btn @click="menuOpen = !menuOpen" flat icon="mdi-chevron-down" elevation="0"/>
        <div v-if="menuOpen" :class="{'d-none': !menuOpen}" class="dropdown-menu pa-3 rounded-lg bg-white elevation-2 d-flex flex-column">
          <router-link :to="{name: '3DPage', params: {scene: 'campus'}}" class="pa-2" target="_blank">
            <CampusIcon class="mr-2 icon-fill-black" />{{$t(`scenes.${Scene.Campus}`)}}
          </router-link>
          <router-link :to="{name: '3DPage', params: {scene: 'office'}}" class="pa-2" target="_blank">
            <OfficeIcon class="mr-2 icon-fill-black" />{{$t(`scenes.${Scene.Office}`)}}
          </router-link>
          <router-link :to="{name: '3DPage', params: {scene: 'store'}}" class="pa-2" target="_blank">
            <StoreIcon class="mr-2 icon-fill-black" />{{$t(`scenes.${Scene.Store}`)}}
          </router-link>
          <router-link :to="{name: '3DPage', params: {scene: 'pool'}}" class="pa-2" target="_blank">
            <PoolIcon class="mr-2 icon-fill-black" />{{$t(`scenes.${Scene.Pool}`)}}
          </router-link>
          <router-link :to="{name: '3DPage', params: {scene: 'loft'}}" class="pa-2" target="_blank">
            <LoftIcon class="mr-2 icon-fill-black" />{{$t(`scenes.${Scene.Loft}`)}}
          </router-link>
          <router-link :to="{name: '3DPage', params: {scene: 'apartment'}}" class="pa-2" target="_blank">
            <ApartmentIcon class="mr-2 icon-fill-black" />{{$t(`scenes.${Scene.Apartment}`)}}
          </router-link>
          <router-link :to="{name: '3DPage', params: {scene: 'warehouse'}}" class="pa-2" target="_blank">
            <WarehouseIcon class="mr-2 icon-fill-black" />{{$t(`scenes.${Scene.Warehouse}`)}}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.dropdown-container {
  position: relative;
  .dropdown-menu {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    min-width: 220px;
    z-index: 10;

    a {
      display: flex;
      color: #333;
      border-radius: 4px;
      &:hover {
        color: rgb(var(--v-theme-fb-navy));
        background-color: rgb(var(--v-theme-admin-grey-2));
      }
    }
  }
}
.user-avatar {
  width: 41px;
  height: 41px;
  border-radius: calc(41px/2);
  background-color: rgb(var(--v-theme-fb-navy-2));
  background-size: cover;
  background-position: center;
}
.user-name {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}
.user-role {
  font-size: 10px;
  line-height: 13px;
}
</style>
<style>
svg.icon-fill-black {
  width: 20px;
  height: 20px;
}
svg.icon-fill-black path {
  fill: #333 !important;
}
</style>
