<script>
import waitFor from '@/utils/waitFor'
import { Locale } from '@/lib/enums.js'

import FlagUS from '@/assets/imgs/icons/flags/usa.svg'
import FlagFR from '@/assets/imgs/icons/flags/france.svg'
import FlagES from '@/assets/imgs/icons/flags/spain.svg'

export default {
  name: 'LocalizedTextField',
  components: {
    FlagUS,
    FlagFR,
    FlagES
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        [Locale.en]: '',
        [Locale.es]: '',
        [Locale.fr]: '',
      }),
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off',
      validator: value => ['on', 'off'].includes(value)
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:modelValue'
  ],
  data() {
    return {
      val: this.modelValue || {
        [Locale.en]: '',
        [Locale.es]: '',
        [Locale.fr]: '',
      },
      open: false,
      locale: Locale.en
    }
  },
  watch: {
    modelValue(next) {
      this.val = next
    },
    val: {
      deep: true,
      handler(next) {
        this.$emit('update:modelValue', next)
      }
    }
  },
  computed: {
    slocale() {
      return Locale[this.locale]
    }
  },
  setup: () => ({ Locale }),
  methods: {
    async toggleLocalesSelector() {
      await waitFor(10)
      this.open = !this.open
    },
  }
}
</script>

<template>
<div class="field-wrapper">
  <input
    type="text"
    :disabled="disabled"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :class="{
      'error': error
    }"
    v-model="val[locale]"
  />
  <div class="locale-selector">
    <v-btn :disabled="$store.state.ui.busy" icon flat elevation="0" @click="toggleLocalesSelector">
      <FlagUS v-if="locale === Locale.en"/>
      <FlagFR v-if="locale === Locale.fr"/>
      <FlagES v-if="locale === Locale.es"/>
    </v-btn>
    <div v-if="open" class="d-flex flex-column elevation-2 bg-white rounded-lg">
      <v-btn v-if="locale !== Locale.en" icon flat elevation="0" @click="locale = Locale.en; open = false">
        <FlagUS/>
      </v-btn>
      <v-btn v-if="locale !== Locale.fr" icon flat elevation="0" @click="locale = Locale.fr; open = false">
        <FlagFR/>
      </v-btn>
      <v-btn v-if="locale !== Locale.es" icon flat elevation="0" @click="locale = Locale.es; open = false">
        <FlagES/>
      </v-btn>
    </div>
  </div>

</div>
</template>

<style scoped lang="scss">
input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  padding: 14px;
  background: #fff;
  box-shadow: #B1B1B158 0px 6px 20px;
  z-index: 1;

  &.error {
    color: rgb(var(--v-theme-error)) !important;
    border: 1px solid rgb(var(--v-theme-error)) !important;
  }
}

input:focus {
  border: 0 !important;
}

.field-wrapper {
  position: relative;

  .locale-selector {
    position: absolute;
    top: 3px;
    right: 20px;
    cursor: pointer;
    fill: #ccc;
    z-index: 2;

    // &.open {
      // fill: var(--v-theme-grey-2d);
    // }
  }

}
</style>
