<script>
//import IconCalender from "@/assets/imgs/icons/admin/email/fb-calender.svg"
import IconMail from "@/assets/imgs/icons/admin/email/fb-mail.svg"
//import IconLabel from "@/assets/imgs/icons/admin/email/fb-label.svg"


export default {
  name: 'AdminEmailSidebar',
  components:{
    IconMail,
    //IconCalender,
    //IconLabel
  },
  data(){
    return{
      labels: [
        {
          id: 1,
          name : "Urgent",
          color : "#FF5E4B"
        },
        {
          id: 2,
          name : "Work",
          color : "#FFE01B"
        },
        {
          id: 3,
          name : "Friends",
          color : "#3DA7BD"
        },
        {
          id: 4,
          name : "Family",
          color : "#B6D38C"
        },
        {
          id: 5,
          name : "Webinars",
          color : "#FFC6C6"
        }
      ]
    }
  }
}
</script>

<template>
  <div class="email-sidebar d-flex flex-column overflow-hidden">
      <div class="email-sidebar-header px-5 py-6">
        <h3>{{$t('admin.emailPage.sidebar.labels.mailbox')}}</h3>
      </div>
      <div class="email-sidebar-body flex-grow-1 overflow-y-auto custom-scrollbar px-5">
        <router-link class="email-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" :to="{name: 'EmailCreate'}">
          <IconMail class="mr-4 flex-shrink-0" />
          <span class="text-truncate">{{$t('admin.emailPage.sidebarLinks.newMessage')}}</span>
        </router-link>
        <router-link class="email-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" :to="{name: 'ActivityInvitationEmail'}">
          <IconMail class="mr-4 flex-shrink-0" />
          <span class="text-truncate">{{$t('admin.emailPage.sidebarLinks.sendActivityInvitation')}}</span>
        </router-link>
        <router-link class="email-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" :to="{name: 'ActivityFollowupEmail'}">
          <IconMail class="mr-4 flex-shrink-0" />
          <span class="text-truncate">{{$t('admin.emailPage.sidebarLinks.sendActivityFollowupInvitation')}}</span>
        </router-link>
        <!-- <router-link class="email-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" :to="{name: 'SentEmails'}">
          <IconCalender class="mr-4 flex-shrink-0" />
          <span class="text-truncate">{{$t('admin.emailPage.sidebarLinks.sent')}}</span>
        </router-link>
        <h3 class="mt-5 mb-2">{{$t('admin.emailPage.sidebar.labels.labels')}}</h3>
        <router-link v-for="label in labels" :key="label.id" class="email-sidebar-label-link d-flex align-items-center mx-n5 px-5 py-3" :to="{name: 'SentEmails'}" :style="'color:' + label.color">
          <IconLabel class="mr-4 flex-shrink-0" />
          <span class="text-truncate">{{ label.name }}</span>
        </router-link> -->
      </div>
    </div>
</template>

<style lang="scss" scoped>
.email-sidebar-link{
  color: inherit;
  &:active{
    color: inherit;
  }
  &:hover{
    background-color: rgb(var(--v-theme-admin-grey-3));
  }
  &.router-link-active{
    background-color: rgb(var(--v-theme-fb-navy));
    color: #fff;
    border-radius: 0 8px 8px 0;
  }
}
.email-sidebar-label-link{
  &:active{
    color: inherit;
  }
  &:hover{
    background-color: rgb(var(--v-theme-admin-grey-3));
  }
}
</style>
