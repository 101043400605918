<script>
import CKEditorField from '@/components/fields/LocalizedCKEditor'
import LocalizedTextField from '@/components/fields/LocalizedText'
import TextField from '@/components/fields/Text'

export default {
  name: 'ActivitySessionMap',
  components:{
    CKEditorField,
    LocalizedTextField,
    TextField,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        name: {},
        description: {},
        links: [],
      })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data(){
    return {
      content: this.modelValue
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler(next) {
        this.content = next
      }
    },
    content: {
      deep: true,
      handler(next) {
        //console.log(next)
        this.$emit('update:modelValue', next)
      }
    }
  },
  created() {
    let upd = false
    if (!this.content.name) {
      this.content.name = {}
      upd = true
    }
    if (!this.content.description) {
      this.content.description = {}
      upd = true
    }
    if (!this.content.links) {
      this.content.links = []
      upd = true
    }
    if (upd)
      this.$emit('update:modelValue', this.content)
  },
  setup() {
    return {}
  },
  methods: {
    deleteMedia() {
      console.log('delete media')
    }
  }
}
</script>

<template>
  <div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.name') }}
      </div>
      <div>
        <LocalizedTextField :modelValue="content.name" />
      </div>
    </div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.description') }}
      </div>
      <div class="">
        <CKEditorField v-model="content.description"/>
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d1fr') }}
      </div>
      <div>
        <TextField v-model="content.links[0]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d1uk') }}
      </div>
      <div>
        <TextField v-model="content.links[1]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d1us') }}
      </div>
      <div>
        <TextField v-model="content.links[2]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d1fruk') }}
      </div>
      <div>
        <TextField v-model="content.links[3]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2fr') }}
      </div>
      <div>
        <TextField v-model="content.links[4]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2uk') }}
      </div>
      <div>
        <TextField v-model="content.links[5]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2fb') }}
      </div>
      <div>
        <TextField v-model="content.links[6]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2east') }}
      </div>
      <div>
        <TextField v-model="content.links[7]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2west') }}
      </div>
      <div>
        <TextField v-model="content.links[8]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2central') }}
      </div>
      <div>
        <TextField v-model="content.links[9]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2room1') }}
      </div>
      <div>
        <TextField v-model="content.links[10]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2room2') }}
      </div>
      <div>
        <TextField v-model="content.links[11]" />
      </div>
    </div>

    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.links.d2room3') }}
      </div>
      <div>
        <TextField v-model="content.links[12]" />
      </div>
    </div>
 
  </div>
</template>
