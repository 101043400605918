<script>
import { useI18n } from 'vue-i18n'
import { UserRole } from '@/lib/enums'

export default {
  name: 'AdminVoiceOfGodSidebar',
  data(){
    return{
      filterMode: this.$route.params.filter ?? "all"
    }
  },
  props: {
    filter: {
      type: Number,
      default: -1
    }
  },
  emits: ['update:filter'],
  mounted(){
    if(!this.$route.params.filter){
      this.$router.push({
        name: 'AdminVoiceOfGod', 
        params: { filter: 'all' }
      });
    }
  },
  setup() {
    const { t } = useI18n()
    return { t, UserRole }
  }
}
</script>

<template>
  <div class="vog-sidebar d-flex flex-column overflow-hidden">
      <div class="px-5 py-6">
        <h3>{{t('admin.voiceOfGodPage.sidebar.labels.talkTo')}}</h3>
      </div>
      <div class="vog-sidebar-body flex-grow-1 overflow-y-auto custom-scrollbar px-5">
        <button class="vog-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" @click="$emit('update:filter', -1)">
          <span class="text-truncate">{{t('admin.voiceOfGodPage.sidebarLinks.all')}}</span>
        </button>
        <button class="vog-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" @click="$emit('update:filter', UserRole.BrandInfluencer)">
          <span class="text-truncate">{{t('admin.voiceOfGodPage.sidebarLinks.brandInfluencer')}}</span>
        </button>
        <button class="vog-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" @click="$emit('update:filter', UserRole.CommunityManager)">
          <span class="text-truncate">{{t('admin.voiceOfGodPage.sidebarLinks.communityManager')}}</span>
        </button>
        <button class="vog-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" @click="$emit('update:filter', UserRole.RetailSalesAssociate)">
          <span class="text-truncate">{{t('admin.voiceOfGodPage.sidebarLinks.retailSalesAssociate')}}</span>
        </button>
        <button class="vog-sidebar-link d-flex align-items-center mx-n5 px-5 py-3" @click="$emit('update:filter', UserRole.Trainer)">
          <span class="text-truncate">{{t('admin.voiceOfGodPage.sidebarLinks.trainer')}}</span>
        </button>
      </div>
    </div>
</template>

<style lang="scss" scoped>
.vog-sidebar-link{
  width: 100%;
  color: inherit;
  &:active{
    color: inherit;
  }
  &:hover, &.router-link-exact-active{
    background-color: rgb(var(--v-theme-admin-grey-3));
  }
}
</style>
