<script>
import DropdownField from '@/components/fields/Dropdown'

import CampusIcon from '@/assets/imgs/icons/navigation/courtyard.svg'
import ApartmentIcon from '@/assets/imgs/icons/navigation/apartment.svg'
import OfficeIcon from '@/assets/imgs/icons/navigation/office.svg'
import LoftIcon from '@/assets/imgs/icons/navigation/loft.svg'
import PoolIcon from '@/assets/imgs/icons/navigation/rooftop.svg'
import StoreIcon from '@/assets/imgs/icons/navigation/store.svg'
import WarehouseIcon from '@/assets/imgs/icons/navigation/warehouse.svg'

import { Scene } from '@/lib/enums'

export default {
  name: 'SceneSelectorComponent',
  components: {
    DropdownField
  },
  props: {
    modelValue: {
      type: Number,
      default: Scene.Campus
    }
  },
  emits: ['update:modelValue'],
  data() { return {
    val: Scene[this.modelValue] || ''
  }},
  computed: {
    options() {
      return {
        Campus: this.$t(`scenes.${Scene.Campus}`),
        Apartment: this.$t(`scenes.${Scene.Apartment}`),
        Loft: this.$t(`scenes.${Scene.Loft}`),
        Office: this.$t(`scenes.${Scene.Office}`),
        Pool: this.$t(`scenes.${Scene.Pool}`),
        Store: this.$t(`scenes.${Scene.Store}`),
        Warehouse: this.$t(`scenes.${Scene.Warehouse}`)
      }
    },
    icons() {
      return {
        Campus: CampusIcon,
        Apartment: ApartmentIcon,
        Loft: LoftIcon,
        Office: OfficeIcon,
        Pool: PoolIcon,
        Store: StoreIcon,
        Warehouse: WarehouseIcon,
      }
    }
  },
  watch: {
    modelValue(next) {
      this.val = Scene[next]
    },
    val(next) {
      this.$emit('update:modelValue', Scene[next])
    }
  }
}
</script>

<template>
<DropdownField
  v-model="val"
  :options="options"
  :icons="icons"
/>
</template>
