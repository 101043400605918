<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import FirebaseUploadAdapter from '@/utils/ckeditorUploadAdapter'

export default {
  props: ['modelValue', 'disabled', 'placeholder'],
  emits: ['update:modelValue'],
  data() {
    return {
      html: this.modelValue || '',
      editor: ClassicEditor,
      editorConfig: {
        placeholder: this.placeholder,
        extraPlugins: [FirebaseUploadAdapter],
        toolbar: ['bold', 'italic', /*'underline',*/ 'link', 'bulletedList', 'numberedList']
      },
    }
  },
  watch: {
    html() {
      this.$emit('update:modelValue', this.html)
    },
    modelValue(next) {
      this.html = next
    }
  }
}
</script>

<template>
  <ckeditor
    v-model="html"
    :editor="editor"
    :config="editorConfig"
    :disabled="disabled"
  />
</template>

<style scoped>
.ck.editor {
  border: 1px solid rgb(var(--v-theme-fb-gray));
}
</style>
