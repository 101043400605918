<script>
import dayjs from 'dayjs'
import Checkbox from '@/components/fields/Checkbox'

export default {
  name: 'AdminVoiceOfGodUserList',
  components:{
    Checkbox
  },
  props: {
    filter: {
      type: Number,
      default: -1
    }
  },
  emits: ['update:selected'],
  data(){
    return{
      selectedUsers:{}
    }
  },
  computed: {
    sampleData() {
      const list = this.$store.getters['admin/registered']
      if (this.filter === -1)
        return list
      return list.filter(user => user.role === this.filter)
    }
  },
  methods:{
    selectUser(){
      const list = Object.keys(this.selectedUsers).filter(id => this.selectedUsers[id])
      this.$emit('update:selected', list)
    },
    userIsOnline(user) {
      if (!user.lastActivity?.seconds)
        return false
      const last = dayjs.unix(user.lastActivity.seconds)
      const now = dayjs()
      return now.diff(last,'s') < 60
    }
 
  }
}
</script>

<template>
  <div class="email-users-list bg-admin-grey-3 overflow-y-auto custom-scrollbar px-2 py-1">
    <div v-for="item in sampleData" :key="item.id">
      <div class="vog-users-list-item rounded-lg px-3 py-2 my-1" :class="selectedUsers[item.id] ? 'vog-user-selected' : ''">
        <div class="email-users-list-avtar">
          <div class="avatar-wrapper">
            <div class="avtar" :style="'background-image:url('+ item.avatar + ')'"/>
            <div class="user-status" :class="`user-status-${userIsOnline(item)?'online':'offline'}`"/>
          </div>
        </div>
        <div class="text-truncate px-3">{{item.displayName}}</div>
        <Checkbox v-model="selectedUsers[item.uid]" @update:modelValue="selectUser" />
      </div>
    </div>
    <div v-if="!sampleData.length">
      {{$t('admin.voiceOfGodPage.userSection.noData')}}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/assets/_variables.scss';
.vog-users-list-item{
  display: grid;
  grid-template-columns: 50px calc(100% - 70px) 20px;
  align-items: center;
  color: rgb(var(--v-theme-fb-grey));
  cursor: pointer;
  &:hover{
    background-color: #fff;
  }
  &.vog-user-selected{
    color: rgb(var(--v-theme-black));
  }
  .avatar-wrapper {
    position: relative;
    .avtar{
      width:50px;
      background-position: center;
      background-size: cover;
    }

    .user-status {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 2px solid #fff;

      &-online {
        background-color: rgb(var(--v-theme-success));
      }

      &-offline {
        background-color: rgb(var(--v-theme-error));
      }
    }
  }
}
</style>
