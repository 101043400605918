<script>
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'TimeField',
  props: {
    modelValue: {
      required: true
    },
    error: {
      type: Boolean,
      default: false
    },
    day: {
      type: String
    },
    tz: {
      type: String,
      default: ''
    },
    utc: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  created() {
    if (this.modelValue?.seconds) {
      const d = this.tz === ''
        ? dayjs.unix(this.modelValue.seconds)
        : dayjs.unix(this.modelValue.seconds).tz(this.tz)
      const val = d.format('HH:mm')
      if (this.modelValue === val)
        return
      this.val = val
    }
  },
  data() {
    return {
      val: null
    }
  },
  watch: {
    modelValue(next,prev) {
      if (next && prev && next.seconds === prev.seconds)
        return
      const d = this.tz === ''
        ? dayjs.unix(this.modelValue.seconds)
        : dayjs.unix(this.modelValue.seconds).tz(this.tz)
      const val = d.format('HH:mm')
      if (this.modelValue === val)
        return
      this.val = val
    },
    val(next) {
      if (typeof next !== 'string' || !next.length) {
        this.$emit('update:modelValue', null)
        return
      }
      const [hour,minute] = next.split(':').map(el => parseInt(el))
      if (typeof hour !== 'number' || typeof minute !== 'number') {
        this.$emit('update:modelValue', null)
        return
      }
      if (!this.day) {
        console.error('no day')
        this.$emit('update:modelValue', null)
        return
      }
      const date = this.day + ' ' + next + ' ' + this.utc
      const day = dayjs(date)
      this.$emit('update:modelValue', { seconds: day.unix() })
    }
  }
 
}
</script>

<template>
  <input
    class="input-time"
    :class="{
      'error': error
    }"
    type="time"
    autocomplete="off"
    v-model="val"
  >
</template>

<style scoped>
.input-time{
  width:100%;
  border-radius: 5px;
  font-size: 16px;
  padding: 14px;
  background: #fff;
  box-shadow: #b1b1b158 0px 6px 20px;

}
.input-time.error {
  color: rgb(var(--v-theme-error)) !important;
  border: 1px solid rgb(var(--v-theme-error)) !important;
}
</style>
