import store from '../store'

class UploadAdapter {
  constructor(loader, editor) {
    this.loader = loader
    this.editor = editor
  }

  async upload() {
    const file = await this.loader.file
    // console.log('CK EDIFOR upload', file, this, store)
    return store.dispatch('user/uploadCKMedia',file)
  }

  abort() {
    // no abort currently
  }
}

const FirebaseUploadAdapter = editor => {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => new UploadAdapter(loader, editor)
}

export default FirebaseUploadAdapter
