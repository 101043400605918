<script>
export default {
  name: 'Settings',
}
</script>

<template>
  <div class="pa-10 admin-page-block bg-white admin-shadow admin-rounded shadow h-full d-flex flex-column">
    <div class="settings-wrapper overflow-y-auto custom-scrollbar">
    Settings
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
