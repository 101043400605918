<script>
import { Locale } from '@/lib/enums.js'

import FlagUS from '@/assets/imgs/icons/flags/usa.svg'
import FlagFR from '@/assets/imgs/icons/flags/france.svg'
import FlagES from '@/assets/imgs/icons/flags/spain.svg'

import DropdownField from '@/components/fields/Dropdown'

export default {
  name: 'LocaleSelector',
  components: {
    DropdownField
  },
  props: {
    modelValue: {
      type: Number,
      default: Locale.en
    }
  },
  emits: ['update:modelValue'],
  data() { return {
    val: Locale[this.modelValue] || ''
  }},
  computed: {
    options() {
      return {
        en: this.$t(`locales.${Locale.en}`),
        es: this.$t(`locales.${Locale.es}`),
        fr: this.$t(`locales.${Locale.fr}`)
      }
    },
    icons() {
      return {
        en: FlagUS,
        es: FlagES,
        fr: FlagFR
      }
    }
  },
  watch: {
    modelValue(next) {
      this.val = Locale[next]
    },
    val(next) {
      this.$emit('update:modelValue', Locale[next])
    }
  }
}
</script>

<template>
<DropdownField
  v-model="val"
  :options="options"
  :icons="icons"
/>
</template>
