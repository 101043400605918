<script>
import LocalizedCKEditorField from '@/components/fields/LocalizedCKEditor'
import TextField from '@/components/fields/Text'
// import FileField from '@/components/fields/File'
import LocalizedDropdownField from '@/components/fields/LocalizedDropdown'

export default {
  name: 'QuizQuestionEditor',
  components:{
    LocalizedCKEditorField,
    LocalizedDropdownField,
    TextField
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({
        prompt: {},
        maxAnswers: 1,
        timer: 0
      })
    },
    loading: {
      type: Boolean,
      default: false
    },
    questionI: {
      type: Number,
      default: 0
    },
    files: {
      required: true
    }
  },
  emits: ['update:modelValue', 'deleteQuestion', 'saveQuiz'],
  data() {
    return {
      question: this.modelValue
    }
  },
  watch: {
    modelValue(next) {
      this.question = next
    },
    question(next) {
      this.$emit('update:modelValue', next)
    }
  },
  computed: {
    filesOptions() {
      return Object.keys(this.files || {}).reduce((acc,fid) => {
        acc[fid] = this.files[fid].details.filename
        return acc
      }, {})
    }
  },
  methods: {
    addOption() {
      this.question.answers.push({
        text: {},
        image: {},
        points: 0,
      })
    },
    async deleteOption(pos) {
      const ok = await this.$store.dispatch('admin/confirm', {
        title: this.$t('admin.quizesPage.question.dialog.deleteOption.title'),
        message: this.$t('admin.quizesPage.question.dialog.deleteOption.message', {num: pos + 1}),
      })
      if (!ok)
        return
      this.question.answers = this.question.answers.filter((a, i) => i !== pos)
    }
  }
}
</script>

<template>
  <div class="bg-white overflow-y-hidden h-full d-flex justify-content-center">
    <div class="quize-editor-container overflow-y-auto custom-scrollbar pa-8">
      <div class="field-editor mb-4">
        <div class="editor-filed-label">
          {{$t('admin.quizesPage.quizEditor.sidebar.questionX', { num: questionI + 1 })}}
        </div>
        <div class="mt-1">
          <LocalizedCKEditorField
            v-model="question.prompt"
            :placeholder="$t('admin.quizesPage.question.question')"
          />
          <div class="my-4 support-filed">
            <label>
              {{$t('admin.quizesPage.question.maxAnswers.label')}}
            </label>
            <TextField
              type="number"
              v-model="question.maxAnswers"
              :help="$t('admin.quizesPage.question.maxAnswers.help')"
            />
          </div>
          <div class="support-filed">
            <label>
              {{$t('admin.quizesPage.question.timer.label')}}
            </label>
            <TextField
              type="number"
              v-model="question.timer"
              :help="$t('admin.quizesPage.question.timer.help')"
            />
          </div>
          <!--
          -->
        </div>
      </div>
      <div class="field-editor mb-8" v-for="(answer, index) in question.answers" :key="index">
        <div class="editor-filed-label">
          {{$t('admin.quizesPage.quizEditor.sidebar.answerX', { num: index + 1 })}}
        </div>
        <div class="answer-editor-wrappper">
          <LocalizedCKEditorField
            v-model="question.answers[index].text"
            :placeholder="$t('admin.quizesPage.question.answer.text')"
          />
          <div class="my-4 support-filed">
            <label>
              {{$t('admin.quizesPage.question.answer.image')}}
            </label>
            <LocalizedDropdownField
              v-model="question.answers[index].image"
              :disabled="$store.state.ui.busy"
              :options="filesOptions"
            />
          </div>
          <div class="support-filed">
            <label>
              {{$t('admin.quizesPage.question.answer.points')}}
            </label>
            <TextField type="number" v-model="question.answers[index].points" />
          </div>
          <div class="text-left mt-3">
            <v-btn
              :disabled="loading || $store.state.ui.busy"
              :loading="loading"
              color="warning"
              @click="deleteOption(index)"
            >
              <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
              <span v-else>
                {{$t('admin.quizesPage.question.deleteOption')}}
              </span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="text-right field-editor-action">
        <hr class="mb-6"/>
        <div class="d-flex justify-content-between">
          <!-- delete question -->
          <v-btn
            :disabled="loading || $store.state.ui.busy"
            :loading="loading"
            color="warning"
            @click="$emit('deleteQuestion')"
          >
            <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
            <span v-else>
              {{$t('admin.quizesPage.question.deleteQuestion')}}
            </span>
          </v-btn>
          <!-- add option -->
          <v-btn
            :disabled="loading || $store.state.ui.busy"
            :loading="loading"
            color="primary"
            @click="addOption"
          >
            <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
            <span v-else>
              {{$t('admin.quizesPage.question.addOption')}}
            </span>
          </v-btn>
          <!-- update quiz -->
          <v-btn
            :disabled="loading || $store.state.ui.busy"
            :loading="loading"
            color="primary"
            @click="$emit('saveQuiz')"
          >
            <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
            <span v-else>
              {{$t('admin.quizesPage.createQuizPage.fields.submitEditButton')}}
            </span>
          </v-btn>
        </div>
      </div>
            <!--
            -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quize-editor-container{
  width: 100%;
  padding: 20px 0;
}
.field-editor{
  display: grid;
  grid-template-columns: minmax(150px, 25%) 1fr;
  grid-column-gap:20px;
  padding: 20px;
  &:hover{
    background-color: rgb(var(--v-theme-admin-grey));
  }
}
.field-editor-action{
  padding-left: 20px;
  padding-right: 20px;
}
.editor-filed-label{
  font-size: 22px;
}
.support-filed{
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap:20px;
  align-items: center;
}
</style>
