<script>

import checkbox from "@/assets/imgs/icons/fb-checkbox.svg";
import checkboxChecked from "@/assets/imgs/icons/fb-checkbox-checked.svg";

export default {
  name: 'CheckboxField',
  components:{
    checkbox,
    checkboxChecked
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: this.modelValue,
    }
  },
  emits: [
    'update:modelValue'
  ],
  watch: {
    modelValue(next) {
      this.val = next
    },
    val(next) {
      this.$emit('update:modelValue', next)
    }
  }
}
</script>

<template>
<div @click="val = disabled ? val : !val" class="checkbox-wrappper" :class="disabled ? 'checkbox-disable' : ''">
  <checkbox v-if="!val" />
  <checkboxChecked v-else />
</div>
</template>

<style scoped lang="scss">
.checkbox-wrappper{
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  svg{
    width: 20px;
    display: block;
  }
  [type="checkbox"]{
    display: none;
  }
}
.checkbox-disable{
  pointer-events: none;
}
</style>
