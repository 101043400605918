<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import utc from 'dayjs/plugin/utc'
import timezone from "dayjs/plugin/timezone";
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
//dayjs.tz.setDefault("America/California");

import CalendarDay from "@/components/admin/scheduling/CalenderDay"
import CalenderEventEditor from "@/components/admin/scheduling/CalenderEventEditor"
import CalenderMonthChanger from "@/components/admin/scheduling/CalenderMonthChanger"
import CalenderMonthLabel from "@/components/admin/scheduling/CalenderMonthLabel"
import DropdownField from '@/components/fields/Dropdown'

export default {
  name: 'Calender',
  components:{
    DropdownField,
    CalenderMonthChanger,
    CalendarDay,
    CalenderEventEditor,
    CalenderMonthLabel
  },
  data(){
    return{
      weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
      selectedDate: dayjs(),
      calenderEvents:[],
      isVisible:false,
      eventDate:null,
      selectedEvent: null,
      timezone: 'PST'
    }
  },
  computed: {

    presentations() {
      const list = this.$store.getters['admin/presentations'] || []
      return list.sort((a,b) => {
        console.log(a.start,b.start);
        return a.start.seconds === b.start.seconds
          ? 0
          : a.start.seconds > b.start.seconds
            ? 1
            : -1
      })
    },

    timeZoneOptions(){
      return {PST: "PST", CET : "CET", EST : "EST"}
    },

    timeZones: () => ({
      PST: {
        tz: 'America/Los_Angeles',
        utc: 'UTC-7'
      },
      CET: {
        tz: 'Europe/Rome',
        utc: 'UTC+2'
      },
      EST: {
        tz: 'America/New_York',
        utc: 'UTC-4'
      }
    }),

    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays
      ];
    },

    today() {
      return dayjs().format("YYYY-MM-DD");
    },

    month() {
      return Number(this.selectedDate.format("M"));
    },

    year() {
      return Number(this.selectedDate.format("YYYY"));
    },

    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },

    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).format(
            "YYYY-MM-DD"
          ),
          isCurrentMonth: true
        };
      });
    },

    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date
      );
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        "month"
      );

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0].date
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: dayjs(
              `${previousMonth.year()}-${previousMonth.month() +
                1}-${previousMonthLastMondayDayOfMonth + index}`
            ).format("YYYY-MM-DD"),
            isCurrentMonth: false
          };
        }
      );
    },

    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`
      );

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, "month");

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: dayjs(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
          ).format("YYYY-MM-DD"),
          isCurrentMonth: false
        };
      });
    }
  },
  methods: {
    getWeekday(date) {
      return dayjs(date).weekday();
    },

    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
    },

    createEvent(value){
      this.eventDate = value;
      this.selectedEvent = undefined
      this.isVisible = true;
    },

    selectEvent({event, date}) {
      this.eventDate = date
      this.selectedEvent = event
      this.isVisible = true
    },

    async saveEvent(activity){
      // console.log(activity);
      this.$store.commit('ui/setBusy', true)
      this.loading = true
      if (activity.id)
        await this.$store.dispatch('admin/activities/update', { id: activity.id, doc: activity})
      else
        await this.$store.dispatch('admin/activities/save', activity)
      this.loading = false
      this.$store.commit('ui/setBusy', false)
    },

    async deleteEvent(activity) {
      const ok = await this.$store.dispatch('admin/confirm',{
        title: this.$t('admin.schedulingPage.createCalenderEvent.deleteDialog.title'),
        message: this.$t('admin.schedulingPage.createCalenderEvent.deleteDialog.message')
      })
      this.isVisible = false
      if (!ok)
        return
      // console.log('del ev', activity)
      this.$store.commit('ui/setBusy', true)
      this.loading = true
      await this.$store.dispatch('admin/activities/delete', activity.id)
      this.loading = false
      this.$store.commit('ui/setBusy', false)
    }
  }
}
</script>

<template>
  <div class="calendar-big">
    <div class="calendar-header d-flex align-items-center justify-space-between mb-4">
      <CalenderMonthLabel class="flex-grow-1" :selected-date="selectedDate" />

      <div class="d-flex">
        <!--
        <div class="mab-2">
          Timezone
        </div>
        -->
        <div>
          <DropdownField
            v-model="timezone"
            :options="timeZoneOptions"
          />
        </div>
      </div>
      <CalenderMonthChanger :current-date="today" :selected-date="selectedDate" @dateSelected="selectDate" />
    </div>
    <div class="calendar-month-previewer custom-scrollbar">
      <div class="calendar-holder">
        <div class="calender-container font-size-14">
          <div class="calender-row">
            <div class="text-bold d-flex justify-center align-items-center" v-for="weekday in weekdays" :key="weekday">{{ weekday }}</div>
          </div>
          <div class="calender-row">
            <CalendarDay
              v-for="day in days"
              :key="day.date"
              :day="day"
              :tz="timeZones[this.timezone]"
              :events="presentations"
              @createCalenderEvent="createEvent"
              @selectCalenderEvent="selectEvent"
            />
          </div>
          <CalenderEventEditor
            v-if="isVisible"
            :date="eventDate"
            :tz="timeZones[this.timezone]"
            v-model="selectedEvent"
            @update:modelValue="isVisible = false; saveEvent($event)"
            @close="isVisible = false"
            @delete:modelValue="deleteEvent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendar-holder{
  display:grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
}
.calender-container{
  .calender-row{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    > div{
      padding: 10px;
      border: 1px solid #ddd;
      margin-top: -1px;
      margin-left: -1px;
      min-height: 100px;
      min-width: 180px;
    }
  }
}
</style>
