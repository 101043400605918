<script>
export default {
  name: 'AdminUiAlert',
  computed: {
    color() {
      if (this.$store.state.admin.uiAlert.color === '')
        return 'bg-white'
      return `bg-${this.$store.state.admin.uiAlert.color}`
    }
  }
}
</script>

<template>
<div class="dialog-overlay">
  <div :class="color" class="dialog-window elevation-4 border rounded-lg pa-5">
    <h3 class="mb-5" v-if="$store.state.admin.uiAlert.title !== ''">
      <component class="mr-5" v-if="$store.state.admin.uiAlert.icon" :is="$store.state.admin.uiAlert.icon"/>
      {{$store.state.admin.uiAlert.title}}
    </h3>
    <p>{{$store.state.admin.uiAlert.message}}</p>
    <div class="text-right mt-5">
      <v-btn @click="$store.commit('admin/closeAlert')">{{$t('buttons.ok')}}</v-btn>
    </div>
  </div>
</div>
</template>

<style scoped>
.dialog-window {
  min-width: 350px;
}
</style>
