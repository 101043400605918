/** Deletes file from storage and collection */
const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')
import * as Sentry from "@sentry/vue";

const fileStreamPrep = async ({
  id,
  collection,
  storage,
  refPrefix = '',
  refSuffix = '',
  
}) => {
  if (!id) return

  const firebaseModule = await firebaseImport()
  const firebase = firebaseModule.default

  const filePath = `${refPrefix}${id}${refSuffix}`
  const ref = storage 
    ? firebase.app().storage(`gs://${storage}`).ref(filePath)
    : firebase.storage().ref(filePath)

  
    try {
      console.log('Bumping ref:', ref)
    } catch (err) {
      Sentry.captureException(err)
      console.log('preparing video for streaming error', err, err.code)
      return { error: err }
    }
  
  if (!collection)
    return

  const refDoc = collection.doc(id)
  await refDoc.update({
    manualStreamPrepTimestamp: new Date()
  })
 
  console.log('Bumped file', refDoc)
}

export default fileStreamPrep
