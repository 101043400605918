<script>
export default {
  name:"CalenderMonthLabel",
  props: {
    selectedDate: {
      type: Object,
      required: true
    }
  },

  computed: {
    selectedMonth() {
      return this.selectedDate.format("MMMM YYYY");
    }
  }
};
</script>

<template>
  <div>
    <h4 class="font-size-18">{{ selectedMonth }}</h4>
  </div>
</template>

<style scoped>

</style>