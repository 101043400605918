<script>
import DropdownField from '@/components/fields/Dropdown'
import ActivityContent from '@/components/admin/activity/ActivityContent'
import ActivityLiveBoard from '@/components/admin/activity/ActivityLiveBoard'
import ActivityMosaic from '@/components/admin/activity/ActivityMosaic'
import ActivityScreen from '@/components/admin/activity/ActivityScreen'
import ActivityScorm from '@/components/admin/activity/ActivityScorm'
import ActivityMap from '@/components/admin/activity/ActivityMap'
import ActivitySessionMap from '@/components/admin/activity/ActivitySessionMap'
import ActivityUrlRedirect from '@/components/admin/activity/ActivityUrlRedirect'

import { ActivityType } from '@/lib/enums'

import ContentIcon from '@/assets/imgs/icons/admin/activity/content.svg'
import ScreenIcon from '@/assets/imgs/icons/admin/activity/screen.svg'
import ScormIcon from '@/assets/imgs/icons/admin/activity/scorm.svg'
import MosaicIcon from '@/assets/imgs/icons/admin/activity/mosaic.svg'
import LiveboardIcon from '@/assets/imgs/icons/admin/activity/liveboard.svg'

export default {
  name: 'ActivityEditor',
  components:{
    DropdownField,
    ActivityContent,
    ActivityLiveBoard,
    ActivityMosaic,
    ActivityScreen,
    ActivityMap,
    ActivitySessionMap,
    ActivityUrlRedirect,
    ActivityScorm
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({
        type: ActivityType.Content,
        content: {}
      })
    },
    mode: {
      type: String,
      default: 'new'
    }
  },
  emits: ['update:modelValue', 'save', 'deleteMe'],
  data() {
    return {
      activity: this.modelValue,
      typeStr: this.mode === 'new' ? '' : ActivityType[this.modelValue.type],
      loading: false,
      media: null,
      files: {},
      sub: null
    }
  },
  created() {
    this.reload()
  },
  computed: {
    typeOptions() { return {
      Content: this.$t(`admin.activity.type.${ActivityType.Content}`),
      LiveBoard: this.$t(`admin.activity.type.${ActivityType.LiveBoard}`),
      Mosaic: this.$t(`admin.activity.type.${ActivityType.Mosaic}`),
      Map: this.$t(`admin.activity.type.${ActivityType.Map}`),
      SCORM: this.$t(`admin.activity.type.${ActivityType.SCORM}`),
      Screen: this.$t(`admin.activity.type.${ActivityType.Screen}`),
      SessionMap: this.$t(`admin.activity.type.${ActivityType.SessionMap}`),
      UrlRedirect: this.$t(`admin.activity.type.${ActivityType.UrlRedirect}`),
    }},
    typeIcons() { return {
      Content: ContentIcon,
      LiveBoard: LiveboardIcon,
      Mosaic: MosaicIcon,
      Map: ScreenIcon,
      SCORM: ScormIcon,
      Screen: ScreenIcon,
      SessionMap: ScreenIcon,
      UrlRedirect: ContentIcon,
    }},
    valid() {
      return this.typeStr !== ''// && this.activity.content?.name?.length
    },
  },
  watch: {
    typeStr(next) {
      this.activity.type = ActivityType[next]
      this.$emit('update:modelValue', this.activity)
    },
    modelValue: {
      deep: true,
      handler(next) {
        this.activity = next
        this.reload()
      }
    },
    activity: {
      deep: true,
      handler(next) {
        this.$emit('update:modelValue', next)
      }
    }
  },
  methods: {
    async reload() {
      if (!this.activity?.id)
        return null
      const ref = await this.$store.getters['admin/activities/docRef'](this.activity.id)
      this.media = ref.collection('media')
      if (this.sub)
        this.sub()
      this.sub = this.media.onSnapshot(snap => {
        const docs = {}
        snap.forEach(rec => {
          const doc = rec.data()
          doc.id = rec.id
          docs[doc.id] = doc
        })
        this.files = docs
      })
    }
  },
  setup() {
    return { ActivityType }
  },
  beforeUnmount() {
    if (this.sub)
      this.sub()
  }
}
</script>

<template>
<div class="py-6">
  <h3>
    <router-link class="mr-1 text-black" :to="{ name: 'Activities' }">
      <v-icon dark left>mdi-arrow-left</v-icon>
    </router-link>
    {{$t('admin.activityPage.activityListPage.title')}}
  </h3>
</div>
<div class="create-activity">

  <!--  Choose Activity Type -->
  <DropdownField
    :disabled="loading || $store.state.ui.busy"
    :options="typeOptions"
    :icons="typeIcons"
    v-model="typeStr"
    class="mb-5 activity-choose-dropdown"
    :placeholder="$t('admin.activityPage.createActivityPage.fileds.chooseActivity')"
  />

  <div v-if="typeStr !== ''" class="activity-creation-container">

    <!--  Content Activity -->
    <ActivityContent
      v-if="activity.type === ActivityType.Content"
      :mode="mode"
      :loading="loading"
      :files="files"
      :mediaCollection="media"
      :activityId="activity.id"
      v-model="activity.content"
    />

    <!--  Liveboard Activity -->
    <ActivityLiveBoard
      v-if="activity.type === ActivityType.LiveBoard"
      :mode="mode"
      :loading="loading"
      :activityId="activity.id || ''"
      v-model="activity.content"
    />
     
    <!--  Map Activity -->
    <ActivityMap
      v-if="activity.type === ActivityType.Map"
      :loading="loading"
      v-model="activity.content"
    />
    
    <!--  Mosaic Activity -->
    <ActivityMosaic
      v-if="activity.type === ActivityType.Mosaic"
      :mode="mode"
      :loading="loading"
      :activityId="activity.id || ''"
      v-model="activity.content"
    />

    <!--  Screen Activity -->
    <ActivityScreen
      v-if="activity.type === ActivityType.Screen"
      :mode="mode"
      :loading="loading"
      :activityId="activity.id || ''"
      v-model="activity.content"
    />
 
    <!--  SessionMap Activity -->
    <ActivitySessionMap
      v-if="activity.type === ActivityType.SessionMap"
      :mode="mode"
      :loading="loading"
      :activityId="activity.id || ''"
      v-model="activity.content"
    />
    
    <!--  UrlRedirect Activity -->
    <ActivityUrlRedirect
      v-if="activity.type === ActivityType.UrlRedirect"
      :mode="mode"
      :loading="loading"
      :activityId="activity.id || ''"
      v-model="activity.content"
    />
    
    <!--  Scorm Activity -->
    <ActivityScorm
      v-if="activity.type === ActivityType.SCORM"
      :mode="mode"
      :loading="loading"
      :files="files"
      :mediaCollection="media"
      :activityId="activity.id || ''"
      v-model="activity.content"
    />

    <div class="text-right">
      <v-btn
        :disabled="!valid ||loading || $store.state.ui.busy"
        :loading="loading"
        @click="$emit('deleteMe')"
        color="warning"
        v-if="mode !== 'new'"
        class="mr-2"
      >
        <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
        <span v-else>{{$t('buttons.delete')}}</span>
      </v-btn>

      <v-btn
        :disabled="!valid ||loading || $store.state.ui.busy"
        :loading="loading"
        @click="$emit('save')"
        color="primary"
      >
        <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
        <span v-else>{{mode === 'new' ? $t('buttons.save') : $t('buttons.update')}}</span>
      </v-btn>
    </div>
  </div>
  <!--
  <div>{{activity}}</div>
  -->
</div>
</template>

<style lang="scss" scoped>
</style>
