export default {
  campus: [{
    id: 1,
    name: 'Plant'
  },{
    id: 2,
    name: 'Chair'
  },{
    id: 3,
    name: 'Tree'
  }],
  store: [{
    id: 1,
    name: 'Monitor'
  },{
    id: 2,
    name: 'Plant'
  },{
    id: 3,
    name: 'Tree'
  }],
  office: [{
    id: 1,
    name: 'Picture'
  },{
    id: 2,
    name: 'Plant'
  },{
    id: 3,
    name: 'Table'
  }],
  pool: [{
    id: 1,
    name: 'Bottles'
  },{
    id: 2,
    name: 'Tree'
  },{
    id: 3,
    name: 'Plant'
  }],
  loft: [{
    id: 1,
    name: 'Flower'
  },{
    id: 2,
    name: 'Plant'
  },{
    id: 3,
    name: 'Phone'
  }],
  apartment: [{
    id: 1,
    name: 'Pillow'
  },{
    id: 2,
    name: 'Horse'
  },{
    id: 3,
    name: 'Plant'
  }],
  warehouse: []
}
