<script>
import { UserRole } from '@/lib/enums'
import * as Sentry from "@sentry/vue";

import TextField from '@/components/fields/Text'
import RoleDropdown from '@/components/fields/RoleDropdown'
// import DropdownField from '@/components/fields/Dropdown'
import Checkbox from '@/components/fields/Checkbox'

import file2buffer from '@/utils/file2buffer'

export default {
  name: 'UsersWhiteLists',
  components: {
    TextField,
    RoleDropdown,
    Checkbox,
    // DropdownField
  },
  data: () => ({
    loading: false,
    newRecord: {
      email: '',
      role: UserRole.Guest,
      approved: false
    }
  }),
  computed: {
    rolesFilter(){
      if (!this.newRecord.approved)
        return [UserRole.Root, UserRole.Trainer, UserRole.CommunityManager, UserRole.BrandInfluencer, UserRole.RetailSalesAssociate, UserRole.FacebookEmployee, UserRole.CorporatePartner]
      return [UserRole.Guest]
    },
    rules() {
      const list = this.$store.getters['admin/prescreened/list']
      return list
        .sort((a,b) => {
          return a.email === b.email
            ? 0
            : a.email > b.email
              ? 1
              : -1
        })
    }
  },
  watch: {
    'newRecord.approved'(next) {
      if (next)
        this.newRecord.role = UserRole.RetailSalesAssociate
      else
        this.newRecord.role = UserRole.Guest
    }
  },
  methods: {
    resetNewRecord() {
      this.newRecord = {
        email: '',
        role: UserRole.Guest,
        approved: false
      }
    },
    async addNewRecord() {
      let data = null;
      const from = data || this.newRecord
      if (!from.email.length)
        return
      this.loading = true
      const existsUser = this.$store.getters['admin/users/list'].find(u => u.email === from.email)
      const existsRule = this.$store.getters['admin/prescreened/list'].find(rec => rec.email === from.email)
      if (existsUser || existsRule) {
        const errCode = existsUser
          ? 'admin.whiteListPage.userExists'
          : 'admin.whiteListPage.ruleExists'
        await this.$store.dispatch('admin/alert',{
          title: this.$t('admin.whiteListPage.failTitle'),
          message: this.$t(errCode, {email: from.email})
        })
        if (!data)
          this.resetNewRecord()
        this.loading = false
        return
      }
      from.approved = from.role !== UserRole.Guest
      await this.$store.dispatch('admin/prescreened/save',JSON.parse(JSON.stringify(from)))
      if (!data)
        this.resetNewRecord()
      this.loading = false
    },
    async delRecord(rec) {
      const ok = await this.$store.dispatch('admin/confirm',{
        title: this.$t('admin.whiteListPage.delTitle'),
        message: this.$t('admin.whiteListPage.delMessage',{email: rec.email}),
      })
      if (!ok)
        return
      await this.$store.dispatch('admin/prescreened/delete',rec.email)
    },
    async uploadCSV(ev) {
      let str = ''
      try {
        const file = ev.target.files[0]
        const buff = await file2buffer(file)
        const enc = new TextDecoder('utf-8')
        str = enc.decode(buff)
      } catch(err) {
        Sentry.captureException(err)
        await this.$store.dispatch('admin/alert',{
          title: this.$t('admin.whiteListPage.csv.errTitle'),
          message: this.$t('admin.whiteListPage.csv.errMessage',{error: err.toString()}),
        })
      }
      if (!str || !str.length)
        return
      // console.log('upload', str, UserRole)
      const lines = str.split('\n')
      for(const line of lines) {
      //lines.forEach(async line => {
        const [email, strRole] = line.split(',')
        if (!email || !strRole)
          continue
        const role = strRole.split(' ').join('').trim()
        const rl = role === 'Admin' ? 'Root' : role
        //console.log('line', email, strRole, role, UserRole[role.trim()])
        if (typeof UserRole[rl] === 'undefined')
          continue
        await this.addNewRecord({email: email, role: UserRole[rl]})
      //})
      }
    }
  }
}
</script>

<template>
  <div class="h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <div class="ma-5 h-full overflow-y-auto custom-scrollbar">
      <!-- add new form -->
      <div class="header d-flex justify-content-between align-items-center">
        <label class="flex-grow-0 mr-5">
          {{$t('admin.whiteListPage.addToList')}}
        </label>
        <TextField
          class="flex-grow-1"
          :placeholder="$t('admin.whiteListPage.email')"
          v-model="newRecord.email"
          :disabled="loading"
        />
        <div class="flex-grow-1 text-center">
          <label>{{$t('admin.whiteListPage.approved')}}</label>
          <Checkbox
            :disabled="loading"
            v-model="newRecord.approved"
            class="ml-3"
          />
        </div>
        <RoleDropdown
          class="flex-grow-1"
          v-model="newRecord.role"
          :disabled="loading"
          :filter="rolesFilter"
        />
        <v-btn
          class="flex-grow-0 ml-5"
          size="large"
          elevation="0"
          flat
          color="primary"
          :disabled="loading"
          :loading="loading"
          @click="addNewRecord"
        >
          {{$t('buttons.add')}}
        </v-btn>
        <label
          class="v-btn v-theme--light bg-primary v-btn--density-default elevation-0 v-btn--size-large v-btn--variant-contained flex-grow-0 ml-5"
        >
          {{$t('buttons.upload')}}
          <input type="file" class="d-none" accept=".csv" @change="uploadCSV"/>
        </label>
      </div>
      <!-- /add new form -->
      <hr class="my-5"/>
      <div>
        <div
          v-for="rule of rules"
          :key="rule.email"
          class="mb-3 d-flex justify-content-between"
        >
          <div>{{rule.email}} - {{$t(`roles.${rule.role}`)}}</div>
          <v-btn
            icon="mdi-minus"
            flat
            elevation="0"
            @click="delRecord(rule)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
