<script>
import fileUpload from "@/utils/fileUpload";
import fileDelete from "@/utils/fileDelete";
import fileStreamPrep from "@/utils/fileStreamPrep";

import TextField from "@/components/fields/Text";

export default {
  name: "FileCollection",
  components: {
    TextField,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
    files: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    bucket: {
      type: String,
      default: undefined,
    },
    refPrefix: {
      type: String,
      default: "",
    },
    refSuffix: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    uploads: [],
    renames: {},
    loading: {},
    names: {},
    videoPreviewDialog: false,
    activeVideoPreviewCloudflareUID: null,
  }),
  computed: {
    list() {
      return Object.values(this.files || {}).sort((a, b) => {
        return a.details.filename === b.details.filename
          ? 0
          : a.details.filename > b.details.filename
          ? 1
          : -1;
      });
    },
  },
  created() {
    this.reload();
  },
  watch: {
    files() {
      this.reload();
    },
  },
  methods: {
    reload() {
      Object.values(this.files || {}).forEach((file) => {
        this.names[file.id] = file.details.filename;
        this.renames[file.id] = false;
        this.loading[file.id] = false;
      });
    },
    async uploadFile(f) {
      const file = f.target.files[0];
      if (!file) return;
      await fileUpload({
        file,
        collection: this.collection,
        storage: this.bucket,
        refPrefix: this.refPrefix,
        refSuffix: this.refSuffix,
        locale: this.$store.state.user.data.locale,
        createdBy: this.$store.state.user.data.uid,
        percent: (info) => {
          if (info.percent === 0) this.uploads.push(info);
          if (info.percent === 100)
            this.uploads = this.uploads.filter(
              (f) => f.details.filename !== info.details.filename
            );
        },
      });
    },
    async deleteFile(file) {
      const ok = await this.$store.dispatch("admin/confirm", {
        title: this.$t("admin.scormManager.dialog.deleteTitle"),
        message: this.$t("admin.scormManager.dialog.deleteMessage", {
          name: file.details.filename,
        }),
      });
      if (!ok) return;

      const res = await fileDelete({
        id: file.id,
        collection: this.collection,
        storage: this.bucket,
        refPrefix: this.refPrefix,
        refSuffix: this.refSuffix,
      });

      if (res && res.error && res.error.code === "storage/unauthorized") {
        this.$store.dispatch("admin/alert", {
          title: "Failed!",
          message: "Not enough permissions",
        });
      }
    },
    async prepareForStreaming(file) {
      // Bumps video media doc to trigger cloudflare stream upload process
      const res = await fileStreamPrep({
        id: file.id,
        collection: this.collection,
        storage: this.bucket,
        refPrefix: this.refPrefix,
        refSuffix: this.refSuffix,
      });

      if (res && res.error) {
        this.$store.dispatch("admin/alert", {
          title: "Failed!",
          message: "Stream preparation failed",
        });
      }

      console.log("prepareForStreaming triggered");
    },
    async renameFile(file) {
      this.loading[file.id] = true;
      const ref = this.collection.doc(file.id);
      const nextDetails = {
        ...file.details,
      };
      nextDetails.filename = this.names[file.id];
      await ref.update({ details: nextDetails });
      this.loading[file.id] = false;
      // console.log('rename', file, nextDetails, ref)
    },
    async previewVideo(row) {
      console.log("Previewing video", row);
      this.videoPreviewDialog = true;
      this.activeVideoPreviewCloudflareUID = row.cloudflareStreamUID;
    },
  },
};
</script>

<template>
  <div class="h-full bg-white pa-6 admin-rounded d-flex flex-column">
    <div class="flex-grow-1 h-full custom-scrollbar overflow-y-auto">
      <div class="d-flex mb-2">
        <h3 class="collection-label mr-3" v-if="label && label !== ''">
          {{ label }}
        </h3>
        <label
          class="
            v-btn v-btn--elevated v-btn--icon
            v-theme--light
            v-btn--density-default v-btn--size-default v-btn--variant-contained
          "
          for="scorm-file-uploader"
        >
          <v-icon>mdi-plus</v-icon>
        </label>
        <input
          type="file"
          id="scorm-file-uploader"
          @change="uploadFile"
          class="d-none"
        />
      </div>
      <div
        class="pa-2 d-flex justify-space-between"
        v-for="(row, rowi) of uploads"
        :key="rowi"
      >
        <div>{{ row.details.filename }}</div>

        <div><v-icon class="spinner">mdi-loading</v-icon></div>
      </div>
      <div
        class="pa-2 d-flex justify-space-between"
        v-for="row of list"
        :key="row.id"
      >
        <div :data-fid="row.id">
          <div v-if="!renames[row.id]">
            {{ row.details.filename }}
          </div>

          <TextField
            v-else
            :disabled="loading[row.id]"
            v-model="names[row.id]"
            @onEnter="renameFile(row)"
          />
        </div>
        <div>
          <div v-if="!renames[row.id]">
            <v-icon
              v-if="row.cloudflareStreamReady"
              class="mr-3"
              small
              color="green"
              >mdi-movie-open-check
            </v-icon>
            <v-btn
              v-if="row.cloudflareStreamReady && row.cloudflareStreamUID"
              size="small"
              icon="mdi-motion-play-outline"
              class="mr-2"
              @click="previewVideo(row)"
            >
              Preview Video
            </v-btn>
            <v-btn
              v-if="
                !row.cloudflareStreamReady === true &&
                !row.cloudflareStreamUID &&
                row.MIME === 5
              "
              size="small"
              icon="mdi-movie-play-outline"
              class="mr-2"
              color="error"
              @click="prepareForStreaming(row)"
            >
              Prepare for Streaming
            </v-btn>
            <v-progress-circular
              v-if="!row.cloudflareStreamReady && row.cloudflareStreamUID"
              indeterminate
              class="mr-2"
              color="primary"
            ></v-progress-circular>
            <v-btn
              size="small"
              icon="mdi-pencil"
              class="mr-2"
              @click="
                names[row.id] = row.details.filename;
                renames[row.id] = true;
              "
            >
              {{ $t("buttons.delete") }}
            </v-btn>
            <v-btn size="small" icon="mdi-minus" @click="deleteFile(row)">
              {{ $t("buttons.delete") }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              size="small"
              icon="mdi-check"
              class="mr-2"
              @click="renameFile(row)"
            >
              {{ $t("buttons.update") }}
            </v-btn>
            <v-btn
              size="small"
              icon="mdi-close"
              @click="renames[row.id] = false"
            >
              {{ $t("buttons.delete") }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Preview Dialog -->
    <v-dialog
      v-model="videoPreviewDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card flat>
          <v-row dense>
            <v-col>
              <v-btn icon small tile dark @click="videoPreviewDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <div style="position: relative; padding-top: 56.25%">
          <iframe
            :src="`https://iframe.videodelivery.net/${activeVideoPreviewCloudflareUID}?autoplay=true`"
            style="
              border: none;
              position: absolute;
              top: 0;
              height: 100%;
              width: 100%;
            "
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></iframe>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.collection-label {
  margin-top: 13px;
}
</style>
