<script>
import CampusIcon from '@/assets/imgs/icons/navigation/courtyard.svg'
import ApartmentIcon from '@/assets/imgs/icons/navigation/apartment.svg'
import OfficeIcon from '@/assets/imgs/icons/navigation/office.svg'
import LoftIcon from '@/assets/imgs/icons/navigation/loft.svg'
import PoolIcon from '@/assets/imgs/icons/navigation/rooftop.svg'
import StoreIcon from '@/assets/imgs/icons/navigation/store.svg'
import WarehouseIcon from '@/assets/imgs/icons/navigation/warehouse.svg'

import { Scene } from '@/lib/enums'

export default {
  name: 'SceneIcon',
  props: {
    scene: {
      type: Number,
      required: true
    }
  },
  computed: {
    icons() {
      return {
        [Scene.Campus]: CampusIcon,
        [Scene.Apartment]: ApartmentIcon,
        [Scene.Loft]: LoftIcon,
        [Scene.Office]: OfficeIcon,
        [Scene.Pool]: PoolIcon,
        [Scene.Store]: StoreIcon,
        [Scene.Warehouse]: WarehouseIcon,
      }
    }
  }
}
</script>

<template>
<component
  v-if="icons[scene]"
  :is="icons[scene]"
/>
</template>
