<script>
import TextField from '@/components/fields/Text'
// import DropdownField from '@/components/fields/Dropdown'
// import LocalizedTextField from '@/components/fields/LocalizedText'
import LocalizedCKEditorField from '@/components/fields/LocalizedCKEditor'
// import { Locale, ActivityType } from '@/lib/enums'

export default {
  name: 'QuizScoreEditor',
  components:{
    TextField,
    // DropdownField,
    // LocalizedTextField,
    LocalizedCKEditorField
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({
        success: 50,
        repeats: 0,
        successMessage: {},
        failMessage: {}
      })
    }
  },
  emits: ['update:modelValue', 'saveQuiz'],
  data() { return {
    loading: false,
    scores: this.modelValue
  }},
  watch: {
    modelValue: {
      deep: true,
      handler(next) {
        this.scores = next
      }
    },
    scores: {
      deep: true,
      handler(next) {
        console.log('next scores', next)
        this.$emit('update:modelValue', next)
      }
    }
  },
  methods: {
  },
  computed: {
    /*
    typeOptions() {
      return {
        '2d': this.$t(`admin.activity.type.${ActivityType.Quizz2D}`),
        '3d': this.$t(`admin.activity.type.${ActivityType.Quizz3D}`)
      }
    }
    */
  }
}
</script>

<template>
<div class="py-6">
  <h3>
    {{$t('admin.quizesPage.createQuizPage.scoresTitle')}}
  </h3>
</div>
<div>
<!--
scores - {{scores}}
-->
  <label>{{$t('admin.quizesPage.createQuizPage.fields.successScore')}}</label>
  <TextField
    :disabled="loading || $store.state.ui.busy"
    class="mb-5 create-quiz-input"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.successScore')"
    v-model="scores.success"
    type="number"
  />
  <label>{{$t('admin.quizesPage.createQuizPage.fields.repeats')}}</label>
  <TextField
    :disabled="loading || $store.state.ui.busy"
    class="mb-5 create-quiz-input"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.repeats')"
    v-model="scores.repeats"
    type="number"
  />
  <label>{{$t('admin.quizesPage.createQuizPage.fields.successMessage')}}</label>
  <LocalizedCKEditorField
    :disabled="loading || $store.state.ui.busy"
    class="mb-5"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.successMessage')"
    v-model="scores.successMessage"
  />
  <label>{{$t('admin.quizesPage.createQuizPage.fields.intro')}}</label>
  <LocalizedCKEditorField
    :disabled="loading || $store.state.ui.busy"
    class="mb-5"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.failMessage')"
    v-model="scores.failMessage"
  />
  <div class="text-right field-editor-action">
    <hr class="mb-6"/>
    <div class="text-right">
      <!-- update quiz -->
      <v-btn
        :disabled="loading || $store.state.ui.busy"
        :loading="loading"
        color="primary"
        @click="$emit('saveQuiz')"
      >
        <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
        <span v-else>
          {{$t('admin.quizesPage.createQuizPage.fields.submitEditButton')}}
        </span>
      </v-btn>
    </div>
  </div>
  
</div>
</template>

<style lang="scss" scoped>
.create-quiz-input{
  box-shadow: 0 none;
  border:1px solid rgb(var(--v-theme-admin-grey));
  border-radius:4px;
}
</style>
