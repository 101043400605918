<script>
import { ActivityType } from '@/lib/enums'
import waitFor from '@/utils/waitFor'

import LocalizedCKEditorField from '@/components/fields/LocalizedCKEditor'
import LocalizedTextField from '@/components/fields/LocalizedText'
import SceneSelectorField from '@/components/fields/SceneSelector'
import LocaleSelectorField from '@/components/fields/LocaleDropdown'
import TimeField from '@/components/fields/Time'
import Checkbox from '@/components/fields/Checkbox'
import TextField from '@/components/fields/Text'

export default {
  name: "CalendarEventEditor",
  components:{
    LocalizedCKEditorField,
    TimeField,
    Checkbox,
    SceneSelectorField,
    LocaleSelectorField,
    LocalizedTextField,
    TextField
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        content: {
          break:false,
          description: {},
          locale: null,
          name: {},
          scene: null,
          primary: false,
          speakers: '',
          video: null,
          teamsUrl: {}
        },
        start: null,
        end: null,
        type: ActivityType.Presentation
      })
    },
    date: {
      type:String,
      required:true
    },
    tz: {
      type: Object,
      required: true
    }
  },
  emits:['update:modelValue', 'close', 'delete:modelValue'],
  data(){
    return{
      activity: this.modelValue,
      spekerList:['list', 'of', 'options'],
      errorMessage: null,
      loading: false,
      errors: {
        name: false,
        start: false,
        end: false,
        scene: false,
        locale: false,
        speakers: false,
        teamsUrl: false
      },
      timezone: null,
    }
  },
  computed:{
    usersMultiselectOptions() {
      return this.$store.getters['admin/administrators'].reduce((acc,u) => {
        acc[u.uid] = `${u.displayName} <${u.email}>`
        return acc
      }, {})
    }
  },
  methods:{
    async updateEvent() {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = false
      })

      if (!Object.keys(this.activity.content.name).length)
        this.errors.name = true
      if (!Object.keys(this.activity.content.teamsUrl).length)
        this.errors.teamsUrl = true
      if (!this.activity.start)
        this.errors.start = true
      if (!this.activity.end)
        this.errors.end = true
      if (!this.activity.content.scene && this.activity.content.scene !== 0)
        this.errors.scene = true
      if (!this.activity.content.locale && this.activity.content.locale !== 0)
        this.errors.locale = true

      if (Object.values(this.errors).some(x => !!x)) {
        await waitFor(5000)
        Object.keys(this.errors).forEach(key => {
          this.errors[key] = false
        })
        return
      }

      this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.activity)))
    }
  }
}
</script>

<template>
  <div class="calender-event-popup">
    <div class="calender-popup-dialog rounded admin-shadow pa-6 bg-white mx-auto">
<!--
{{activity}}
-->
      <div class="calender-popup-dialog-heading mx-n6 mt-n6 py-4 px-6 mb-6 d-flex justify-space-between align-items-center">
        <span v-if="!activity.id" class="text-bold font-size-18">{{$t('admin.schedulingPage.createCalenderEvent.title')}} | {{date}}</span>
        <span v-else class="text-bold font-size-18">{{$t('admin.schedulingPage.createCalenderEvent.titleEdit')}} | {{date}}</span>
        <v-btn
          flat
          elevation="0"
          icon="mdi-close"
          size="small"
          class="mr-n3"
          :disabled="loading || $store.state.ui.busy"
          :loading="loading"
          @click="$emit('close')"
        />
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.name') }}
        </div>
        <div>
          <LocalizedTextField :error="errors.name" v-model="activity.content.name" />
        </div>
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.primary') }}
          <Checkbox class="ml-2" v-model="activity.content.primary" />
        </div>
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.break') }}
          <Checkbox class="ml-2" v-model="activity.content.break" />
        </div>
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.speakers') }}
        </div>
        <div>
          <TextField
            v-model="activity.content.speakers"
          />
        </div>
      </div>
      <div class="mb-6 d-flex">
        <div class="flex-grow-1 pr-2">
          <div class="mb-2">
            {{ $t('admin.schedulingPage.createCalenderEvent.fields.starttime') }}
          </div>
          <div>
            <TimeField
              :day="date"
              :error="errors.start"
              :utc="tz.utc"
              :tz="tz.tz"
              v-model="activity.start"
            />
          </div>
        </div>
        <div class="flex-grow-1 pl-2">
          <div class="mb-2">
            {{ $t('admin.schedulingPage.createCalenderEvent.fields.endtime') }}
          </div>
          <div>
            <TimeField
              :day="date"
              :error="errors.end"
              :utc="tz.utc"
              :tz="tz.tz"
              v-model="activity.end"
            />
          </div>
        </div>
      </div>
      <div class="mb-6 d-flex">
        <div class="flex-grow-1 pr-2">
          <div class="mb-2">
            {{ $t('admin.schedulingPage.createCalenderEvent.fields.locale') }}
          </div>
          <div>
            <LocaleSelectorField
              :disabled="loading || $store.state.ui.busy"
              :error="errors.locale"
              v-model="activity.content.locale"
            />
          </div>
        </div>
        <div class="flex-grow-1 pl-2">
          <div class="mb-2">
            {{ $t('admin.schedulingPage.createCalenderEvent.fields.scene') }}
          </div>
          <div>
            <SceneSelectorField
              class="calendar-editor-scene-selector"
              :disabled="loading || $store.state.ui.busy"
              :error="errors.scene"
              v-model="activity.content.scene"
            />
          </div>
        </div>
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.teamsUrl') }}
        </div>
        <div>
          <LocalizedTextField :error="errors.teamsUrl" v-model="activity.content.teamsUrl" />
        </div>
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.description') }}
        </div>
        <div>
          <LocalizedCKEditorField v-model="activity.content.description"/>
        </div>
      </div>
      <div class="mb-6 text-admin-danger" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="calender-popup-dialog-footer text-right mx-n6 mb-n6 py-4 px-6 mt-6">
        <v-btn
          v-if="activity.id"
          flat
          elevation="0"
          class="mr-5"
          color="warning"
          @click="$emit('delete:modelValue', activity)"
          :disabled="loading || $store.state.ui.busy"
          :loading="loading"
        >{{$t('buttons.delete')}}</v-btn>
        <v-btn
          flat
          elevation="0"
          color="primary"
          @click="updateEvent"
          :disabled="loading || $store.state.ui.busy"
          :loading="loading"
        >
          <span v-if="activity.id">{{$t('buttons.update')}}</span>
          <span v-else>{{$t('buttons.save')}}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calender-event-popup{
  position: fixed;
  z-index: 1000;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(var(--v-theme-black),.7);
  overflow-y: auto;
  padding: 50px 15px;
}
.calender-popup-dialog{
  max-width:1000px;
  &-heading{
    border-bottom:1px solid rgb(var(--v-theme-fb-grey));
  }
  &-footer{
    border-top:1px solid rgb(var(--v-theme-fb-grey));
  }
}
</style>

<style>
.calendar-editor-scene-selector .selected-icon path,
.calendar-editor-scene-selector .item-icon path
{
  fill: black !important;
}
</style>
