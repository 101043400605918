<script>
import * as Sentry from "@sentry/vue";

import MultiselectField from '@/components/fields/Multiselect'
import DropdownField from '@/components/fields/Dropdown'
//import waitFor from '@/utils/waitFor'
import { ActivityType } from '@/lib/enums'

const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')

export default {
  name: 'ActivityInvitationEmail',
  components:{
    MultiselectField,
    DropdownField,
  },
  data() {
    return{
      data:{
        activity:null,
        invitees:[],
        footerImage:null,
        headerImage:null,
        inviter:{
          id:this.$store.state.user.data.uid,
          name:this.$store.state.user.data.displayName,
        }
      },
      users: {},
      loading:false,
      startHeaderUploading:false,
      startFooterUploading:false,
      success: false,
      error: false,
      responseData:null
    }
  },
  computed: {
    usersMultiselectOptions() {
      return Object.values(this.users).reduce((acc,u) => {
        acc[u.uid] = `${u.displayName} <${u.email}>`
        return acc
      }, {})
    },
    activityOptions() { 
      let allEvents = this.$store.getters['collections/activities/list'] || [];
      let calendarEvents = allEvents.filter(p => p.type === ActivityType.Presentation)
      return Object.values(calendarEvents).reduce((acc,u) => {
        acc[u.id] = this.$l(u.content.name);
        return acc
      }, {})
    },
  },
  async created() {
    // I work here with collection, not with store, because on this page I need single time loaded list of users, not "realtime updated"
    // this function can be moved to store, if anywhere else same functionality will be required
    const collection = await this.$store.getters['admin/users/collection']()
    const options = {
      query: ['approved', '==', true],
      orderBy: ['email', 'desc']
    }
    const ref = collection
      .where(...options.query)
      .orderBy(...options.orderBy)
      const snap = await ref.get()
      const docs = {}
      snap.forEach(rec => {
        const doc = rec.data()
        doc.uid = rec.id
        docs[rec.id] = doc
      })
      this.users = docs
  },
  methods:{
    userName(uid) {
      return this.$store.getters['collections/users/byID'](uid).email
    },
    async uploadheader(f){
      this.startHeaderUploading = true;
      const file = f.target.files[0];
      const details = await this.$store.dispatch('user/uploadCKMedia', file);
      this.data.headerImage = details.default;
      this.startHeaderUploading = false;
    },
    async uploadFooter(f){
      this.startFooterUploading = true;
      const file = f.target.files[0];
      const details = await this.$store.dispatch('user/uploadCKMedia', file);
      this.data.footerImage = details.default;
      this.startFooterUploading = false;
    },
    async sendEmail(){
      this.loading = true;
      this.success = false;
      this.error = false;

      let invitationData = this.data
      if(!this.data.headerImage){
        delete invitationData.headerImage
      }
      if(!this.data.footerImage){
        delete invitationData.footerImage
      }

      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      var addMessage = firebase.functions().httpsCallable('sendActivityInvitations');
      addMessage(invitationData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.data.activity = null;
          this.data.invitees = [];
          this.data.footerImage = null;
          this.data.headerImage = null;
          this.success = true
          this.responseData = response.data
        })
        .catch((error) => {
          Sentry.captureException(error)
          this.loading = false;
          console.log(error);
          this.error = true
        });
    }
  }
}
</script>

<template>
  <div class="create-email-page h-100 overflow-y-auto custom-scrollbar">
    <div class="w-100 h-full">
      <div>
        <label>{{ $t('admin.emailPage.createEmailPage.fields.headerImage.label') }}</label>
        <div class="mt-2 email-image-upload d-flex align-items-center">
          <input type="file" @change="uploadheader" :disabled="loading" accept="image/*"/>
          <v-icon v-if="startHeaderUploading" class="spinner" size="sm">mdi-loading</v-icon>
          <img class="uploaded-image" :src="data.headerImage" v-if="data.headerImage" />
        </div>
      </div>
      <div class="mt-6">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.footerImage.label') }}</label>
        <div class="mt-2 email-image-upload d-flex align-items-center">
          <input type="file" @change="uploadFooter" :disabled="loading" accept="image/*"/>
          <v-icon v-if="startFooterUploading" class="spinner" size="sm">mdi-loading</v-icon>
          <img class="uploaded-image" :src="data.footerImage" v-if="data.footerImage" />
        </div>
      </div>
      <div class="mt-6">
          <label>{{ $t('admin.activityPage.createActivityPage.fileds.chooseActivity') }}</label>
          <div class="mt-2">
            <DropdownField
              :disabled="loading || $store.state.ui.busy"
              :options="activityOptions"
              v-model="data.activity"
              class="mb-5 activity-choose-dropdown"
              :placeholder="$t('admin.activityPage.createActivityPage.fileds.chooseActivity')"
            />
          </div>
      </div>
      <div class="mt-6">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.user.label') }}</label>
        <div class="mt-2">
          <MultiselectField
            v-model="data.invitees"
            :options="usersMultiselectOptions"
            :disabled="$store.state.ui.busy"
            :maxHeight="150"
            :placeholder="$t('admin.emailPage.createEmailPage.fields.user.placeholder')"
          />
        </div>
      </div>
      <div class="mt-6 text-left pa-6 bg-admin-grey" v-if="success">
        <div v-if="responseData.alreadyInvited.length">
          <h4>{{ $t('admin.emailPage.createEmailPage.alreadyInvited') }}</h4>
          <span v-for="alreadyInvited, index in responseData.alreadyInvited" :key="index">&lt;{{ userName(alreadyInvited) }}&gt;</span>&nbsp;
        </div>
        <div 
          v-if="responseData.failedInvitations.length"
          :class="responseData.alreadyInvited.length ? 'mt-6' : ''"
          >
          <h4>{{ $t('admin.emailPage.createEmailPage.faildinvitees') }}</h4>
          <span v-for="failed, index in responseData.failedInvitations" :key="index">&lt;{{ userName(failed) }}&gt;</span>
        </div>
      </div>
      <div class="py-6 text-right">
        <span v-if="success" class="mr-4 text-admin-green-400">{{ $t('admin.emailPage.createEmailPage.successMessageForInvitation') }}</span>
        <span v-if="error" class="mr-4 text-admin-danger">{{ $t('admin.emailPage.createEmailPage.errorMessage') }}</span>
        <v-btn
          color="primary"
          :disabled="loading || $store.state.ui.busy"
          @click="sendEmail"
        >
          <v-icon v-if="loading" class="spinner mr-2" size="sm">mdi-loading</v-icon>
          {{$t('buttons.sendEmail')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-email-page{
  padding: 20px 40px;
}
.email-image-upload{
  input{
    width:250px;
  }
  img{
    width:150px;
    height:70px;
    object-fit: contain;
    object-position: center;
  }
}
</style>
