<script>
import Calendar from "@/components/admin/scheduling/Calender"

export default {
  name: 'Scheduling',
  components:{
    Calendar
  }
}
</script>

<template>
  <div class="admin-scedule-page h-full d-flex flex-column">
    <div class="tag-wrapper overflow-x-auto custom-scollbar mx-n2 d-flex items-center mb-4">
      <!--<span v-for="tag in tags" :key="tag.id" class="text-white tag ma-2 flex-grow-1 py-4 px-6 rounded" :style="'background-color:' + tag.color">{{ tag.name }}</span>-->
    </div>
    <div class="admin-scedule-page bg-white admin-rounded admin-shadow pa-6 flex-grow-1 overflow-hidden">
      <Calendar/>
    </div>
  </div>
</template>

<style lang="scss">
.admin-scedule-page{
  .calendar-big{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .calendar-month-previewer{
      height: 100%;
      overflow: auto;
      padding: 1px;
    }
  }
}
</style>
