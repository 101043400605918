<script>

import AdminSidebar from "@/components/admin/AdminSidebar"
import AdminNavbar from "@/components/admin/AdminNavbar"

import UiAlert from '@/components/admin/UiAlert'
import UiConfirm from '@/components/admin/UiConfirm'

import IconChat from '@/assets/imgs/icons/icon-chat.svg'
import ChatWidget from '@/components/chat/ChatWidget'

export default {
  name: 'Dashboard',
  components:{
    AdminSidebar,
    AdminNavbar,
    UiAlert,
    IconChat,
    ChatWidget,
    UiConfirm
  },
  data: () => ({
    chatActivated: false
  })
}
</script>

<template>
  <div class="admin-wrapper">
    <AdminSidebar />
    <div class="admin-main-wrapper">
      <div class="admin-navbar-wrapper">
        <AdminNavbar />
      </div>
      <div class="admin-pages-wrapper flex-grow-1 overflow-hidden">
        <router-view name="dashboardPage"/>
      </div>
      <UiAlert v-if="$store.state.admin.uiAlert.handler"/>
      <UiConfirm v-if="$store.state.admin.uiConfirm.handler"/>
    </div>
    <button @click.stop="chatActivated = !chatActivated" class="overlay-ui-icon-button" id="icon-chat">
      <IconChat/>
    </button>
    <ChatWidget
      v-if="chatActivated"
      :isOpen="chatActivated"
      :admin="true"
      @chatboxClose="chatActivated = !chatActivated"
    />
  </div>
</template>

<style lang="scss" scoped>
.admin-wrapper{
  // font-family: Optimistic Display, sans-serif;
  display: grid;
  grid-template-columns: 260px calc(100% - 260px);
  grid-template-rows: 1fr;
  height: 100vh;
  background-color: rgb(var((--v-theme-admin-grey)));
  overflow: hidden;
  color: rgb(var(--v-theme-admin-text));
}
.admin-main-wrapper{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.admin-navbar-wrapper, .admin-pages-wrapper{
  padding-left:15px;
  padding-right:15px;
  width: 100%;
}
.admin-navbar-wrapper{
  padding-top: 15px;
  padding-bottom: 7.5px;
}
.admin-pages-wrapper{
  padding-top: 7.5px;
  padding-bottom: 15px;
}
.admin-navbar {
  height: 76px;
}
#icon-chat{
  right: 25px;
  bottom: 15px;
  background: #fff;
  box-shadow:0 0 15px -1px rgba(0,0,0,0.25);
  .icon-tooltip{
    right:130%;
    top:50%;
    transform:translateY(-50%);
  }
  &:hover{
    .icon-tooltip{
      right:115%;
    }
  }
}


</style>
