<script>
import * as Sentry from "@sentry/vue";

import LocalizedTextField from '@/components/fields/LocalizedText'
import LocalizedCKEditorField from '@/components/fields/LocalizedCKEditor'
import DropdownField from '@/components/fields/Dropdown'
import { ActivityType } from '@/lib/enums'

const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')

export default {
  name: 'ActivityFollowupEmail',
  components:{
    LocalizedTextField,
    DropdownField,
    LocalizedCKEditorField
  },
  data() {
    return{
      data:{
        activity:null,
        footerImage:null,
        headerImage:null,
        body:{},
        subject:{}
      },
      loading:false,
      startHeaderUploading:false,
      startFooterUploading:false,
      success: false,
      error: false
    }
  },
  computed: {
    activityOptions() { 
      let allEvents = this.$store.getters['collections/activities/list'] || [];
      let calendarEvents = allEvents.filter(p => p.type === ActivityType.Presentation)
      return Object.values(calendarEvents).reduce((acc,u) => {
        acc[u.id] = this.$l(u.content.name);
        return acc
      }, {})
    },
  },
  methods:{
    async uploadheader(f){
      this.startHeaderUploading = true;
      const file = f.target.files[0];
      const details = await this.$store.dispatch('user/uploadCKMedia', file);
      this.data.headerImage = details.default;
      this.startHeaderUploading = false;
    },
    async uploadFooter(f){
      this.startFooterUploading = true;
      const file = f.target.files[0];
      const details = await this.$store.dispatch('user/uploadCKMedia', file);
      this.data.footerImage = details.default;
      this.startFooterUploading = false;
    },
    async sendEmail(){
      this.loading = true;

      let invitationData = this.data
      if(!this.data.headerImage){
        delete invitationData.headerImage
      }
      if(!this.data.footerImage){
        delete invitationData.footerImage
      }

      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      var addMessage = firebase.functions().httpsCallable('sendActivityInvitations');
      addMessage(invitationData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.data.activity = null;
          this.data.invitees = [];
          this.data.footerImage = null;
          this.data.headerImage = null;
          this.success = true
        })
        .catch((error) => {
          Sentry.captureException(error)
          this.loading = false;
          console.log(error);
          this.error = true
        });
    }
  }
}
</script>

<template>
  <div class="create-email-page h-100 overflow-y-auto custom-scrollbar">
    <div class="w-100 h-full">
      <div>
        <label>{{ $t('admin.emailPage.createEmailPage.fields.headerImage.label') }}</label>
        <div class="mt-2 email-image-upload d-flex align-items-center">
          <input type="file" @change="uploadheader" :disabled="loading" accept="image/*"/>
          <v-icon v-if="startHeaderUploading" class="spinner" size="sm">mdi-loading</v-icon>
          <img class="uploaded-image" :src="data.headerImage" v-if="data.headerImage" />
        </div>
      </div>
      <div class="mt-6">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.footerImage.label') }}</label>
        <div class="mt-2 email-image-upload d-flex align-items-center">
          <input type="file" @change="uploadFooter" :disabled="loading" accept="image/*"/>
          <v-icon v-if="startFooterUploading" class="spinner" size="sm">mdi-loading</v-icon>
          <img class="uploaded-image" :src="data.footerImage" v-if="data.footerImage" />
        </div>
      </div>
      <div class="mt-6">
          <label>{{ $t('admin.activityPage.createActivityPage.fileds.chooseActivity') }}</label>
          <div class="mt-2">
            <DropdownField
              :disabled="loading || $store.state.ui.busy"
              :options="activityOptions"
              v-model="data.activity"
              class="mb-5 activity-choose-dropdown"
              :placeholder="$t('admin.activityPage.createActivityPage.fileds.chooseActivity')"
            />
          </div>
      </div>
      <div class="mt-6">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.subject.label') }}</label>
        <div class="mt-2">
          <LocalizedTextField :modelValue="data.subject" />
        </div>
      </div>
      <div class="mt-6">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.message.label') }}</label>
        <div class="mt-2">
          <LocalizedCKEditorField
            :disabled="loading || $store.state.ui.busy"
            :placeholder="$t('admin.emailPage.createEmailPage.fields.message.label')"
            v-model="data.body"
          />
        </div>
      </div>
      <div class="py-6 text-right">
        <span v-if="success" class="mr-4 text-admin-green-400">{{ $t('admin.emailPage.createEmailPage.successMessage') }}</span>
        <span v-if="error" class="mr-4 text-admin-danger">{{ $t('admin.emailPage.createEmailPage.errorMessage') }}</span>
        <v-btn
          color="primary"
          :disabled="loading || $store.state.ui.busy"
          @click="sendEmail"
        >
          <v-icon v-if="loading" class="spinner mr-2" size="sm">mdi-loading</v-icon>
          {{$t('buttons.sendEmail')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-email-page{
  padding: 20px 40px;
}
.email-image-upload{
  input{
    width:250px;
  }
  img{
    width:150px;
    height:70px;
    object-fit: contain;
    object-position: center;
  }
}
</style>
