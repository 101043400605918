<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.tz.setDefault("America/California");

export default {
  name: "CalendarMonthChanger",

  props: {
    currentDate: {
      type: String,
      required: true
    },

    selectedDate: {
      type: Object,
      required: true
    }
  },

  methods: {
    selectPrevious() {
      let newSelectedDate = dayjs(this.selectedDate).subtract(1, "month");
      this.$emit("dateSelected", newSelectedDate);
    },

    selectCurrent() {
      let newSelectedDate = dayjs(this.currentDate);
      this.$emit("dateSelected", newSelectedDate);
    },

    selectNext() {
      let newSelectedDate = dayjs(this.selectedDate).add(1, "month");
      this.$emit("dateSelected", newSelectedDate);
    }
  }
};
</script>

<template>
<div class="d-flex align-items-center">
  <v-btn
    flat
    elevation="0"
    icon="mdi-arrow-left"
    size="small"
    color="white"
    class="mx-1"
    :disabled="$store.state.ui.busy"
    @click="selectPrevious"
  />
  <v-btn
    flat
    elevation="0"
    color="white"
    class="mx-1"
    :disabled="$store.state.ui.busy"
    @click="selectCurrent"
  >
  {{ $t('admin.schedulingPage.monthSwiper.label') }}
  </v-btn>
  <v-btn
    flat
    elevation="0"
    icon="mdi-arrow-right"
    size="small"
    color="white"
    class="mx-1"
    :disabled="$store.state.ui.busy"
    @click="selectNext"
  />
</div>
</template>
