<script>
import TouchPoints from '@/touchpoints'
import EasterEggs from '@/eggs'
import { Scene, ActivityType } from '@/lib/enums'
import DropdownField from '@/components/fields/Dropdown'

// console.log('tp', TouchPoints)

import capitalize from '@/utils/capitalize'


export default {
  name: 'SceneTouchpoints',
  components: {
    DropdownField
  },
  data: () => ({
    scene: {
      quiz: ''
    },
    tpCollection: null,
    tpSub: null,
    touchpoints: {},
    egCollection: null,
    egSub: null,
    eastereggs: {},
    actionsList: {},
    loading: false,
    reloading: false
  }),
  async created() {
    await this.$store.dispatch('admin/scenes/init/next')
    const scene = this.$store.getters['admin/scenes/byID'](this.sceneName)
    if (!scene) {
      await this.$store.dispatch('admin/scenes/save',{
        id: this.sceneName,
        quiz: null
      })
      // console.log('create defaults for scene')
    }
    await this.reloadScene()
  },
  setup: () => ({ capitalize }),
  computed: {
    sceneName() {
      return this.$route.params.scene || ' '
    },
    sceneID() {
      const code = capitalize(this.sceneName)
      return Scene[code]
    },
    touchpointIDs() {
      const ret = Object.keys(this.touchpointsData)
      return ret
    },
    touchpointsData() {
      return TouchPoints[this.sceneName].reduce((acc, tp) => {
        acc[tp.id] = tp
        return acc
      }, {})
    },
    easterEggsIDs() {
      const ret = Object.keys(this.easterEggsData)
      return ret
    },
    easterEggsData() {
      return EasterEggs[this.sceneName].reduce((acc, tp) => {
        acc[tp.id] = tp
        return acc
      }, {})
    },
    quizzesOpts() {
      const ret = {}
      ret[''] = this.$t('admin.touchPointsPage.labels.none')
      const list = this.$store.getters['admin/activities/list'].filter(a => {
        return a.type === ActivityType.Quizz2D
      })
      list.forEach(a => {
        ret[a.id] = this.$l(a.content.name)
      })
      //console.log(list)
      return ret
    },
    hasEasterEggs() {
      return EasterEggs[this.sceneName] && EasterEggs[this.sceneName].length
    }
  },
  methods: {
    touchpointName(id) {
      return  this.touchpointsData[id].name
    },
    easterEggName(id) {
      return  this.easterEggsData[id].name
    },
    reloadActions() {
      const list = this.$store.getters['admin/activities/list']
      this.actionsList = list.reduce((acc,act) => {
        acc[act.id] = this.$l(act.content.name)
        return acc
      }, {})
      this.actionsList[''] = this.$t('admin.touchPointsPage.labels.none')
      // console.log(this.actionsList)
    },
    async reloadScene() {
      this.reloading = true
      /**/
      // console.log(this.sceneName, this.sceneID, TouchPoints[this.sceneName])
      //const sceneName = this.$route.params.scene
      this.scene = this.$store.getters['admin/scenes/byID'](this.sceneName)
      this.touchpoints = TouchPoints[this.sceneName].reduce((acc,sc) => {
        acc[sc.id] = {
          activity: ''
        }
        return acc
      }, {})
      this.eastereggs = (EasterEggs[this.sceneName] || []).reduce((acc,sc) => {
        acc[sc.id] = {
          activity: ''
        }
        return acc
      }, {})

      const coll = await this.$store.getters['admin/scenes/collection']()
      if (this.tpSub) {
        this.tpSub()
      }
      if (this.egSub) {
        this.egSub()
      }
      // touchpoints
      this.tpCollection = coll.doc(this.sceneName).collection('touchpoints')
      this.tpSub = this.tpCollection.onSnapshot(snap => {
        if (this.loading) return
        snap.forEach(doc => {
          const docData = doc.data()
          docData.id = parseInt(doc.id)
          this.touchpoints[docData.id] = docData
        })
      })

      // easter eggs
      this.egCollection = coll.doc(this.sceneName).collection('eastereggs')
      this.egSub = this.egCollection.onSnapshot(snap => {
        if (this.loading) return
        snap.forEach(doc => {
          const docData = doc.data()
          docData.id = parseInt(doc.id)
          this.eastereggs[docData.id] = docData
        })
      })
 
      this.reloadActions()

      this.reloading = false
    },
    async saveTps() { 
      if (!this.tpCollection) return
      // console.log
      this.loading = true
      Object.keys(this.touchpoints).forEach(async tid => {
        const ref = this.tpCollection.doc(`${tid}`)
        await ref.set(this.touchpoints[tid])
      })
      this.loading = false
    },
    async saveEgs() { 
      if (!this.egCollection) return
      // console.log
      this.loading = true
      Object.keys(this.eastereggs).forEach(async tid => {
        const ref = this.egCollection.doc(`${tid}`)
        await ref.set(this.eastereggs[tid])
      })
      this.loading = false
    },
    async saveDoc() {
      this.loading = true
      await this.$store.dispatch('admin/scenes/update',{
        id: this.sceneName,
        doc: { quiz: this.scene.quiz }
      })
      this.loading = false
    }
  },
  watch: {
    '$store.admin.scenes.data'() {
      this.reloadScene()
    },
    '$store.admin.activities.data'() {
      this.reloadActions()
    },
    touchpoints: {
      deep: true,
      async handler() {
        // console.log('next tps', next)
        await this.saveTps()
      }
    },
    eastereggs: {
      deep: true,
      async handler() {
        // console.log('next tps', next)
        await this.saveEgs()
      }
    },
    async 'scene.quiz'() {
      if (this.reloading) return
      // console.log('next quiz', next)
      await this.saveDoc()
    }
  }
}
</script>

<template>
<div class="h-full bg-white pa-6 admin-rounded d-flex flex-column overflow-y-auto custom-scrollbar">
  <div class="mb-4">
    <h3>{{capitalize(sceneName)}}</h3>
    <h4>{{$t('admin.touchPointsPage.labels.endOfSceneQuiz')}}</h4>
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <label>
            {{$t('admin.touchPointsPage.labels.endOfSceneQuiz')}}
          </label>
        </v-col>
        <v-col>
          <DropdownField
            :maxHeight="400"
            :disabled="$store.state.ui.busy"
            v-model="scene.quiz"
            :options="quizzesOpts"
          />
        </v-col>
      </v-row>
    </v-container>
    <h4>{{$t('admin.touchPointsPage.labels.touchPoints')}}</h4>
    <v-container fluid>
      <div v-for="tpid of touchpointIDs" :key="tpid">
        <v-row v-if="touchpointName(tpid) !== ''">
          <v-col cols="3">
            <label>
              {{touchpointName(tpid)}}
            </label>
          </v-col>
          <v-col>
            <DropdownField
              v-if="touchpoints[tpid]"
              :maxHeight="400"
              :disabled="$store.state.ui.busy"
              v-model="touchpoints[tpid].activity"
              :options="actionsList"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div v-if="hasEasterEggs">
      <h4>Easter eggs</h4>
      <v-container fluid>
        <div v-for="tpid of easterEggsIDs" :key="tpid">
          <v-row v-if="easterEggName(tpid) !== ''">
            <v-col cols="3">
              <label>
                {{easterEggName(tpid)}}
              </label>
            </v-col>
            <v-col>
              <DropdownField
                v-if="eastereggs[tpid]"
                :maxHeight="400"
                :disabled="$store.state.ui.busy"
                v-model="eastereggs[tpid].activity"
                :options="actionsList"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</div>
</template>
