<script>
import TextField from '@/components/fields/Text'
import DropdownField from '@/components/fields/Dropdown'
import LocalizedTextField from '@/components/fields/LocalizedText'
import LocalizedCKEditorField from '@/components/fields/LocalizedCKEditor'
import { Locale, ActivityType } from '@/lib/enums'

export default {
  name: 'QuizInfoEditor',
  components:{
    TextField,
    DropdownField,
    LocalizedTextField,
    LocalizedCKEditorField
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({
        content: {
          name: {},
          description: {},
          intro: {},
          maxPlayers: 1,
          questions: [],
          scores: {
            success: 80,
            successMessage: {},
            failMessage: {},
          }
        },
        type: ActivityType.Quizz2D,
      })
    },
    mode: {
      type: String,
      default: 'new',
    }
  },
  emits: ['update:modelValue','deleteQuiz'],
  data() { return {
    loading: false,
    quiz: this.modelValue.content,
    type: this.modelValue.type,
    strType: '2d'
  }},
  watch: {
    modelValue: {
      deep: true,
      handler(next) {
        this.quiz = next.content
        this.type = next.type
        this.strType = this.type === ActivityType.Quizz2D ? '2d' : '3d'
      }
    }
  },
  methods: {
    async createQuiz() {
      const emit = {
        content: this.quiz,
        type: this.type,
      }
      if (this.modelValue && this.modelValue.id)
        emit.id = this.modelValue.id
      this.$emit('update:modelValue',emit)
    },
    typeChange(next) {
      this.type = next === '2d' ? ActivityType.Quizz2D : ActivityType.Quizz3D
    }
  },
  computed: {
    typeOptions() {
      return {
        '2d': this.$t(`admin.activity.type.${ActivityType.Quizz2D}`),
        '3d': this.$t(`admin.activity.type.${ActivityType.Quizz3D}`)
      }
    },
    isValid() {
      return this.quiz.name[`${Locale.en}`]?.length &&
             this.quiz.description[`${Locale.en}`]?.length &&
             this.quiz.intro[`${Locale.en}`]?.length 
    }
  }
}
</script>

<template>
<div v-if="mode === 'new'" class="py-6">
  <h3>
    <router-link class="mr-1 text-black" :to="{ name: 'QuizesList' }">
      <v-icon dark left>mdi-arrow-left</v-icon>
    </router-link>
    {{$t('admin.quizesPage.createQuizPage.title')}}
  </h3>
</div>
<div>
  <label>{{$t('admin.quizesPage.createQuizPage.fields.name')}}</label>
  <LocalizedTextField
    :disabled="loading || $store.state.ui.busy"
    class="mb-5 create-quiz-input"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.name')"
    v-model="quiz.name"
  />
  <label>{{$t('admin.quizesPage.createQuizPage.fields.description')}}</label>
  <LocalizedCKEditorField
    :disabled="loading || $store.state.ui.busy"
    class="mb-5"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.description')"
    v-model="quiz.description"
  />
  <label>{{$t('admin.quizesPage.createQuizPage.fields.intro')}}</label>
  <LocalizedCKEditorField
    :disabled="loading || $store.state.ui.busy"
    class="mb-5"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.intro')"
    v-model="quiz.intro"
  />
  <label>{{$t('admin.quizesPage.createQuizPage.fields.maximumPersonAllowed')}}</label>
  <TextField
    type="number"
    :disabled="loading || $store.state.ui.busy"
    class="mb-5 create-quiz-input"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.maximumPersonAllowed')"
    v-model="quiz.maxPlayers"
  />
  <label>{{$t('admin.quizesPage.createQuizPage.fields.type')}}</label>
  <DropdownField
    :disabled="loading || $store.state.ui.busy"
    class="mb-5"
    :placeholder="$t('admin.quizesPage.createQuizPage.fields.type')"
    :options="typeOptions"
    v-model="strType"
    @update:modelValue="typeChange"
  />
  <div class="d-flex justify-content-between">
    <v-btn
      v-if="mode !== 'new'"
      :disabled="loading || $store.state.ui.busy"
      :loading="loading"
      @click="$emit('deleteQuiz')"
      color="warning"
      class="mr-5"
    >
      <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
      <span v-else>
        {{$t('admin.quizesPage.question.deleteQuiz')}}
      </span>
    </v-btn>
    <v-btn
      :disabled="!isValid || loading || $store.state.ui.busy"
      :loading="loading"
      @click="createQuiz()"
      color="primary"
    >
      <v-icon v-if="loading" class="spinner" size="sm">mdi-loading</v-icon>
      <span v-else>
        <span v-if="mode === 'new'">{{$t('admin.quizesPage.createQuizPage.fields.submitButton')}}</span>
        <span v-else>{{$t('admin.quizesPage.createQuizPage.fields.submitEditButton')}}</span>
      </span>
    </v-btn>
  </div>
  <!--
  -->
</div>
<!--
<div class="text-fb-navy bg-white border rounded pa-2">
  {{quiz}}
</div>
-->
</template>

<style lang="scss" scoped>
.create-quiz-input{
  box-shadow: 0 none;
  border:1px solid rgb(var(--v-theme-admin-grey));
  border-radius:4px;
}
</style>
