<script>
import * as Sentry from "@sentry/vue";
import CKEditorField from '@/components/fields/CKEditor'
import TextField from '@/components/fields/Text'
import MultiselectField from '@/components/fields/Multiselect'
//import waitFor from '@/utils/waitFor'
const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')
export default {
  name: 'EmailCreate',
  components:{
    CKEditorField,
    MultiselectField,
    TextField
  },
  data() {
    return{
      data:{
        metadata:null,
        userIDs:[],
        userEmails:[],
        //labels:[],
        data:{
          to: [],
          from: process.env.VUE_APP_SENDGRID_FROM_EMAIL,
          subject: "",
          text: "text",
          html: "",
        },
        isMultiple:false
      },
      pdf:null,
      startPdfUploading:false,
      loading:false,
      users: {},
      success: false,
      error: false
    }
  },
  computed: {
    usersMultiselectOptions() {
      return Object.values(this.users).reduce((acc,u) => {
        acc[u.email] = `${u.displayName} <${u.email}>`
        return acc
      }, {})
    },
    // labelsOptions() {
    //   return {
    //     urgent: this.$t('admin.emailPage.labels.urgent'),
    //     work: this.$t('admin.emailPage.labels.work'),
    //     friends: this.$t('admin.emailPage.labels.friends'),
    //     family: this.$t('admin.emailPage.labels.family'),
    //     webinars: this.$t('admin.emailPage.labels.webinars')
    //   }
    // }
  },
  async created() {
    // I work here with collection, not with store, because on this page I need single time loaded list of users, not "realtime updated"
    // this function can be moved to store, if anywhere else same functionality will be required
    const collection = await this.$store.getters['admin/users/collection']()
    const options = {
      query: ['approved', '==', true],
      orderBy: ['email', 'desc']
    }
    const ref = collection
      .where(...options.query)
      .orderBy(...options.orderBy)
      const snap = await ref.get()
      const docs = {}
      snap.forEach(rec => {
        const doc = rec.data()
        doc.uid = rec.id
        docs[rec.id] = doc
      })
      this.users = docs
  },
  methods:{
    async uploadPdf(f){
      this.startPdfUploading = true;
      const file = f.target.files[0];
      const details = await this.$store.dispatch('user/uploadCKMedia', file);
      this.data.data.html = this.data.data.html + `<a href="${details.default}">${details.default}</a>`;
      this.startPdfUploading = false;
    },
    async sendEmail(){
      this.loading = true;
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      var addMessage = firebase.functions().httpsCallable('sendEmail');
      addMessage(this.data)
        .then(() => {
          this.loading = false;
          this.data.metadata = null;
          this.data.userIDs = [];
          this.data.userEmails = [];
          this.data.labels = [];
          this.data.data.to = [];
          this.data.data.subject = "";
          this.data.data.text = "";
          this.data.data.html = "";
          this.data.isMultiple = false;
          this.success = true
        })
        .catch((error) => {
          Sentry.captureException(error)
          console.log(error);
          this.error = true;
          this.loading = false;
        });
    }
  },
  watch:{
    'data.data.subject'(){
      this.data.metadata = this.data.data.subject;
    },
    'data.data.to'(){
      this.data.userEmails = this.data.data.to;
    },
  }
}
</script>

<template>
  <div class="create-email-page h-100 overflow-y-auto custom-scrollbar">
    <div class="w-100 h-full">
      <div class="email-form-field">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.subject.label') }}</label>
        <div class="mt-2">
          <TextField v-model="data.data.subject" :placeholder="$t('admin.emailPage.createEmailPage.fields.subject.placeholder')"/>
        </div>
      </div>
      <div class="email-form-field mt-6">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.user.label') }}</label>
        <div class="mt-2">
          <MultiselectField
            v-model="data.data.to"
            :options="usersMultiselectOptions"
            :disabled="$store.state.ui.busy"
            :maxHeight="150"
            :customTag="true"
            :placeholder="$t('admin.emailPage.createEmailPage.fields.user.placeholder')"
            :customTagPlaceholder="$t('admin.emailPage.createEmailPage.writeCustomEmail')"
          />
        </div>
      </div>
      <!-- <div class="email-form-field mt-6">
          <label>{{ $t('admin.emailPage.createEmailPage.fields.labels.label') }}</label>
          <div class="mt-2">
            <MultiselectField
              v-model="data.labels"
              :options="labelsOptions"
              :disabled="$store.state.ui.busy"
              :maxHeight="150"
              :placeholder="$t('admin.emailPage.createEmailPage.fields.labels.placeholder')"
            />
          </div>
      </div> -->
      <div class="mt-6">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.message.label') }}</label>
        <div class="mt-2 font-size-14 text-light">
          <CKEditorField v-model="data.data.html"/>
        </div>
      </div>
      <div class="mt-6">
        <label>{{ $t('admin.emailPage.createEmailPage.fields.pdfUpload.label') }}</label>
        <div class="mt-2 email-image-upload d-flex align-items-center">
          <input type="file" @change="uploadPdf" :disabled="loading" accept="application/pdf"/>
          <v-icon v-if="startPdfUploading" class="spinner" size="sm">mdi-loading</v-icon>
        </div>
      </div>
      <div class="py-6 text-right">
        <span v-if="success" class="mr-4 text-admin-green-400">{{ $t('admin.emailPage.createEmailPage.successMessage') }}</span>
        <span v-if="error" class="mr-4 text-admin-danger">{{ $t('admin.emailPage.createEmailPage.errorMessage') }}</span>
        <v-btn
          color="primary"
          :disabled="loading || $store.state.ui.busy"
          @click="sendEmail"
        >
          <v-icon v-if="loading" class="spinner mr-2" size="sm">mdi-loading</v-icon>
          {{$t('buttons.sendEmail')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-email-page{
  padding: 20px 40px;
}
</style>
