<script>
import CKEditorField from "@/components/fields/LocalizedCKEditor";
import LocalizedTextField from "@/components/fields/LocalizedText";
import LocalizedDropdownField from "@/components/fields/LocalizedDropdown";
// import Checkbox from '@/components/fields/Checkbox'
// import FileField from '@/components/File'
import FileCollection from "@/components/admin/FileCollection";

export default {
  name: "ActivityContent",
  components: {
    FileCollection,
    CKEditorField,
    LocalizedTextField,
    LocalizedDropdownField,
    // Checkbox,
    // FileField
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        name: {},
        description: {},
        content: {},
        isDownloadable: false,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "new",
    },
    activityId: {
      type: String,
      default: "",
    },
    files: {
      type: Object,
      default: () => ({}),
    },
    mediaCollection: {
      // type: Object,
      required: true,
      validate: (val) => typeof val === "object" || val === null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      content: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      deep: true,
      handler(next) {
        this.content = next;
      },
    },
    content: {
      deep: true,
      handler(next) {
        //console.log(next)
        this.$emit("update:modelValue", next);
      },
    },
  },
  created() {
    let upd = false;
    if (!this.content.name) {
      this.content.name = {};
      upd = true;
    }
    if (!this.content.description) {
      this.content.description = {};
      upd = true;
    }
    if (!this.content.content) {
      this.content.content = [];
      upd = true;
    }
    if (upd) this.$emit("update:modelValue", this.content);
  },
  setup() {
    return {};
  },
  computed: {
    bucket: () => process.env.VUE_APP_FIREBASE_STORAGE_ACTIVITY_UPLOAD_BUCKET,
    filesPrefix() {
      return "/" + this.activityId + "/";
    },
    filesOptions() {
      return Object.keys(this.files || {}).reduce((acc, fid) => {
        acc[fid] = this.files[fid].details.filename;
        return acc;
      }, {});
    },
  },
  methods: {
    addMedia() {
      this.content.content.push({
        media: "",
        downloadable: false,
      });
    },
    async deleteMedia(mi) {
      const ok = await this.$store.dispatch("admin/confirm", {
        title: this.$t("admin.activityPage.dialogs.deleteMedia.title"),
        message: this.$t("admin.activityPage.dialogs.deleteMedia.message"),
      });
      if (!ok) return;
      this.content.content = this.content.content.filter((x, xi) => xi !== mi);
    },
  },
};
</script>

<template>
  <div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t("admin.activityPage.createActivityPage.fileds.name") }}
      </div>
      <div>
        <LocalizedTextField :modelValue="content.name" />
      </div>
    </div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t("admin.activityPage.createActivityPage.fileds.description") }}
      </div>
      <div class="">
        <CKEditorField v-model="content.description" />
      </div>
    </div>
    <div v-if="mode !== 'new' && mediaCollection">
      <div class="create-activity-field mb-6">
        <div>
          {{ $t("admin.activityPage.createActivityPage.fileds.contentHeader") }}
        </div>
        <div>
          <LocalizedDropdownField
            v-model="content.content"
            :disabled="$store.state.ui.busy"
            :options="filesOptions"
          />
          <!--
        <FileField
          v-model="content.file"
          :disabled="$store.state.ui.busy"
          :collection="mediaCollection"
          :files="files"
          :storage="bucket"
          :refPrefix="`/${activityId}/`"
        />
        --></div>
      </div>
      <!--
    <div
      class="create-activity-field mb-6"
    >
      <div>
        {{ $t('admin.activityPage.createActivityPage.labels.makeItDownloadable') }}
      </div>
      <Checkbox
        :disabled="$store.state.ui.busy"
        v-model="content.isDownloadable"
      />
    </div>
    -->

      <FileCollection
        :label="$t('admin.activityPage.createActivityPage.labels.files')"
        :files="files"
        :collection="mediaCollection"
        :bucket="bucket"
        :refPrefix="filesPrefix"
      />

      <!--
        <LocalizedDropdownField
          v-model="content.files"
          :disabled="$store.state.ui.busy"
          :options="filesOptions"
        />
      {{activityId}}
      -->
      <!--
      <h4>
          {{ $t('admin.activityPage.createActivityPage.fileds.contentHeader') }}
          <v-btn
            icon="mdi-plus"
            flat
            elevation="0"
            size="x-small"
            :disabled="content?.content.length > 9"
            @click="addMedia()"
          />
      </h4>
      <div
        v-for="media, mi in content.content"
        :key="mi"
        class="create-activity-field mb-6"
      >
        <div>
          {{ $t('admin.activityPage.createActivityPage.fileds.mediaFile') }}
        </div>
        <div v-if="typeof media.media === 'string'">
          <div>
            <FileField
              v-model="content.content[mi].media"
              :disabled="$store.state.ui.busy"
              :collection="mediaCollection"
              :files="files"
              :storage="bucket"
              :refPrefix="`/${activityId}/`"
            />
          </div>
          <div class="mt-2">
            <Checkbox v-model="content.content[mi].downloadable" /> <span class="ml-1">{{ $t('admin.activityPage.createActivityPage.labels.makeItDownloadable') }}</span>
          </div>
          <v-btn
            class="my-2"
            size="small"
            @click="deleteMedia(mi)"
          >
            <v-icon>mdi-minus</v-icon>
            {{ $t('admin.activityPage.createActivityPage.fileds.mediaDelete') }}
          </v-btn>
        </div>
      </div>
      -->
    </div>
    <div v-else>
      {{ $t("admin.activityPage.createActivityPage.labels.createRequired") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-activity-field-checkbox {
  > span {
    vertical-align: middle;
  }
}
</style>
