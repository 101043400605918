<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import FirebaseUploadAdapter from '@/utils/ckeditorUploadAdapter'
import { Locale } from '@/lib/enums.js'

import FlagUS from '@/assets/imgs/icons/flags/usa.svg'
import FlagFR from '@/assets/imgs/icons/flags/france.svg'
import FlagES from '@/assets/imgs/icons/flags/spain.svg'

export default {
  name: 'LocalizedCKEditorField',
  components: {
    FlagUS,
    FlagFR,
    FlagES
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    toolbar: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['update:modelValue'],
  setup: () => ({ Locale }),
  data() {
    const toolbar = ['bold', 'italic', /*'underline',*/ 'bulletedList', 'numberedList', ...this.toolbar]
    return {
      html: this.modelValue || {
        [Locale.en]: '',
        [Locale.es]: '',
        [Locale.fr]: '',
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: this.placeholder,
        extraPlugins: [FirebaseUploadAdapter],
        toolbar
      },
      open: false,
      locale: Locale.en
    }
  },
  watch: {
    html: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', this.html)
      }
    },
    modelValue(next) {
      this.html = next
    }
  }
}
</script>

<template>
<div class="field-wrapper d-flex">
  <ckeditor
    v-model="html[locale]"
    :editor="editor"
    :config="editorConfig"
    :disabled="disabled"
  />
  <div class="locale-selector flex-shrink-0 ml-2">
    <div class="d-flex flex-column">
      <v-btn
        icon
        flat
        elevation="0"
        :class="{ 'selected-locale': locale === Locale.en }"
        @click="locale = Locale.en"
      >
        <FlagUS/>
      </v-btn>
      <v-btn
        icon
        flat
        elevation="0"
        :class="{ 'selected-locale': locale === Locale.fr }"
        @click="locale = Locale.fr"
      >
        <FlagFR/>
      </v-btn>
      <v-btn
        icon
        flat
        elevation="0"
        :class="{ 'selected-locale': locale === Locale.es }"
        @click="locale = Locale.es"
      >
        <FlagES/>
      </v-btn>
    </div>
  </div>

</div>

</template>

<style>
.ck.ck-editor {
  flex-grow: 1 !important;
}
.ck.ck-editor .ck-editor__main .ck-content {
    min-height: 150px;
}
button.selected-locale {
  border: 1px solid rgb(var(--v-theme-fb-navy));
}
</style>
