<script>
export default {
  name: 'ActivityPage',
}
</script>

<template>
  <div class="activity-page-wrapper h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <router-view name="activityUI"/>
  </div>
</template>

<style lang="scss" scoped>

</style>