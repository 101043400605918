/** Deletes file from storage and collection */
const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')
import * as Sentry from "@sentry/vue";

const fileDelete = async ({
  id,
  collection,
  storage,
  refPrefix = '',
  refSuffix = '',
  dbOnly = true
}) => {
  if (!id) return

  const firebaseModule = await firebaseImport()
  const firebase = firebaseModule.default

  const filePath = `${refPrefix}${id}${refSuffix}`
  const ref = storage 
    ? firebase.app().storage(`gs://${storage}`).ref(filePath)
    : firebase.storage().ref(filePath)

  if (!dbOnly) {
    try {
      await ref.delete()
    } catch (err) {
      Sentry.captureException(err)
      console.log('deleting file error', err, err.code)
      return { error: err }
    }
  }
  if (!collection)
    return

  const refDoc = collection.doc(id)
  await refDoc.delete()
}

export default fileDelete
