<script>
import { useI18n } from 'vue-i18n'
export default {
  name: 'AdminUiConfirm',
  setup() {
    const { t } = useI18n()
    return { t }
  },
}
</script>

<template>
<div class="dialog-overlay">
  <div class="dialog-window bg-white elevation-4 border rounded-lg pa-5">
    <h3 class="mb-5" v-if="$store.state.admin.uiConfirm.title !== ''">
      <component class="mr-5" v-if="$store.state.admin.uiConfirm.icon" :is="$store.state.admin.uiConfirm.icon"/>
      {{$store.state.admin.uiConfirm.title}}
    </h3>
    <p>{{$store.state.admin.uiConfirm.message}}</p>
    <div class="text-right mt-5">
      <v-btn class="mr-2" @click="$store.commit('admin/closeConfirm',false)">{{t('buttons.no')}}</v-btn>
      <v-btn @click="$store.commit('admin/closeConfirm',true)">{{t('buttons.yes')}}</v-btn>
    </div>
  </div>
</div>
</template>

<style scoped>
.dialog-window {
  min-width: 350px;
}
</style>
