<script>

export default {
  name: 'QuizQuestionsList',
  props: ['quiz', 'loading', 'selected'],
  emits: ['addQuestion', 'showQuestion', 'showInfo','showFiles','showScores']
}
</script>

<template>
  <div class="bg-admin-grey-3 overflow-y-auto custom-scrollbar px-2 py-1">
    <div class="px-4 py-6 mt-n1">
      <div class="mb-3">
        <router-link class="mr-1 text-black d-flex" :to="{ name: 'QuizesList' }">
          <v-icon dark left>mdi-arrow-left</v-icon>
          <h3>{{$t('admin.sidebarLinks.quizes')}}</h3>
        </router-link>
      </div>
      <div class="mb-3">
        <v-btn
          flat
          block
          elevation="0"
          class="align-left"
          :class="{'text-bold': -1 === selected}"
          @click="$emit('showInfo')"
        >
          {{$t('admin.quizesPage.quizEditor.sidebar.mainInfo')}}
        </v-btn>
        <v-btn
          flat
          block
          elevation="0"
          class="align-left"
          :class="{'text-bold': -2 === selected}"
          @click="$emit('showFiles')"
        >
          {{$t('admin.quizesPage.quizEditor.sidebar.images')}}
        </v-btn>
        <v-btn
          flat
          block
          elevation="0"
          class="align-left"
          :class="{'text-bold': -3 === selected}"
          @click="$emit('showScores')"
        >
          {{$t('admin.quizesPage.quizEditor.sidebar.rules')}}
        </v-btn>
      </div>
      <div class="d-flex mb-3 justify-content-between">
        <h3>{{$t('admin.quizesPage.quizEditor.sidebar.title')}}</h3>
        <v-btn
          flat
          elevation="0"
          class="button-shift-up"
          icon="mdi-plus"
          size="x-small"
          :disabled="loading || $store.state.ui.busy"
          :loading="loading"
          @click="$emit('addQuestion')"
        />
      </div>
    </div>
    <div v-for="q,i in quiz?.content.questions" :key="i" class="quiz-question-list-item">
      <v-btn
        flat
        block
        elevation="0"
        @click="$emit('showQuestion', i)"
        :class="{'text-bold': i === selected}"
      >
        {{$t('admin.quizesPage.quizEditor.sidebar.questionX', { num: i + 1 })}}
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/assets/_variables.scss';
.quiz-question-list-item{
  color: rgb(var(--v-theme-fb-navy));
  cursor: pointer;
  &:hover{
    background-color: #fff;
  }
}
.button-shift-up {
  position: relative;
  top: -4px;
}
</style>
