<script>
import IconVog from "@/assets/imgs/icons/fb-vog-icon.svg";
import IconTalk from "@/assets/imgs/icons/admin/vog/fb-vog-talk-icon.svg";
import IconLive from "@/assets/imgs/icons/admin/vog/fb-vog-live-icon.svg";
import IconWrite from "@/assets/imgs/icons/admin/vog/fb-vog-write-icon.svg";

import CKEditorField from '@/components/fields/CKEditor'
import Checkbox from '@/components/fields/Checkbox'
import TextField from '@/components/fields/Text'

import audioAnimation from "@/assets/imgs/audio-recording-animation.gif"

let mediaStream = null;
let recording = [];
let seconds = 0;

export default {
  name: 'AdminVoiceOfGodContent',
  components:{
    CKEditorField,
    Checkbox,
    TextField,

    IconVog,
    IconTalk,
    IconLive,
    IconWrite,
  },
  props:['selectedUserLength', 'sending'],
  emits: ['sendTextNotification', 'sendMediaNotification'],
  data(){
    return{
      currentAction:"talk",
      loading:false,
      seconds: 0,
      minute: 0,
      timer:null,
      recodingData:{
        notificationTitle: null,
        notificationUrl: null,
        immidiate: true,
        recordingBlob: null,
        recordingUrl:null,
      },
      talkAction:{
        mode: "initial", // "recording" "recordingCompleted"
      },
      videoAction:{
        mode: "initial", // "recording" "recordingCompleted"
        preview:null,
        previewVideoIsPlaying:false
      },
      contentAction: {
        content: '',
        media: null
      }
    }
  },
  methods: {
    incrementTime() {
      if(seconds < 59){
        this.seconds = parseInt(this.seconds) + 1;
        seconds = this.seconds;
      }else{
        this.minute = parseInt(this.minute) + 1;
        this.seconds = 0;
        seconds = 0;
      }
    },
    async startRecording(type){
      this.loading = true;
      let audio = true;
      let video = false;
      if(type === "video"){
        video = true;
      }
      await navigator.mediaDevices.getUserMedia({ audio: audio, video: video })
      .then(stream => {
        if(video){
          window.localStream = this.videoAction.preview = stream;
        }else{
          window.localStream = stream;
        }
        mediaStream =  new MediaRecorder(stream);
        mediaStream.start();
        this.timer = setInterval(this.incrementTime, 1000);

        mediaStream.addEventListener("dataavailable", event => {
          recording.push(event.data);
        });

        mediaStream.addEventListener("stop", () => {
          let recordingBlob = null;
          if(video){
            recordingBlob = new Blob(recording, { type: "video/mp4" });
          }else{
            recordingBlob = new Blob(recording, { type: "audio/mpeg" });
          }
          this.recodingData.recordingBlob = recordingBlob;
          this.recodingData.recordingUrl = URL.createObjectURL(recordingBlob);
        });

        if(video){
          this.videoAction.mode = "recording";
        }else{
          this.talkAction.mode = "recording";
        }

      });
    },
    finishRecording(type){
      mediaStream.stop();
      clearInterval(this.timer);
      if(type === "video"){
        this.videoAction.mode = "recordingCompleted";
        window.localStream.getVideoTracks()[0].stop();
      }
      this.talkAction.mode = "recordingCompleted";
      window.localStream.getAudioTracks()[0].stop();
    },
    sendNotification(type){
      console.log('send', this.recordingData)
      this.$emit('sendMediaNotification', this.recodingData);
      // reset the timer
      seconds = this.minute = this.seconds = 0;

      //this.recodingData.recordingBlob = null;
      this.recodingData.recordingUrl = null;
      this.recodingData.immidiate = true;
      // this.recodingData.notificationTitle = null;

      if(type === "video"){
        this.videoAction.mode = "initial";
      }else{
        this.talkAction.mode = "initial";
      }
      
      // reset Loading mode
      this.loading = false
    },
    previewVideoPlayToggle(){
      var finalVideo = document.getElementById("final-video");
      if (finalVideo.paused){
        finalVideo.play();
        this.videoAction.previewVideoIsPlaying = true;
      }else{
        finalVideo.pause();
        this.videoAction.previewVideoIsPlaying = false;
      }
    }
  },
  setup(){
    return {  audioAnimation }
  },
  watch: {
    sending(next, prev) {
      if (!next && prev) {
        console.log('send complete, reseting')
        window.localStream = null
        mediaStream = null
        recording = []
        this.recodingData.recordingBlob = null
        this.recodingData.recordingUrl = null
        this.recodingData.immidiate = true
        this.recodingData.notificationTitle = null
        this.recodingData.notificationUrl = null
      }
    }
  }
}
</script>

<template>
  <div
    class="
      vog-content-wrappper
      bg-white
      overflow-y-hidden
      h-full
      d-flex
      justify-content-center
    "
  >
    <div class="vog-content-container overflow-y-auto custom-scrollbar pa-8">
      <div class="text-center mb-6">
        <IconVog />
        <h3>{{ $t("admin.voiceOfGodPage.labels.vog") }}</h3>
      </div>

      <!-- Voice of God Action -->
      <div class="d-flex align-items-center justify-content-center mb-6">
        <button
          class="
            vog-action-tab
            d-flex
            flex-column
            justify-center
            align-items-center
            admin-shadow-sm admin-rounded
          "
          @click="currentAction = 'talk'"
          :class="currentAction === 'talk' ? 'vog-action-tab-active' : ''"
          :disabled="loading || sending"
        >
          <IconTalk class="mb-2" />
          {{ $t("admin.voiceOfGodPage.sectionTalk.title") }}
        </button>
        <button
          class="
            vog-action-tab
            d-flex
            flex-column
            justify-center
            align-items-center
            admin-shadow-sm admin-rounded
          "
          @click="currentAction = 'live'"
          :class="currentAction === 'live' ? 'vog-action-tab-active' : ''"
          :disabled="loading || sending"
        >
          <IconLive class="mb-2" />
          {{ $t("admin.voiceOfGodPage.sectionLive.title") }}
        </button>
        <button
          class="
            vog-action-tab
            d-flex
            flex-column
            justify-center
            align-items-center
            admin-shadow-sm admin-rounded
          "
          @click="currentAction = 'write'"
          :class="currentAction === 'write' ? 'vog-action-tab-active' : ''"
          :disabled="loading || sending"
        >
          <IconWrite class="mb-2" />
          {{ $t("admin.voiceOfGodPage.sectionWrite.title") }}
        </button>
      </div>
      <div v-if="!sending">
        <div v-if="currentAction === 'talk'">
          <div class="vog-tab-content-talk admin-shadow-sm pa-6 admin-rounded">
            <div class="text-center">
              <p>{{ $t("admin.voiceOfGodPage.sectionTalk.time") }}</p>
              <span class="font-size-20">{{ minute }} : {{ seconds }}</span>
              <div>
                <img
                  v-if="talkAction.mode === 'recording'"
                  class="audio-animation-icon mt-6"
                  :src="audioAnimation"
                  alt="audio animation"
                />
                <audio
                  v-if="talkAction.mode === 'recordingCompleted'"
                  class="mt-6 mx-auto final-audio"
                  width="400"
                  :src="recodingData.recordingUrl"
                  controls
                />
              </div>
              <div class="vog-tab-action-buttons mt-4">
                <v-btn
                  elevation="0"
                  class="bg-fb-navy ma-2"
                  v-if="talkAction.mode === 'initial'"
                  @click="startRecording('audio')"
                >
                  <span>{{ $t("admin.voiceOfGodPage.startRecording") }}</span>
                </v-btn>

                <v-btn
                  elevation="0"
                  class="bg-fb-navy ma-2"
                  v-if="talkAction.mode === 'recording'"
                  @click="finishRecording"
                >
                  {{ $t("admin.voiceOfGodPage.finishRecording") }}
                </v-btn>
                <div
                  class="my-6 mx-auto notification-title"
                  v-if="talkAction.mode === 'recordingCompleted'"
                >
                  <TextField
                    type="text"
                    :disabled="$store.state.ui.busy"
                    :placeholder="$t('admin.voiceOfGodPage.notificationTitle')"
                    v-model="recodingData.notificationTitle"
                  />
                </div>
                <div
                  class="d-flex justify-center align-items-center mb-2"
                  v-if="talkAction.mode === 'recordingCompleted'"
                >
                  <Checkbox class="mr-2" v-model="recodingData.immidiate" />
                  {{ $t("admin.voiceOfGodPage.immidiatly") }}
                </div>
                <v-btn
                  elevation="0"
                  class="bg-fb-navy ma-2"
                  :disabled="selectedUserLength === 0 || selectedUserLength === 'undefined'"
                  v-if="talkAction.mode === 'recordingCompleted'"
                  @click="sendNotification"
                >
                  {{ $t("admin.voiceOfGodPage.sendNotification") }}
                </v-btn>
                <div class="text-admin-danger font-size-14" v-if="talkAction.mode === 'recordingCompleted' && selectedUserLength === 0 || selectedUserLength === 'undefined'">
                  {{ $t("admin.voiceOfGodPage.noUserSelectedWarning") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentAction === 'live'">
          <div class="vog-tab-content-talk admin-shadow-sm pa-6 admin-rounded">
            <div class="text-center">
              <p>{{ $t("admin.voiceOfGodPage.sectionTalk.time") }}</p>
              <span class="font-size-20">{{ minute }} : {{ seconds }}</span>
              <div>
                <video
                  v-if="videoAction.mode === 'recording'"
                  class="preview-video mt-6"
                  :srcObject.prop="videoAction.preview"
                  width="400"
                  height="300"
                  autoplay
                  muted
                ></video>
                <div
                  class="final-video mt-6 mx-auto position-relative"
                  v-if="videoAction.mode === 'recordingCompleted'"
                >
                  <video
                    id="final-video"
                    :src="recodingData.recordingUrl"
                    width="400"
                    height="300"
                  ></video>
                  <v-btn
                    color="primary"
                    icon="mdi-play"
                    v-if="!videoAction.previewVideoIsPlaying"
                    class="final-video-play-button"
                    @click="previewVideoPlayToggle"
                  />
                  <v-btn
                    color="primary"
                    icon="mdi-pause"
                    v-if="videoAction.previewVideoIsPlaying"
                    class="final-video-play-button"
                    @click="previewVideoPlayToggle"
                  />
                </div>
              </div>
              <div class="vog-tab-action-buttons mt-4">
                <v-btn
                  elevation="0"
                  class="bg-fb-navy ma-2"
                  v-if="videoAction.mode === 'initial'"
                  @click="startRecording('video')"
                >
                  <span>{{ $t("admin.voiceOfGodPage.startRecording") }}</span>
                </v-btn>

                <v-btn
                  elevation="0"
                  class="bg-fb-navy ma-2"
                  v-if="videoAction.mode === 'recording'"
                  @click="finishRecording('video')"
                >
                  {{ $t("admin.voiceOfGodPage.finishRecording") }}
                </v-btn>
                <div
                  class="my-6 mx-auto notification-title"
                  v-if="videoAction.mode === 'recordingCompleted'"
                >
                  <TextField
                    type="text"
                    :disabled="$store.state.ui.busy"
                    :placeholder="$t('admin.voiceOfGodPage.notificationTitle')"
                    v-model="recodingData.notificationTitle"
                  />
                </div>
                <div
                  class="d-flex justify-center align-items-center mb-2"
                  v-if="videoAction.mode === 'recordingCompleted'"
                >
                  <Checkbox class="mr-2" v-model="recodingData.immidiate" />
                  {{ $t("admin.voiceOfGodPage.immidiatly") }}
                </div>
                <v-btn
                  elevation="0"
                  class="bg-fb-navy ma-2"
                  v-if="videoAction.mode === 'recordingCompleted'"
                  :disabled="selectedUserLength === 0 || selectedUserLength === 'undefined'"
                  @click="sendNotification('video')"
                >
                  {{ $t("admin.voiceOfGodPage.sendNotification") }}
                </v-btn>
                <div class="text-admin-danger font-size-14" v-if="videoAction.mode === 'recordingCompleted' && selectedUserLength === 0 || selectedUserLength === 'undefined'">
                  {{ $t("admin.voiceOfGodPage.noUserSelectedWarning") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentAction === 'write'">
          <div class="vog-tab-content-talk admin-shadow-sm pa-6 admin-rounded">
            <CKEditorField v-model="contentAction.content" />
            <!--
            <TextField
              type="text"
              :disabled="$store.state.ui.busy"
              :placeholder="$t('admin.voiceOfGodPage.notificationUrl')"
              v-model="recodingData.notificationUrl"
              class="pb-4"
            />
            -->
          </div>
          <div class="text-center ma-4">
            <v-btn
              class="bg-fb-navy"
              @click="
                $emit('sendTextNotification', this.contentAction);
                contentAction.content = '';
              "
              >{{ $t("buttons.send") }}
            </v-btn>
          </div>
        </div>
      </div>
      <!-- sending -->
      <div v-else class="ma-10 text-center">
        <v-icon size="x-large" class="spinner">mdi-loading</v-icon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vog-content-container {
  width: 100%;
  padding: 20px;
}
.vog-action-tab {
  margin: 10px;
  width: 120px;
  height: 120px;
  padding: 20px;
  text-align: center;
  color: rgb(var(--v-theme-fb-navy-2));
  svg {
    height: 40px;
  }
  &.vog-action-tab-active {
    color: rgb(var(--v-theme-white));
    background-color: rgb(var(--v-theme-fb-navy-2));
  }
  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
  }
}
.audio-animation-icon {
  width: 100px;
}
.preview-video,
.final-video video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.vog-tab-action-buttons {
  button {
    text-transform: capitalize;
    border-radius: 6px;
    &:focus .v-btn__overlay {
      opacity: 0;
    }
  }
}
.notification-title,
.final-audio,
.final-video {
  width: 400px;
}
.final-video-play-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
