<script>
/** TextField component requires because Vuetify 3 still doesn't has it */
// import DropdownIcon from '@/assets/imgs/icons/dropdown-arror.svg'
import waitFor from '@/utils/waitFor'
import Checkbox from '@/components/fields/Checkbox'

export default {
  name: 'MultiselectField',
  components: {
    Checkbox
  },
  props: {
    modelValue: {
      // type: Array,
      default: () => ([]),
      required: true
    },
    maxHeight: {
      type: Number,
      default: 200
    },
    /** options set key => label */
    options: {
      type: Object,
      default: () => ({})
    },
    /** options icons set key => icon */
    icons: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
    },
    customTag: {
      type: Boolean,
      default: false
    },
    customTagPlaceholder: {
      type: String,
    },
  },
  emits: [
    'update:modelValue'
  ],
  data() {
    return {
      val: Array.isArray(this.modelValue) ? this.modelValue : [],
      map: {},
      open: false,
      customTagValue: ""
    }
  },
  watch: {
    modelValue(next) {
      this.val = next
    },
    options() {
      this.rebuildMap()
    },
    map: {
      deep: true,
      async handler() {
        this.val = Object.keys(this.map).filter(key => !!this.map[key])
        await waitFor(0)
        this.$emit('update:modelValue', this.val)
      }
    }
  },
  computed: {
    optionsList() {
      return Object.keys(this.options).map(key => ({
        value: key,
        label: this.options[key]
      }))
    },
    hasIcons() {
      return !!Object.keys(this.icons || {}).length
    }
  },
  methods: {
    toggleDropdown() {
      if (this.disabled)
        return
      this.open = !this.open
    },
    rebuildMap() {
      this.map = Object.keys(this.options).reduce((acc,key) => {
        acc[key] = this.val.indexOf(key) !== -1
        return acc
      },{})
    },
    toggleOption(val) {
      if (this.val.indexOf(val) === -1)
        this.val.push(val)
      else
        this.val = this.val.filter(x => x !== val)
    },
    addTag(tag){
      this.val.push(tag);
      this.customTagValue = "";
    },
    clickOutside() {
      this.open = false
    }
  },
  async created() {
    await waitFor(0)
    if (!this.modelValue && this.val)
      this.$emit('update:modelValue', this.val)
    this.rebuildMap()
  }
}
</script>

<template>
<div class="multiselect-filed position-relative" v-click-outside="clickOutside">
  <div class="selected-tags" @click="toggleDropdown">
      <div v-if="val.length">
        <span class="tag" v-for="item in val" :key="item">{{ options[item] }}</span>
      </div>
      <span class="placeholder" v-else>{{ placeholder }}</span>
  </div>
  <div v-if="open" class="multiselect-dropdown bg-white field-wrapper rounded elevation-2">
    <div :style="{'max-height': maxHeight + 'px'}"  class="pa-2 overflow-y-auto custom-scrollbar" :class="customTag === true ? 'mb-n2' : ''">
      <div class="dropdown-item" v-for="opt, i of optionsList" :key="i">
        <div class="dropdown-button pa-2">
          <Checkbox v-model="map[opt.value]" @click.stop class="mr-2"/>
          <component class="item-icon" v-if="hasIcons" :is="icons[opt.value]"/>
          {{opt.label}}
        </div>
      </div>
    </div>
    <div v-if="customTag" class="px-4 py-4">
      <input type="text" v-model="customTagValue" class="custom-tag-input" v-on:keyup.enter="addTag($event.target.value)" :placeholder="customTagPlaceholder" />
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">

.field-wrapper {
  position: relative;
  max-height: 350px;
}
.multiselect-filed{
  box-shadow: #b1b1b158 0px 6px 20px;
}
.placeholder{
  padding: 2px 10px;
  color: rgb(var(--v-theme-grey-2d));
  display: block;
}
.selected-tags{
  min-height: 50px;
  padding: 10px;
  cursor: pointer;
}
.multiselect-dropdown{
  position: absolute;
  width: 100%;
  top: calc(100% + 2px);
  z-index: 10;
}
.tag{
  display: inline-block;
  font-size:12px;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: rgb(var(--v-theme-fb-navy));
  color: rgb(var(--v-theme-white));
  margin-top:2px;
  margin-bottom:2px;
  &:not(:last-child){
    margin-right: 4px;
  }
}
.custom-tag-input{
  box-shadow: #b1b1b158 0px 6px 20px;
  padding: 10px;
  width:100%;
}
</style>
