<script>
import fileUpload from '@/utils/fileUpload'

export default {
  name: 'ScormManager',
  computed: {
    list() {
      const list = this.$store.getters['admin/scorm/list']
      return list
        .sort((a,b) => {
          return a.details.filename === b.details.filename
            ? 0
            : a.details.filename > b.details.filename
              ? 1
              : -1
        })
    }
  },
  methods:{
    async uploadFile(f){
      const file = f.target.files[0]
      if (!file) return
      console.log('upload file', file)
      const collection = await this.$store.getters['admin/scorm/collection']()
      this.$store.commit('ui/setBusy', true)
      await fileUpload({
        file,
        collection,
        storage: process.env.VUE_APP_FIREBASE_STORAGE_SCORM_UPLOAD_BUCKET,
        refPrefix: '/',
        locale: this.$store.state.user.data.locale,
        createdBy: this.$store.state.user.data.uid
      })
      this.$store.commit('ui/setBusy', false)
    },
    async deleteScorm(file) {
      const ok = await this.$store.dispatch('admin/confirm',{
        title: this.$t('admin.scormManager.dialog.deleteTitle'),
        message: this.$t('admin.scormManager.dialog.deleteMessage', {name: file.details.filename})
      })
      if (!ok)
        return
      await this.$store.dispatch('admin/scorm/delete',file.id)
    }
  },
}
</script>

<template>
  <div class="h-full bg-white pa-6 admin-rounded d-flex flex-column">
    <div class="flex-grow-1 h-full custom-scrollbar overflow-y-auto">
      <div class="mb-4 pa-2">
        <label
          class="v-btn v-btn--elevated v-btn--icon v-theme--light v-btn--density-default v-btn--size-default v-btn--variant-contained"
          for="scorm-file-uploader"
        >
          <v-icon>mdi-plus</v-icon>
        </label>
        <input type="file" id="scorm-file-uploader" @change="uploadFile" class="d-none"/>
      </div>
      <div class="pa-2 d-flex align-items-center justify-space-between scorm-list-item rounded" v-for="row of list" :key="row.id">
        <div :data-fid="row.id">{{row.details.filename}}</div>
        <div>
          <v-btn
            size="small"
            icon="mdi-minus"
            @click="deleteScorm(row)"
          >
            {{$t('buttons.delete')}}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mode-button {
  svg.icon {
    position: relative;
    top: -1px;
  }
  &.text-white {
    svg, path {
      fill: #fff;
    }
  }
  &.text-admin-text {
    svg, path {
      fill: rgb(var(--v-theme-admin-text));
    }
  }
  &.v-btn:focus .v-btn__overlay{
    opacity: 0!important;
  }
}
[data-v-2281c310] .v-btn__overlay{
  display: none!important;
}
svg.grid-icon {
  position: relative;
  top: 7px;

  &.flag-icon {
    border-radius: 3px;
  }
}
.touchpoint-icon-wrapper{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.touchpoints-view-wrapper{
  a{
    color: rgb(var(--v-theme-admin-text));
    &:hover{
      background-color: rgb(var(--v-theme-admin-grey));
    }
  }
  &.grid-view{
    a{
      color: rgb(var(--v-theme-fb-navy-2));
    }
    .touchpoint-icon-wrapper{
      width:80px;
      height:80px;
    }
  }
}
.scorm-list-item{
  &:hover{
    background-color: rgb(var(--v-theme-admin-grey-3));
  }
}
</style>
