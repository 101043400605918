<script>

import { useI18n } from 'vue-i18n'
import AdminEmailSidebar from '@/components/admin/email/AdminEmailSidebar'

export default {
  name: 'Email',
  components:{
    AdminEmailSidebar
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<template>
  <div class="email-wrapper h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <AdminEmailSidebar />
    <router-view name="emailUI"/>
  </div>
</template>

<style lang="scss" scoped>
.email-wrapper{
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-columns: 280px 1fr;
}
</style>