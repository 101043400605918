<script>
import IconModeList from '@/assets/imgs/icons/admin/viewMode-list.svg'
import IconModeGrid from '@/assets/imgs/icons/admin/viewMode-grid.svg'

import IconCourtyard from '@/assets/imgs/icons/navigation/courtyard.svg'
import IconOffice from '@/assets/imgs/icons/navigation/office.svg'
import IconRooftop from '@/assets/imgs/icons/navigation/rooftop.svg'
import IconStore from '@/assets/imgs/icons/navigation/store.svg'
import IconLoft from '@/assets/imgs/icons/navigation/loft.svg'
import IconApartment from '@/assets/imgs/icons/navigation/apartment.svg'
import IconWarehouse from '@/assets/imgs/icons/navigation/warehouse.svg'

import { Scene } from '@/lib/enums'

export default {
  name: 'TouchPoints',
  components:{
    IconModeList,
    IconModeGrid,
    IconCourtyard,
    IconOffice,
    IconRooftop,
    IconStore,
    IconLoft,
    IconApartment,
    IconWarehouse
  },
  data(){
    return{
      viewMode : localStorage.getItem('admin/touchPoint/viewMode') || "list"
    }
  },
  created(){
    this.changeViewMode();
  },
  methods:{
    changeViewMode(){
      localStorage.setItem('admin/touchPoint/viewMode', this.viewMode)
    }
  },
  setup: () => ({ Scene })
}
</script>

<template>
  <div class="h-full bg-white pa-6 admin-rounded d-flex flex-column">
    <div class="text-right mb-4">
      <v-btn
        :disabled="$store.state.ui.busy"
        @click="viewMode = 'list'; changeViewMode();"
        flat
        elevation="0"
        class="mode-button ma-2"
        :class="{
          'bg-fb-navy text-white': viewMode === 'list',
          'bg-admin-grey-2': viewMode !== 'list',
        }"
      >
        <IconModeList class="icon mr-1"/>
        {{$t('admin.usersPage.viewMode.list')}}
      </v-btn>
      <v-btn
        :disabled="$store.state.ui.busy"
        @click="viewMode = 'grid'; changeViewMode();" 
        flat
        elevation="0"
        class="mode-button ma-2"
        prepend-icon
        :class="{
          'bg-fb-navy text-white': viewMode === 'grid',
          'bg-admin-grey-2': viewMode !== 'grid'
        }"
      >
        <IconModeGrid class="icon mr-1"/>
        {{$t('admin.usersPage.viewMode.grid')}}
      </v-btn>
    </div>
    <div class="flex-grow-1 h-full custom-scrollbar overflow-y-auto">
      <!-- view mode === list -->
      <div v-if="viewMode === 'list'">
        <v-container
          fluid
          class="touchpoints-view-wrapper touchpoints-view-list"
        >
          <v-row>
            <v-col cols="12">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'apartment'}}" class="d-flex align-items-center rounded-lg admin-shadow-lg mb-3 py-4 px-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mr-3">
                  <IconApartment/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Apartment}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="12">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'campus'}}" class="d-flex align-items-center rounded-lg admin-shadow-lg mb-3 py-4 px-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mr-3">
                  <IconCourtyard/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Campus}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="12">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'loft'}}" class="d-flex align-items-center rounded-lg admin-shadow-lg mb-3 py-4 px-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mr-3">
                  <IconLoft/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Loft}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="12">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'office'}}" class="d-flex align-items-center rounded-lg admin-shadow-lg mb-3 py-4 px-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mr-3">
                  <IconOffice/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Office}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="12">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'pool'}}" class="d-flex align-items-center rounded-lg admin-shadow-lg mb-3 py-4 px-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mr-3">
                  <IconRooftop/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Pool}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="12">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'store'}}" class="d-flex align-items-center rounded-lg admin-shadow-lg mb-3 py-4 px-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mr-3">
                  <IconStore/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Store}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="12">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'warehouse'}}" class="d-flex align-items-center rounded-lg admin-shadow-lg mb-3 py-4 px-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mr-3">
                  <IconWarehouse/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Warehouse}`)}}
                </div>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- view mode === list -->
      <div v-if="viewMode === 'grid'">
        <v-container
          fluid
          class="touchpoints-view-wrapper grid-view text-center"
        >
          <v-row>
            <v-col cols="3">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'apartment'}}" class="d-block admin-shadow-lg admin-rounded mb-3 pa-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mx-auto mb-4">
                  <IconApartment/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Apartment}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="3">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'campus'}}" class="d-block admin-shadow-lg admin-rounded mb-3 pa-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mx-auto mb-4">
                  <IconCourtyard/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Campus}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="3">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'loft'}}" class="d-block admin-shadow-lg admin-rounded mb-3 pa-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mx-auto mb-4">
                  <IconLoft/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Loft}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="3">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'office'}}" class="d-block admin-shadow-lg admin-rounded mb-3 pa-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mx-auto mb-4">
                  <IconOffice/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Office}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="3">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'pool'}}" class="d-block admin-shadow-lg admin-rounded mb-3 pa-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mx-auto mb-4">
                  <IconRooftop/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Pool}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="3">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'store'}}" class="d-block admin-shadow-lg admin-rounded mb-3 pa-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mx-auto mb-4">
                  <IconStore/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Store}`)}}
                </div>
              </router-link>
            </v-col>
            <v-col cols="3">
              <router-link :to="{name: 'SceneTouchPoints', params:{scene : 'warehouse'}}" class="d-block admin-shadow-lg admin-rounded mb-3 pa-8 admin-rounded">
                <div class="touchpoint-icon-wrapper bg-fb-navy d-flex justify-content-center align-items-center mx-auto mb-4">
                  <IconWarehouse/>
                </div>
                <div>
                  {{$t(`scenes.${Scene.Warehouse}`)}}
                </div>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
.mode-button {
  svg.icon {
    position: relative;
    top: -1px;
  }
  &.text-white {
    svg, path {
      fill: #fff;
    }
  }
  &.text-admin-text {
    svg, path {
      fill: rgb(var(--v-theme-admin-text));
    }
  }
  &.v-btn:focus .v-btn__overlay{
    opacity: 0!important;
  }
}
[data-v-2281c310] .v-btn__overlay{
  display: none!important;
}
svg.grid-icon {
  position: relative;
  top: 7px;

  &.flag-icon {
    border-radius: 3px;
  }
}
.touchpoint-icon-wrapper{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.touchpoints-view-wrapper{
  a{
    color: rgb(var(--v-theme-admin-text));
    &:hover{
      background-color: rgb(var(--v-theme-admin-grey));
    }
  }
  &.touchpoints-view-list{
    .touchpoint-icon-wrapper{
      svg{
        width:30px;
        height:30px;
      }
    }
  }
  &.grid-view{
    a{
      color: rgb(var(--v-theme-fb-navy-2));
    }
    .touchpoint-icon-wrapper{
      width:80px;
      height:80px;
    }
  }
}
</style>
