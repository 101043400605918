<script>
import * as Sentry from "@sentry/vue";
import QuizInfoEditor from '@/components/admin/quizes/QuizInfoEditor'

export default {
  name: 'QuizCreate',
  components:{
    QuizInfoEditor
  },
  data: () => ({
    loading: false,
    quiz: undefined
  }),
  methods: {
    async createQuiz() {
      this.loading = true
      this.$store.commit('ui/setBusy', true)
      let qdoc = null
      let qerr = null
      try {
        qdoc = await this.$store.dispatch('admin/activities/save', {
          ...this.quiz
        })
      } catch (err) {
        Sentry.captureException(err)
        qerr = err
        // console.error('save error', err)
      }
      this.loading = false
      this.$store.commit('ui/setBusy', false)
      if (qdoc?.id) {
        this.$router.push({ name: 'QuizEditor', params: {id: qdoc.id}})
      } else {
        this.$store.dispatch('admin/alert',{
          title: this.$t('admin.error.error'),
          message: `${this.$t('admin.error.saveFailed')}: ${qerr.toString()}`
        })
      }
    }
  },
  watch: {
    quiz: {
      deep: true,
      async handler() {
        this.createQuiz()
      }
    }
  }
}
</script>

<template>
  <div class="h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <div class="create-quiz-wrapper h-full overflow-y-auto custom-scrollbar">
      <QuizInfoEditor v-model="quiz"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-quiz-wrapper{
  padding: 20px;
}
</style>
