<script>
/** TextField component requires because Vuetify 3 still doesn't has it */
import DropdownIcon from '@/assets/imgs/icons/dropdown-arror.svg'
import waitFor from '@/utils/waitFor'
import { Locale } from '@/lib/enums.js'

import FlagUS from '@/assets/imgs/icons/flags/usa.svg'
import FlagFR from '@/assets/imgs/icons/flags/france.svg'
import FlagES from '@/assets/imgs/icons/flags/spain.svg'

export default {
  name: 'DropdownField',
  components: {
    FlagUS,
    FlagFR,
    FlagES,
    DropdownIcon
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        [Locale.en]: '',
        [Locale.es]: '',
        [Locale.fr]: '',
      }),
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    /** options set key => label */
    options: {
      type: Object,
      default: () => ({})
    },
    /** options icons set key => icon */
    icons: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:modelValue'
  ],
  data() {
    return {
      val: this.modelValue || {
        [Locale.en]: '',
        [Locale.es]: '',
        [Locale.fr]: '',
      },
      open: false,
      openLocale: false,
      focusable: true,
      locale: Locale.en
    }
  },
  watch: {
    modelValue(next) {
      this.val = next
    },
    val(next) {
      this.$emit('update:modelValue', next)
    }
  },
  computed: {
    slocale() {
      return Locale[this.locale]
    },
    optionsList() {
      return Object.keys(this.options).map(key => ({
        value: key,
        label: this.options[key]
      }))
    },
    hasIcons() {
      return !!Object.keys(this.icons || {}).length
    },
    inputText() {
      if (this.val[this.locale] === '') return ''
      return this.options[this.val[this.locale]]
    },
    inputIsDisabled() {
      return this.disabled || this.open || !this.focusable
    }
  },
  setup: () => ({ Locale }),
  methods: {
    clickOutside() {
      this.open = false
      this.focusable = true
    },
    selectOption(val) {
      this.val[this.locale] = val
    },
    async toggleDropdown() {
      // await waitFor(0)
      if (this.disabled)
        return
      this.open = !this.open
      this.focusable = !this.open
      this.openLocale = false
    },
    async toggleLocalesSelector() {
      await waitFor(10)
      this.openLocale = !this.openLocale
      this.open = false
    },
  }
}
</script>

<template>
<div class="locale-wrapper d-flex">
  <div class="field-wrapper flex-grow-1">
    <input
      :disabled="inputIsDisabled"
      :placeholder="placeholder"
      :class="{
        'error': error,
        'with-icon': hasIcons && val !== ''
      }"
      :value="inputText"
      @focus="toggleDropdown"
    />
    <DropdownIcon :class="{open: open}" class="toggle-button"/>
    <component class="selected-icon" v-if="hasIcons && val !== ''" :is="icons[val]"/>
    <div @click="toggleDropdown" v-click-outside="clickOutside" class="click-holder"/>
    <div v-if="open" class="dropdown-wrapper">
      <div class="dropdown-item" v-for="opt, i of optionsList" :key="i">
        <button @click="selectOption(opt.value)" class="dropdown-button pa-2">
          <component v-if="hasIcons" :is="icons[opt.value]"/>
          {{opt.label}}
        </button>
      </div>
    </div>

  </div>
  <div class="locale-selector">
    <v-btn :disabled="$store.state.ui.busy" icon flat elevation="0" @click="toggleLocalesSelector">
      <FlagUS v-if="locale === Locale.en"/>
      <FlagFR v-if="locale === Locale.fr"/>
      <FlagES v-if="locale === Locale.es"/>
    </v-btn>
    <div v-if="openLocale" class="d-flex flex-column elevation-2 bg-white rounded-lg">
      <v-btn v-if="locale !== Locale.en" icon flat elevation="0" @click="locale = Locale.en; openLocale = false">
        <FlagUS/>
      </v-btn>
      <v-btn v-if="locale !== Locale.fr" icon flat elevation="0" @click="locale = Locale.fr; openLocale = false">
        <FlagFR/>
      </v-btn>
      <v-btn v-if="locale !== Locale.es" icon flat elevation="0" @click="locale = Locale.es; openLocale = false">
        <FlagES/>
      </v-btn>
    </div>
  </div>


</div>
</template>

<style scoped lang="scss">
input {
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  padding: 14px;
  background: #fff;
  box-shadow: #B1B1B158 0px 6px 20px;
  cursor: pointer;

  &.with-icon {
    padding-left: 60px;
  }

  &.error {
    color: rgb(var(--v-theme-error)) !important;
    border: 1px solid rgb(var(--v-theme-error)) !important;
  }
}

.dropdown-button:hover{
  color: rgb(var(--v-theme-fb-navy));
  background-color: rgb(var(--v-theme-admin-grey-2));
}

input:focus {
  border: 0 !important;
}

.locale-wrapper {
  position: relative;

  .locale-selector {
    position: absolute;
    top: 3px;
    right: 0px;
    cursor: pointer;
    fill: #ccc;
    z-index: 2;

    // &.open {
      // fill: var(--v-theme-grey-2d);
    // }
  }

}

.field-wrapper {
  position: relative;
  margin-right: 57px;

  .click-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }


  svg.toggle-button {
    position: absolute;
    top: 18px;
    //transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
    fill: #ccc;

    &.open {
      fill: var(--v-theme-grey-2d);
    }
  }

  svg.selected-icon {
    position: absolute;
    top: 11px;
    left: 20px;
  }

  .dropdown-wrapper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: translate(0, calc(100% + 2px));
    width: 100%;
    padding: 10px;
    // padding-top: 0px;
    box-shadow: #B1B1B158 0px 6px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 10;
    background: #fff;

    .dropdown-item {
      padding: 2px;

      .dropdown-button {
        width: 100%;
        text-align: left;
        color: rgb(var(--v-theme-grey-2d));

        &:hover {
          color: #000;
        }

        svg {
          position: relative;
          top: 9px;
        }
      }
    }
  }


}
</style>
