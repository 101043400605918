<script>
import * as Sentry from "@sentry/vue";

import ActivityEditor from '@/components/admin/activity/ActivityEditor'

export default {
  name: 'ActivityCreate',
  components:{
    ActivityEditor
  },
  data() {
    return {
      activity: undefined
    }
  },
  methods: {
    async save() {
      this.loading = true
      this.$store.commit('ui/setBusy', true)
      let qdoc = null
      let qerr = null
      try {
        qdoc = await this.$store.dispatch('admin/activities/save', {
          ...this.activity
        })
      } catch (err) {
        Sentry.captureException(err)
        qerr = err
        // console.error('save error', err)
      }
      this.loading = false
      this.$store.commit('ui/setBusy', false)
      if (qdoc?.id) {
        this.$router.push({ name: 'ActivityEditor', params: {id: qdoc.id}})
      } else {
        this.$store.dispatch('admin/alert',{
          title: this.$t('admin.error.error'),
          message: `${this.$t('admin.error.saveFailed')}: ${qerr.toString()}`
        })
      }
    }
  }
}
</script>

<template>
  <div class="h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <div class="create-activity-wrapper h-full overflow-y-auto custom-scrollbar">
      <ActivityEditor
        v-model="activity"
        @save="save"
      />
      <!--
      <div>{{activity}}</div>
      -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-activity-wrapper{
  padding: 20px;
}
</style>
