<script>
export default {
  name: "Analytics",
};
</script>

<template>
  <div
    class="flex-grow-1 h-full overflow-y-hidden"
  >
    <iframe
      width="100%"
      height="100%"
      src="https://datastudio.google.com/embed/reporting/a579e2d5-f7f2-4e05-b7b2-200f9e27c777/page/1M"
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<style lang="scss" scoped>
</style>
