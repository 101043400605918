<script>
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import IconModeList from '@/assets/imgs/icons/admin/viewMode-list.svg'
import IconModeGrid from '@/assets/imgs/icons/admin/viewMode-grid.svg'
import IconSortAZ from '@/assets/imgs/icons/admin/sort-az.svg'
import IconSortZA from '@/assets/imgs/icons/admin/sort-za.svg'
import IconEmail from '@/assets/imgs/icons/admin/user-email.svg'
// import IconPhone from '@/assets/imgs/icons/admin/user-phone.svg'
import { UserRole, Country } from '@/lib/enums'

import DropdownMenu from '@/components/DropdownMenu'

import FlagUs from '@/assets/imgs/icons/flags/usa.svg'
import FlagFr from '@/assets/imgs/icons/flags/france.svg'
import FlagEs from '@/assets/imgs/icons/flags/spain.svg'
import FlagGb from '@/assets/imgs/icons/flags/uk.svg'

export default {
  name: 'AdminUsers',
  components: {
    DropdownMenu,
    IconModeList,
    IconModeGrid,
    IconSortAZ,
    IconSortZA,
    // IconPhone,
    IconEmail,
    FlagUs,
    FlagEs,
    FlagFr,
    FlagGb
  },
  data(){ return {
    filterMode: ['registered', 'guests','rejected'].includes(this.$route.params.filter)
      ? this.$route.params.filter
      : 'registered',
    viewMode: localStorage.getItem('admin/user/viewMode') || 'list',
    sortBy: localStorage.getItem('admin/user/sortBy') || 'displayName',
    sortDirection: localStorage.getItem('admin/user/sortDirection') || 'ACS',
    items: parseInt(localStorage.getItem('admin/user/items')) || 10,
    page: parseInt(localStorage.getItem('admin/user/page')) || 0,
    loading: false
  }},
  methods: {
    writeUserView() {
      localStorage.setItem('admin/user/viewMode', this.viewMode)
      localStorage.setItem('admin/user/sortBy', this.sortBy)
      localStorage.setItem('admin/user/sortDirection', this.sortDirection)
      localStorage.setItem('admin/user/items', this.items)
      localStorage.setItem('admin/user/page', this.page)
    },
    clickSort(field) {
      if (!field.sort) return
      if (field.name !== this.sortBy) {
        this.sortBy = field.name
        this.sortDirection = 'asc'
      } else {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      }
      this.writeUserView();
      this.updateSub()
    },
    userDropdownMenu(user) {
      const ret = {
        [UserRole.Root]: this.t(`roles.${UserRole.Root}`),
        [UserRole.Trainer]: this.t(`roles.${UserRole.Trainer}`),
        [UserRole.CommunityManager]: this.t(`roles.${UserRole.CommunityManager}`),
        [UserRole.BrandInfluencer]: this.t(`roles.${UserRole.BrandInfluencer}`),
        [UserRole.RetailSalesAssociate]: this.t(`roles.${UserRole.RetailSalesAssociate}`),
        [UserRole.FacebookEmployee]: this.t(`roles.${UserRole.FacebookEmployee}`),
        [UserRole.CorporatePartner]: this.t(`roles.${UserRole.CorporatePartner}`),
        [-1]: this.t('admin.usersPage.actions.ban')
      }
      if (this.filterMode === 'registered') {
        delete ret[user.role]
      }
      if (this.filterMode === 'guests') {
        ret[-1] = this.t('admin.usersPage.actions.reject')
      }
      if (this.filterMode === 'rejected') {
        delete ret[-1]
      }
      return ret
    },
    userDropdownClasses() {
      return {
        [-1]: 'text-admin-danger'
      }
    },
    /**
      Action on user menu
      @param user - user object
      @param action - stringed number of new user role or -1 for ban/reject
    */
    async userDropdownAction(user,action) {
      const role = isNaN(parseInt(action)) ? -2 : parseInt(action)
      // console.log('user action', user, action, role)
      if (role === -2) return
      // console.log('user action', user, action)
      const dialog = role >= 0 ? {
        title: this.t('admin.usersPage.dialogs.confirmRoleTitle'),
        message: this.t('admin.usersPage.dialogs.confirmRoleMessage', {
          user: user.displayName,
          role: this.t(`roles.${role}`)
        })
      } : {
        title: this.t('admin.usersPage.dialogs.confirmBanTitle'),
        message: this.t('admin.usersPage.dialogs.confirmBanMessage', {
          user: user.displayName,
        })
      }
      const ok = await this.$store.dispatch('admin/confirm',dialog)
      if (!ok)
        return
      this.$store.commit('ui/setBusy', true)
      if (role >= 0)
        await this.$store.dispatch('admin/setUserRole',{ uid: user.uid, role})
      else
        await this.$store.dispatch('admin/banUser', user.uid )
      this.$store.commit('ui/setBusy', false)
    },
    userIsOnline(user) {
      if (!user.lastActivity?.seconds)
        return false
      const last = dayjs.unix(user.lastActivity.seconds)
      const now = dayjs()
      return now.diff(last,'s') < 60
    },
    updatePager() {
      this.writeUserView()
    },
    gotoPage(page) {
      this.page = page
      //if (page >= this.totalPages && page >= 0)
      //console.log('pager goto', page)
    },
    updateSub() {
      /*
      const options = {
        query: this.filterMode === 'registered'
          ? [['approved', '==', true]]
          : this.filterMode === 'guests'
           ? [['approved', '==', false], ['reviewDate', '==', null]]
           : [['approved', '==', false], ['reviewDate', '!=', null]],
        // orderBy: [this.sortBy, this.sortDirection],
        orderBy: this.filterMode !== 'rejected'
          ? [this.sortBy, this.sortDirection]
          //: [this.sortBy, this.sortDirection],
          : [['reviewDate'], [this.sortBy, this.sortDirection]],
        page: this.page,
        pageSize: this.items
      }
      console.log('update but not required', options)
      //this.$store.dispatch('admin/users/query',options)
      */
    },
    retailerName(rid) {
      const retailer = this.$store.getters['collections/retailers/byID'](rid)
      return retailer?.name || ''
    }
  },
  computed: {
    sortFields() { return [
      { name: 'avatar', cols: 1, sort: false },
      { name: 'displayName', cols: 3, sort: true },
      { name: 'email', cols: 3, sort: true },
      { name: 'retailerCountry', cols: 3, sort: true },
      { name: 'role', cols: 2, sort: this.filterMode === 'registered' },
    ]},
    usersSorted() {
      const list = this.filterMode === 'registered'
          ? this.$store.getters['admin/registered']
          : this.filterMode === 'guests'
            ? this.$store.getters['admin/guests']
            : this.$store.getters['admin/rejected']
      const sorted = list.sort((a,b) => {
        return this.sortDirection === 'asc'
          ? a[this.sortBy] === b[this.sortBy]
            ? 0
            : a[this.sortBy] > b[this.sortBy]
              ? 1
              : -1
          : a[this.sortBy] === b[this.sortBy]
            ? 0
            : a[this.sortBy] < b[this.sortBy]
              ? 1
              : -1
      })
      return sorted
    },
    usersList() {
      //const list = this.$store.getters['admin/users/list']
      return this.usersSorted.slice(this.page * this.items, (this.page+1) * this.items)
    },
    totalPages() {
      const total = this.usersSorted.length
      return Math.ceil(total/this.items)
    },
    hasNextPage() {
      return this.page < this.totalPages
    },
    hasPrevPage() {
      return this.page > 0
    },
    fastPages() {
      return [0]
    }
  },
  watch: {
    '$route.params.filter'(next) {
      this.filterMode = ['registered', 'guests','rejected'].includes(next)
        ? next
        : 'registered',
      this.sortBy = 'displayName'
      this.sortDirection = 'asc'
      this.page = 0
      this.writeUserView()
      this.updateSub()
    }
  },
  setup() {
    const { t } = useI18n()
    return { t, Country, UserRole }
  },
  created() {
    this.updateSub()
  }
}
</script>

<template>
  <div class="pa-10 admin-page-block bg-white admin-shadow admin-rounded shadow h-full d-flex flex-column">
    <div class="d-flex justify-content-between mb-4">
      <div>
        <router-link class="mr-4 mode-link" :to="{name: 'AdminUsers'}">
          {{t('admin.usersPage.modes.registered')}}
        </router-link>
        <router-link v-if="$can('users_management')" class="mr-4 mode-link" :to="{name: 'AdminUsers', params: { filter: 'guests' }}">
          {{t('admin.usersPage.modes.guests')}}
        </router-link>
        <router-link v-if="$can('users_management')" class="mr-4 mode-link" :to="{name: 'AdminUsers', params: { filter: 'rejected' }}">
          {{t('admin.usersPage.modes.rejected')}}
        </router-link>
        <router-link v-if="$can('users_management')" class="mr-4 mode-link" :to="{name: 'AdminUsersWhiteLists'}">
          {{t('admin.usersPage.modes.whitelists')}}
        </router-link>
      </div>
      <div>
        <v-btn
          :disabled="$store.state.ui.busy"
          @click="viewMode = 'list'; writeUserView();"
          flat
          elevation="0"
          class="mode-button ma-2"
          :class="{
            'bg-fb-navy text-white': viewMode === 'list',
            'bg-admin-grey-2': viewMode !== 'list',
          }"
        >
          <IconModeList class="icon mr-1"/>
          {{t('admin.usersPage.viewMode.list')}}
        </v-btn>
        <v-btn
          :disabled="$store.state.ui.busy"
          @click="viewMode = 'grid'; writeUserView();" 
          flat
          elevation="0"
          class="mode-button ma-2"
          prepend-icon
          :class="{
            'bg-fb-navy text-white': viewMode === 'grid',
            'bg-admin-grey-2': viewMode !== 'grid'
          }"
        >
          <IconModeGrid class="icon mr-1"/>
          {{t('admin.usersPage.viewMode.grid')}}
        </v-btn>
      </div>
    </div>
    <div class="flex-grow-1 h-full overflow-y-auto custom-scrollbar">
    <v-container fluid class="mb-5">
      <v-row class="bg-admin-grey-2 rounded">
        <v-col
          v-for="field, fi of sortFields"
          :key="fi"
          :cols="field.cols"
          class="py-3 col-header d-flex justify-content-start align-center"
          :class="{'is-active': field.name === sortBy}"
          @click="clickSort(field);"
        >
          <div>
            {{t(`admin.usersPage.sort.${field.name}`)}}
          </div>
          <div v-if="field.sort" class="sort-icon ml-3">
            <IconSortZA v-if="sortBy === field.name && sortDirection === 'desc'"/>
            <IconSortAZ v-else/>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- view mode === list -->
    <div v-if="viewMode === 'list'">
      <v-container
        fluid
        v-for="user of usersList"
        :key="user.uid"
        class="rounded-lg admin-shadow-lg mb-3"
      >
        <v-row>
          <v-col cols="1" class="d-flex justify-end flex-shrink-0">
            <div class="user-avatar flex-shrink-0" :style="{backgroundImage: `url(${user.avatar})`}">
              <div class="user-status" :class="{
                'user-status-offline': !userIsOnline(user),
                'user-status-online': userIsOnline(user)
              }"/>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-bold text-truncate">{{user.displayName}}</div>
            <div class="text-fb-grey text-truncate">{{t(`roles.${user.role}`)}}</div>
          </v-col>
          <v-col cols="3" class="d-flex flex-column justify-center">
            <div class="text-truncate" :title="user.email">
              {{user.email}}
            </div>
          </v-col>
          <!--
          <v-col cols="2" class="d-flex flex-column justify-center">
            {{user.phone || '-'}}
          </v-col>
          -->
          <v-col cols="3" class="d-flex flex-column justify-center">
            <div class="d-flex">
              <FlagUs v-if="user.retailerCountry === Country.US" class="mr-2 flag-icon"/>
              <FlagFr v-if="user.retailerCountry === Country.FR" class="mr-2 flag-icon"/>
              <FlagEs v-if="user.retailerCountry === Country.ES" class="mr-2 flag-icon"/>
              <FlagGb v-if="user.retailerCountry === Country.GB" class="mr-2 flag-icon"/>
              <div v-if="typeof user.jobPosition === 'number'" class="d-flex flex-column justify-center">
                {{$t('admin.usersPage.position',{
                  position: $t(`roles.${user.jobPosition}`),
                  company: user.company
                })}}
              </div>
            </div>
            <!--
            {{$store.getters['collections/retailers/byID'](user.retailer)}}
            {{t(`countries.${Country[user.retailerCountry]}`)}}
            -->
          </v-col>
          <v-col cols="2" class="d-flex">
            <div class="d-flex flex-column justify-center">
              {{t(`roles.${user.role}`)}}
            </div>
            <DropdownMenu
              v-if="$can('users_management')"
              flat
              icon
              class="elevation-0"
              :minWidth="250"
              :options="userDropdownMenu(user)"
              :classes="userDropdownClasses()"
              @select="userDropdownAction(user, $event)"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- view mode === grid -->
    <div v-if="viewMode === 'grid'">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="user of usersList"
            :key="user.uid"
            cols="4"
          >
            <div class="admin-grid-card rounded-lg admin-shadow-lg pa-3 d-flex h-full">
              <!-- 1st column -->
              <div class="admin-grid-card-image flex-shrink-0 text-center">
                <div class="user-avatar user-avatar-lg mx-auto" :style="{backgroundImage: `url(${user.avatar})`}">
                  <div class="user-status" :class="{
                    'user-status-offline': !userIsOnline(user),
                    'user-status-online': userIsOnline(user)
                  }"/>
                </div>
                <div class="text-bold font-size-14 mt-2">{{user.displayName}}</div>
                <div class="text-fb-grey font-size-12">{{t(`roles.${user.role}`)}}</div>
              </div>
              <!-- 2nd column -->
              <div class="admin-grid-card-content flex-grow-1 pl-4">
                <div class="d-flex text-truncate mb-2" :title="user.email"><IconEmail class="flex-shrink-0 grid-icon mr-2 align-items-start"/> {{user.email}}</div>
                <div class="d-flex text-truncate" v-if="typeof user.jobPosition === 'number'">
                  <!--
                <div><IconPhone class="grid-icon"/> {{user.phone || '-'}}</div>
                  <FlagUs class="grid-icon flag-icon"/> {{t('countries.US')}}
                  <FlagUs v-if="user.retailerCountry === Country.US" class="grid-icon flag-icon"/>
                  -->
                  <FlagUs v-if="user.retailerCountry === Country.US" class="grid-icon flag-icon flex-shrink-0 mr-2"/>
                  <FlagFr v-if="user.retailerCountry === Country.FR" class="grid-icon flag-icon flex-shrink-0 mr-2"/>
                  <FlagEs v-if="user.retailerCountry === Country.ES" class="grid-icon flag-icon flex-shrink-0 mr-2"/>
                  <FlagGb v-if="user.retailerCountry === Country.GB" class="grid-icon flag-icon flex-shrink-0 mr-2"/>
                  <span v-if="typeof user.jobPosition === 'number'">
                    {{$t('admin.usersPage.position',{
                      position: $t(`roles.${user.jobPosition}`),
                      company: user.company
                    })}}
                  </span>
                </div>
                <!--
                    {{t(`jobPositions.${user.jobPosition}`)}}
                  {{retailerName(user.retailer)}}
                  {{$store.getters['collections/retailers/byID'](user.retailer).name}}
                <div v-if="user.retailerCountry === Country.FR">
                  <FlagFr class="grid-icon flag-icon"/> {{t('countries.FR')}}
                </div>
                <div v-if="user.retailerCountry === Country.ES">
                  <FlagEs class="grid-icon flag-icon"/> {{t('countries.ES')}}
                </div>
                <div v-if="user.retailerCountry === Country.GB">
                  <FlagGb class="grid-icon flag-icon"/> {{t('countries.GB')}}
                </div>
                -->
                <div class="d-flex">
                  <div class="d-flex flex-column justify-center">
                    {{t(`roles.${user.role}`)}}
                  </div>
                  <DropdownMenu
                    v-if="$can('users_management')"
                    flat
                    icon
                    class="elevation-0"
                    :minWidth="250"
                    :options="userDropdownMenu(user)"
                    :classes="userDropdownClasses()"
                    @select="userDropdownAction(user, $event)"
                  />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- paginator -->
    <v-container fluid class="mt-5">
      <v-row>
        <v-col>
          <span class="bg-admin-grey-2 pa-3">
            {{t('admin.usersPage.paginator.itemspp')}}: {{items}}
            <DropdownMenu
              flat
              icon
              density="compact"
              class="elevation-0 bg-admin-grey-2"
              :options="{
                10: '10',
                25: '25',
                50: '50',
                100: '100'
              }"
              @select="items = $event; updatePager();"
            />
          </span>
        </v-col>
        <v-col align="right">
          <v-btn
            flat
            :disabled="$store.state.ui.busy || !hasPrevPage"
            class="mr-2"
            @click="gotoPage(page-1)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <!--
          <v-btn
            v-for="pg of fastPages"
            :key="pg"
            flat
            :disabled="$store.state.ui.busy || pg === page"
            class="mr-2"
            @click="gotoPage(pg)"
          >
            {{pg + 1}}
          </v-btn>
          -->
          <v-btn
            flat
            :disabled="$store.state.ui.busy || !hasNextPage"
            @click="gotoPage(page+1)"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mode-link {
  color: rgb(var(--v-theme-admin-text));
  text-decoration: none;

  &.router-link-exact-active {
    text-decoration: underline;
  }
}
.mode-button {
  svg.icon {
    position: relative;
    top: -1px;
  }
  &.text-white {
    svg, path {
      fill: #fff;
    }
  }
  &.text-admin-text {
    svg, path {
      fill: rgb(var(--v-theme-admin-text));
    }
  }
}

.col-header {
  color: rgb(var(--v-theme-fb-grey));
  font-weight: normal;
  font-size: 14px;

  .sort-icon {
    position: relative;
    top: 2px;
    svg {
      fill: #64747F;
    }
  }

  &.is-active {
    color: rgb(var(--v-theme-fb-navy));

    svg {
      fill: rgb(var(--v-theme-fb-navy));
    }
  }

  &:hover {
    cursor: pointer;
    color: rgb(var(--v-theme-fb-navy-2));

    svg {
      fill: rgb(var(--v-theme-fb-navy-2));
    }
  }

}
.admin-grid-card{
  &-image{
    width:130px;
  }
  &-content{
    width:calc(100% - 130px);
  }
}

.user-avatar {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: rgb(var(--v-theme-fb-navy-2));
  background-size: cover;
  background-position: center;

  .user-status {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    bottom: 0px;
    right: 0px;
    background-color: rgb(var(--v-theme-admin-grey));
    border: 2px solid #fff;

    &-online {
      background-color: rgb(var(--v-theme-admin-green-400));
    }

    &-offline {
      background-color: rgb(var(--v-theme-admin-danger));
    }
  }

  &-lg {
    width: 100px;
    height: 100px;
    border-radius: 50px;

    .user-status {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      bottom: 0px;
      right: 0px;
      border-width: 4px;
    }
  }
}

svg.grid-icon {
  position: relative;
  top: 1px;

  &.flag-icon {
    width:22px;
    height:auto;
    border-radius: 3px;
    left:1px;
  }
}
</style>
