<script>

import { useI18n } from 'vue-i18n'
import AdminLogo from "@/assets/imgs/admin-logo.svg"
import IconUsers from "@/assets/imgs/icons/admin/admin-users.svg"
import IconScheduling from "@/assets/imgs/icons/admin/admin-scheduling.svg"
import IconEmail from "@/assets/imgs/icons/admin/admin-email.svg"
import IconVoiceOfGod from "@/assets/imgs/icons/admin/admin-voice-of-god.svg"
import IconAnalytics from "@/assets/imgs/icons/admin/admin-analytics.svg"
import IconTouchPoints from "@/assets/imgs/icons/admin/admin-touch-points.svg"
import IconQuizes from "@/assets/imgs/icons/admin/admin-quize.svg"
//import IconSettings from "@/assets/imgs/icons/admin/admin-setting.svg"
import IconLogout from "@/assets/imgs/icons/admin/admin-logout.svg"

export default {
  name: 'AdminSidebar',
  components:{
    AdminLogo,
    IconUsers,
    IconScheduling,
    IconEmail,
    IconVoiceOfGod,
    IconAnalytics,
    IconTouchPoints,
    IconQuizes,
    //IconSettings,
    IconLogout
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
}
</script>

<template>
  <div class="admin-sidebar-wrapper bg-white">
    <div class="px-4 py-6">
      <AdminLogo class="w-100 h-auto admin-sidebar-logo mx-auto"/>
    </div>
    <div class="admin-sidebar-links d-flex flex-column overflow-y-auto custom-scrollbar">
      <div>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'AdminUsers' }">
          <IconUsers/>
          {{t('admin.sidebarLinks.users')}}
        </router-link>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'Scheduling' }">
          <IconScheduling/>
          {{t('admin.sidebarLinks.scheduling')}}
        </router-link>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'EmailCreate' }">
          <IconEmail/>
          {{t('admin.sidebarLinks.email')}}
        </router-link>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'AdminVoiceOfGod' }">
          <IconVoiceOfGod/>
          {{t('admin.sidebarLinks.voiceOfGod')}}
        </router-link>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'Analytics' }">
          <IconAnalytics/>
          {{t('admin.sidebarLinks.analytics')}}
        </router-link>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'TouchPoints' }">
          <IconTouchPoints/>
          {{t('admin.sidebarLinks.touchPoints')}}
        </router-link>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'ScormManager' }">
          <IconQuizes/>
          {{t('admin.sidebarLinks.scormManager')}}
        </router-link>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'QuizesList' }">
          <IconQuizes/>
          {{t('admin.sidebarLinks.quizes')}}
        </router-link>
        <router-link class="text-link-2d font-size-15" :to="{ name: 'Activities' }">
          <IconQuizes/>
          {{t('admin.sidebarLinks.activityEditor')}}
        </router-link>
      </div>
      <div class="mt-auto">
        <!-- <router-link class="text-link-2d font-size-15" :to="{ name: 'AdminSettings' }">
          <IconSettings/>
          {{t('admin.sidebarLinks.settings')}}
        </router-link> -->
        <a class="text-link-2d font-size-15" href="#" @click.stop.prevent="$store.dispatch('user/logout')">
          <IconLogout/>
          {{t('admin.sidebarLinks.logout')}}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.admin-sidebar-wrapper{
 display: grid;
 grid-template-rows: auto 1fr;
 height: 100vh;
}
.admin-sidebar-logo{
  max-width: 150px;
  display: block;
}
.admin-sidebar-links{
  display: flex;
  flex-direction: column;
  a {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 4px;
    margin: 4px 8px;
    position: relative;
    svg{
      width: 24px;
      height: auto;
      display: block;
      margin-right: 16px;
      flex-shrink: 0;
    }
    &:hover{
      background-color: rgb(var(--v-theme-admin-grey));
    }
    &.router-link-active {
      background-color: rgb(var(--v-theme-admin-grey));
      &:before{
        content:"";
        width: 2px;
        height: 100%;
        position: absolute;
        right: -6px;
        top: 0;
        background-color: #000;
      }
    }
  }
} 
</style>
