<script>
import QuizInfoEditor from '@/components/admin/quizes/QuizInfoEditor'
import QuizQuestionsList from '@/components/admin/quizes/QuizQuestionsList'
import QuizQuestionEditor from '@/components/admin/quizes/QuizQuestionEditor'
import QuizScoreEditor from '@/components/admin/quizes/QuizScoreEditor'

import FileCollection from '@/components/admin/FileCollection'

export default {
  name: 'QuizEditor',
  components:{
    QuizInfoEditor,
    QuizScoreEditor,
    QuizQuestionsList,
    QuizQuestionEditor,

    FileCollection
  },
  data() {
    const quiz = this.$store.getters['admin/activities/byID'](this.$route.params.id) || undefined
    // console.log('data quiz', quiz)
    return {
      quiz,
      loading: false,
      current: -1,
      mediaCollection: null,
      files: {},
      sub: null,
      saved: true
    }
  },
  computed: {
    bucket: () => process.env.VUE_APP_FIREBASE_STORAGE_ACTIVITY_UPLOAD_BUCKET,
    bucketPrefix() {
      return this.quiz && this.quiz.id ? `/${this.quiz.id}/` : ''
    }
  },
  methods: {
    async saveQuiz() {
      //await waitFor(0)
      // console.log('SAVE', JSON.parse( JSON.stringify(this.quiz) ), this.quiz)
      this.$store.commit('ui/setBusy', true)
      await this.$store.dispatch('admin/activities/update', {
        id: this.quiz.id,
        doc: {
          type: this.quiz.type,
          content: { ...this.quiz.content }
        }
      })
      this.$store.commit('ui/setBusy', false)
    },
    async reloadQuiz() {
      const quiz = this.$store.getters['admin/activities/byID'](this.$route.params.id) || undefined
      const ref = await this.$store.getters['admin/activities/docRef'](this.$route.params.id)

      if (quiz && ref) {
        //quiz.id = this.$route.params.id
        this.mediaCollection = ref.collection('media')
        // console.log('quiz&ref&&col', quiz, ref, this.mediaCollection)
        if (this.sub)
          this.sub()
        this.sub = this.mediaCollection.onSnapshot(snap => {
          const docs = {}
          snap.forEach(rec => {
            const doc = rec.data()
            doc.id = rec.id
            docs[doc.id] = doc
          })
          this.files = docs
        })

      }
      // console.log(`[${reason}] reloaded quiz`, quiz)
      this.quiz = quiz
      this.saved = true
    },
    async deleteQuiz() {
      const ok = await this.$store.dispatch('admin/confirm', {
        title: this.$t('admin.quizesPage.question.dialog.deleteQuiz.title'),
        message: this.$t('admin.quizesPage.question.dialog.deleteQuiz.message'),
      })
      if (!ok)
        return
      // console.log('DEL Q?', this.quiz, this.quiz.id)
      this.$store.commit('ui/setBusy', true)
      await this.$store.dispatch('admin/activities/delete', this.quiz.id)
      this.$store.commit('ui/setBusy', false)
      this.$router.push({ name: 'QuizesList' })
    },
    addQuestion() {
      this.quiz.content.questions.push({
        answers: [],
        maxAnswers: 1,
        prompt: {},
        timer: 0
      })
      this.current = this.quiz.content.questions.length - 1
    },
    async deleteQuestion() {
      const ok = await this.$store.dispatch('admin/confirm', {
        title: this.$t('admin.quizesPage.question.dialog.deleteQuestion.title'),
        message: this.$t('admin.quizesPage.question.dialog.deleteQuestion.message', { num: this.current + 1 }),
      })
      if (!ok)
        return
      const pos = this.current
      this.current = -1
      this.quiz.content.questions = this.quiz.content.questions.filter((el, i) => pos !== i)
    }
  },
  created() {
    this.reloadQuiz()
  },
  watch: {
    '$route.params.id'() {
      this.reloadQuiz('route')
    },
    '$store.state.admin.activities.data'(next, prev) {
      //console.log('next store', next, prev)
      if (next && !Object.keys(prev || {}).length && this.saved)
        this.reloadQuiz('store')
    },
    quiz: {
      deep: true,
      handler() {
        this.saved = false
      }
    }
  },
  beforeUnmount() {
    if (this.sub)
      this.sub()
  }
}
</script>

<template>
  <div class="quiz-editor-wrapper h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <QuizQuestionsList
      :quiz="quiz"
      :loading="loading"
      :selected="current"
      @showInfo="current = -1"
      @showFiles="current = -2"
      @showScores="current = -3"
      @showQuestion="current = $event"
      @addQuestion="addQuestion"
    />
    <div class="overflow-y-auto custom-scrollbar pa-5 ">
  <!--
  <div class="ma-5 elevation-2 font-size-14 pa-5 text-fb-navy bg-white">{{quiz}}</div>
  -->
      <QuizInfoEditor
        v-if="current === -1"
        mode="edit"
        v-model="quiz"
        @update:modelValue="saveQuiz"
        @deleteQuiz="deleteQuiz"
      />
      <FileCollection
        v-if="current === -2 && mediaCollection"
        :label="$t('admin.activityPage.createActivityPage.labels.files')"
        :files="files"
        :collection="mediaCollection"
        :bucket="bucket"
        :refPrefix="bucketPrefix"
      />
      <QuizScoreEditor
        v-if="current === -3"
        v-model="quiz.content.scores"
        @saveQuiz="saveQuiz"
      />
      <QuizQuestionEditor
        v-if="current > -1 && quiz"
        :loading="loading"
        v-model="quiz.content.questions[current]"
        :questionI="current"
        :files="files"
        @deleteQuestion="deleteQuestion"
        @saveQuiz="saveQuiz"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .quiz-editor-wrapper{
   display: grid; 
   grid-template-columns: 300px calc(100% - 300px);
  }
</style>
