<script>
import CKEditorField from '@/components/fields/LocalizedCKEditor'
import LocalizedTextField from '@/components/fields/LocalizedText'
import TextField from '@/components/fields/Text'
import Checkbox from '@/components/fields/Checkbox'

export default {
  name: 'ActivityUrlRedirect',
  components:{
    CKEditorField,
    LocalizedTextField,
    TextField,
    Checkbox,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        name: {},
        description: {},
        url: '',
        blank: true,
      })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data(){
    return {
      content: this.modelValue
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler(next) {
        this.content = next
      }
    },
    content: {
      deep: true,
      handler(next) {
        //console.log(next)
        this.$emit('update:modelValue', next)
      }
    }
  },
  created() {
    let upd = false
    if (!this.content.name) {
      this.content.name = {}
      upd = true
    }
    if (!this.content.description) {
      this.content.description = {}
      upd = true
    }
    if (!this.content.url) {
      this.content.url = ''
      upd = true
    }
    if (typeof this.content.blank === 'undefined') {
      this.content.blank = true
      upd = true
    }
    if (upd)
      this.$emit('update:modelValue', this.content)
  },
  setup() {
    return {}
  },
  methods: {
    deleteMedia() {
      console.log('delete media')
    }
  }
}
</script>

<template>
  <div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.name') }}
      </div>
      <div>
        <LocalizedTextField :modelValue="content.name" />
      </div>
    </div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.description') }}
      </div>
      <div class="">
        <CKEditorField v-model="content.description"/>
      </div>
    </div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.url') }}
      </div>
      <div>
        <TextField v-model="content.url" />
      </div>
    </div>
     <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.blank') }}
      </div>
      <Checkbox
        :disabled="$store.state.ui.busy"
        v-model="content.blank"
      />
    </div>


  </div>
</template>
