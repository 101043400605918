<script>
import LocalizedTextField from '@/components/fields/LocalizedText'
import CKEditorField from '@/components/fields/LocalizedCKEditor'
import LocalizedDropdownField from '@/components/fields/LocalizedDropdown'

export default {
  name: 'ActivityScreen',
  components:{
    CKEditorField,
    LocalizedTextField,
    LocalizedDropdownField,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        name: {},
        description: {},
        files: {}
      })
    },
    loading: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'new'
    },
    activityId: {
      type: String,
      default: ''
    },
    files: {
      type: Object,
      default: () => ({})
    },
    mediaCollection: {
      required: true,
      validate: val => typeof val === 'object' || val === null
    }
  },
  emits: ['update:modelValue'],
  data(){
    return {
      content: this.modelValue
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler(next) {
        this.content = next
      }
    },
    content: {
      deep: true,
      handler(next) {
        //console.log(next)
        this.$emit('update:modelValue', next)
      }
    }
  },
  created() {
    let upd = false
    if (!this.content.name) {
      this.content.name = {}
      upd = true
    }
    if (!this.content.description) {
      this.content.description = {}
      upd = true
    }
    if (!this.content.files) {
      this.content.files = {}
      upd = true
    }
    if (upd)
      this.$emit('update:modelValue', this.content)
  },
  setup() {
    return {}
  },
  computed: {
    bucket: () => process.env.VUE_APP_FIREBASE_STORAGE_SCORM_UPLOAD_BUCKET,
    filesOptions() {
      return (this.$store.getters['admin/scorm/list'] || []).reduce((acc,f) => {
        acc[f.id] = f.details.filename
        return acc
      }, {})
    }
  },
  methods: {
    deleteMedia() {
      console.log('delete media')
    }
  }
}
</script>

<template>
  <div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.name') }}
      </div>
      <div>
        <LocalizedTextField :modelValue="content.name" />
      </div>
    </div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.description') }}
      </div>
      <div class="">
        <CKEditorField v-model="content.description"/>
      </div>
    </div>
    <div
      class="create-activity-field mb-6"
    >
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.zipFile') }}
      </div>
      <div>
        <LocalizedDropdownField
          v-model="content.files"
          :disabled="$store.state.ui.busy"
          :options="filesOptions"
        />
      </div>
    </div>
 
  </div>
</template>

<style lang="scss" scoped>
.create-activity-field-checkbox{
  > span{
    vertical-align: middle;
  }
}
</style>
