<script>
import { ActivityType } from '@/lib/enums'

import ContentIcon from '@/assets/imgs/icons/admin/activity/content.svg'
import ScormIcon from '@/assets/imgs/icons/admin/activity/scorm.svg'
import MosaicIcon from '@/assets/imgs/icons/admin/activity/mosaic.svg'
import LiveboardIcon from '@/assets/imgs/icons/admin/activity/liveboard.svg'
import ScreenIcon from '@/assets/imgs/icons/admin/activity/screen.svg'

export default {
  name: 'ActivitiesList',
  components: {
    ScormIcon,
    MosaicIcon,
    ScreenIcon,
    LiveboardIcon,
    ContentIcon
  },
  computed: {
    activities() {
      return this.$store.getters['admin/otherActivities']
    }
  },
  setup: () => ({ ActivityType })
}
</script>

<template>
  <div class="quizes-list h-full overflow-hidden">
    <div class="quizes-list-wrapper h-full overflow-y-auto custom-scrollbar">
      <div class="px-5 py-6 d-flex align-items-center justify-content-between">
        <h3>{{$t('admin.activityPage.activityListPage.title')}}</h3>
        <router-link class="create-new-quize-button" :to="{name: 'ActivityCreate'}">
          {{$t('admin.activityPage.activityListPage.createButton')}}
        </router-link>
      </div>
      <v-container
        fluid
      >
        <v-row>
          <v-col cols="3" v-for="activity in activities" :key="activity.id">
            <router-link class="activity-link admin-shadow d-block px-4 py-5 rounded h-full word-break-all" :to="{name: 'ActivityEditor', params :{id: activity.id}}">
              <div class="mb-2 pr-12 pb-4 text-fb-navy">
                <ContentIcon class="type-icon" v-if="activity.type === ActivityType.Content || activity.type === ActivityType.UrlRedirect"/>
                <ScormIcon class="type-icon" v-if="activity.type === ActivityType.SCORM"/>
                <LiveboardIcon class="type-icon" v-if="activity.type === ActivityType.LiveBoard"/>
                <MosaicIcon class="type-icon" v-if="activity.type === ActivityType.Mosaic"/>
                <ScreenIcon class="type-icon" v-if="activity.type === ActivityType.Map || activity.type === ActivityType.SessionMap || activity.type === ActivityType.Screen"/>
                <span class="activity-name">{{$l(activity.content.name)}}</span>
              </div>
              <div class="font-size-14 mb-2 word-break-all text-grey-2d">
                <p :innerHTML="$l(activity.content.description)"/>
              </div>
            </router-link>
          </v-col>
        </v-row>
 
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quizes-list-wrapper{
  padding: 20px;
}
.activity-link {
  color: inherit;
  position: relative;

  &:active{
    color: inherit;
  }
  &:hover, &.router-link-exact-active{
    background-color: rgb(var(--v-theme-admin-grey-3));
  }

  .type-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.2;
  }


}
.create-new-quize-button{
  background-color: rgb(var(--v-theme-fb-navy));
  color: rgb(var(--v-theme-white));
  padding: 10px 20px;
  border-radius: 4px;
}
</style>
