export default {
  /** Campus Scene Touchpoints */
  campus: [{
    id: 1, // id is NOT index at array. it's id recieved from Playcanvas
    name: '1 Hacker Way Sign'
  },{
    id: 2,
    name: 'Stage Screen'
  },{
    id: 3,
    name: 'Info Kiosk'
  },{
    id: 4,
    name: 'Breakout Kiosk'
  }],
  /** Store Scene Touchpoints */
  store: [{
    id: 1,
    name: 'Big monitor'
  },{
    id: 2,
    name: 'Oculus table screen'
  },{
    id: 3,
    name: 'Table card (all-in-one) on corner'
  },{
    id: 4,
    name: 'Table card (all-in-one) on center'
  },{
    id: 5,
    name: 'Oculus device on bar table'
  },{
    id: 6,
    name: 'Small monitor'
  },{
    id: 7,
    name: 'Montor on product wall'
  },{
    id: 8,
    name: 'Big portal device on table (corner)'
  },{
    id: 9,
    name: 'Small portal device on table (corner)'
  },{
    id: 10,
    name: 'Big portal device on table (middle)'
  },{
    id: 11,
    name: 'Oculus Big Box on wall shelf'
  },{
    id: 12,
    name: ''
  },{
    id: 13,
    name: ''
  },{
    id: 14,
    name: 'Oculus device on coffee table'
  },{
    id: 15,
    name: 'Small portal device on shells'
  }],
  /** Office Scene Touchpoints */
  office: [{
    id: 1,
    name: 'Mosaic mural'
  },{
    id: 2,
    name: 'Card "Upgrade your experience" (left)'
  },{
    id: 3,
    name: 'Small Portal device (left)'
  },{
    id: 4,
    name: 'Big portal device (left)'
  //},{
    //id: 5,
    //name: 'Desk card (all-in-one)'
  },{
    id: 6,
    name: 'Oculus product box'
  //},{
    //id: 7,
    //name: 'Wall horizotal card (all-in-one)'
  },{
    id: 8,
    name: 'Wall vertical card (all-in-one)'
  },{
    id: 9,
    name: 'Oculus device'
  //},{
    //id: 10,
    //name: 'Card "Upgrade your experience" (right)'
  },{
    id: 11,
    name: 'Big portal device (right)'
  },{
    id: 12,
    name: 'Small Portal device (right)'
  },{
    id: 13,
    name: 'Office PC'
  }],
  /** Warehouse Scene Touchpoints */
  warehouse: [{
    id: 1,
    name: 'Portal+ (Best Buy US)'
  },{
    id: 2,
    name: 'Portal Go (Best Buy US)'
  },{
    id: 3,
    name: 'Portal 10" (Best Buy US)'
  },{
    id: 4,
    name: 'Portal TV (Best Buy US)'
  },{
    id: 5,
    name: 'Back screen (Best Buy US)'
  },{
    id: 6,
    name: 'Portal Go(Target US)'
  },{
    id: 7,
    name: 'Portal 10" (Target US)'
  },{
    id: 8,
    name: 'Portal TV (Target US)'
  },{
    id: 9,
    name: 'Back screen (Target US)'
  },{
    id: 10,
    name: 'Portal Go (Selfridges GB)'
  },{
    id: 11,
    name: 'Portal 10" (Selfridges GB)'
  },{
    id: 12,
    name: 'Portal TV (Selfridges GB)'
  },{
    id: 13,
    name: 'Back screen (Selfridges GB)'
  },{
    id: 14,
    name: 'Portal+ (Dixons UK)'
  },{
    id: 15,
    name: 'Portal Go (Dixons UK)'
  },{
    id: 16,
    name: 'Portal 10" (Dixons UK)'
  },{
    id: 17,
    name: 'Portal TV (Dixons UK)'
  },{
    id: 18,
    name: 'Back screen (Dixons UK)'
  },{
    id: 19,
    name: 'Portal+ (FNAC FR)'
  },{
    id: 20,
    name: 'Portal Go (FNAC FR)'
  },{
    id: 21,
    name: 'Portal TV (FNAC FR)'
  },{
    id: 22,
    name: 'Back screen (FNAC FR)'
  },{
    id: 23,
    name: 'Portal+ (Boulanger FR)'
  },{
    id: 24,
    name: 'Portal Go (Boulanger FR)'
  },{
    id: 25,
    name: 'Portal TV (Boulanger FR)'
  },{
    id: 26,
    name: 'Back screen (Boulanger FR)'
  },{
    id: 27,
    name: 'Portal+ (Boulanger FR 2)'
  },{
    id: 28,
    name: 'Portal GO (Boulanger FR 2)'
  },{
    id: 29,
    name: 'Portal 10" (Boulanger FR 2)'
  },{
    id: 30,
    name: 'Portal TV (Boulanger FR 2)'
  },{
    id: 31,
    name: 'Back screen (Boulanger FR 2)'
  },{
    id: 32,
    name: 'Portal Go (FNAC FR)'
  },{
    id: 33,
    name: 'Portal TV (FNAC FR)'
  },{
    id: 34,
    name: 'Wall TV'
  }],
  /** Lounge (pool/rooftop) Scene Touchpoints */
  pool: [{
    id: 1,
    name: 'Write something wall'
  },{
    id: 2,
    name: 'Presentation Screen (big)'
  },{
    id: 3,
    name: 'TV Screen (small)'
  },{
    id: 4,
    name: 'Portal Device'
  },{
    id: 5,
    name: 'Qculus Device'
  },{
    id: 6,
    name: 'Portal Floatie'
  },{
    id: 7,
    name: 'Portal Device (at bar)'
  },{
    id: 8,
    name: 'Oculus Quest Product Box (small)'
  },{
    id: 9,
    name: 'Oculus Quest Product Box (big)'
  }],
  /** Loft Scene Touchpoints */
  loft: [{
    id: 1,
    name: 'Television Screen'
  },{
    id: 2,
    name: 'Television Screen (2nd floor)'
  },{
    id: 3,
    name: 'Television Screen (dining table)'
  },{
    id: 4,
    name: 'Portal Device (coffee table)'
  },{
    id: 5,
    name: 'Info Card (coffee table)'
  },{
    id: 6,
    name: 'Oculus device (coffee table)'
  },{
    id: 7,
    name: 'Portal Device (kitchen island)'
  },{
    id: 8,
    name: 'Oculus box (kitchen island)'
  },{
    id: 9,
    name: 'Info Card (kitchen island)'
  }],
  /** Apartment Scene Touchpoints */
  apartment: [{
    id: 1,
    name: 'Portal Device (at island)'
  },{
    id: 2,
    name: 'Oculus Quest Product Box'
  },{
    id: 3,
    name: 'Oculus Art Piece'
  },{
    id: 4,
    name: 'TV Screen'
  },{
    id: 5,
    name: 'Portal Device (at coffee table)'
  },{
    id: 6,
    name: 'Portal Device (at corner table)'
  },{
    id: 7,
    name: 'Oculus Quest Product Box (at corner table)'
  },{
    id: 8,
    name: 'Portal Device (at dining table)'
  },{
    id: 9,
    name: 'Picture (top left)'
  },{
    id: 10,
    name: 'Picture (bottom left)'
  },{
    id: 11,
    name: 'Picture (top)'
  },{
    id: 12,
    name: 'Picture (bottom)'
  },{
    id: 13,
    name: 'Picture (top right)'
  }],
}
