<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

import SceneIcon from '@/components/SceneIcon'

export default {
  name: "CalendarDay",
  components: {
    SceneIcon
  },
  props: {
    day: {
      type: Object,
      required: true
    },
    events: {
      type: Array
    },
    tz: {
      type: Object,
      required: true
    }
  },
  emits:['createCalenderEvent', 'selectCalenderEvent'],
  computed: {
    label() {
      //console.log('label', this.day)
      return dayjs(this.day.date).format("D");
    },
    myEvents() {
      return this.events.filter(ev => {
        const d = dayjs(this.day.date + ' 00:00 ' + this.tz.utc)
        const de = dayjs.unix(ev.start.seconds).tz(this.tz.tz)
        return de.isSame(d, 'day')
      })
    },
    isPast() {
      const d = dayjs(this.day.date, 'YYYY-MM-DD')
      const today = dayjs().hour(0).minute(0)
      return d.isBefore(today)
    },
    isToday() {
      const d = dayjs(this.day.date, 'YYYY-MM-DD')
      return dayjs().isSame(d, 'day')
    }
  },
  methods: {
    createcalenderEvent(date){
      this.$emit('createCalenderEvent', date)
    },
    getTime(unix) {
      const d = dayjs.unix(unix).tz(this.tz.tz)
      return d.format('hh:mm A')
    }
  },
  created() {
    // console.log(this.day)
  }
};
</script>

<template>
  <div
    class="calendar-day position-relative"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday
    }"
  >
  <div class="calendar-day-label">
    <span>{{ label }}</span>
    <v-btn
      v-if="!isPast"
      flat
      elevation="0"
      icon="mdi-plus"
      size="x-small"
      color="primary"
      class="add-event-button"
      :disabled="$store.state.ui.busy"
      @click="createcalenderEvent(day.date)"
    />
  </div>
  <div v-if="myEvents.length" class="events-stack mt-2">
    <div
      @click="$emit('selectCalenderEvent', {event:ev, date: day.date})"
      v-for="ev in myEvents"
      :key="ev.id"
      class="d-flex align-items-center calendar-day-event rounded text-white ma-1 px-2 py-1 font-size-12"
      style="background-color:red;"
      :title="`[${$t(`scenes.${ev.content.scene}`)}] - ${getTime(ev.start.seconds)} ${$l(ev.content.name)}`"
    >
      <SceneIcon :scene="ev.content.scene" class="scene-icon"/>
      <div class="flex-shrink-1 mr-2 events-stack-event-time">{{getTime(ev.start.seconds)}}</div>
      <div class="events-stack-event-title flex-grow-1 overflow-hidden text-truncate">{{$l(ev.content.name)}}</div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.calendar-day {

  .calendar-day-event {
    cursor: pointer;
    position: relative;
    height: 30px;
    overflow: hidden;


    .scene-icon {
      width: 25px;
      height: 25px;
      position: relative;
      top: -2.5px;
      left: -2.5px;
    }

  }
  .calendar-day-label{
    position: relative;
  }
  &:hover{
    .add-event-button{
      opacity: 1;
    }
  }

  .add-event-button {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }
  .calendar-day--not-current{
    .calendar-day-label{
      color:rgb(var(--v-theme-grey-200))
    }
  }
  &-label{
    text-align: center;
    > span{
      display: inline-block;
      width:32px;
      height:32px;
      border-radius: 50%;
      line-height: 32px;
    }
  }
  &--today{
    .calendar-day-label{
      > span{
        background-color: rgb(var(--v-theme-primary));
        color: rgb(var(--v-theme-white));
      }
    }
  }
}
.events-stack svg{
  flex-shrink: 0;
}
.events-stack-event-time{
  white-space: nowrap;
}
.events-stack-event-title{
  max-width: 70px;
}

</style>
