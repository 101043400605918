<script>
export default {
  name: 'Quizes'
}
</script>

<template>
  <div class="quiz-wrapper h-full admin-page-block bg-white admin-shadow admin-rounded shadow overflow-hidden">
    <router-view name="quizUI"/>
  </div>
</template>

<style lang="scss" scoped>

</style>