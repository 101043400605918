<script>
import { ActivityType } from '@/lib/enums'

import Quiz2DIcon from '@/assets/imgs/icons/admin/activity/quiz2d.svg'
import Quiz3DIcon from '@/assets/imgs/icons/admin/activity/quiz3d.svg'

export default {
  name: 'QuizesList',
  components: {
    Quiz2DIcon,
    Quiz3DIcon
  },
  setup: () => ({ ActivityType }),
  computed: {
    quizzes() {
      return this.$store.getters['admin/quizes']
    }
  }
}
</script>

<template>
  <div class="quizes-list h-full overflow-hidden">
    <div class="quizes-list-wrapper h-full overflow-y-auto custom-scrollbar">
      <div class="px-5 py-6 d-flex align-items-center justify-content-between">
        <h3>{{$t('admin.quizesPage.quizListPage.title')}}</h3>
        <router-link class="create-new-quize-button" :to="{name: 'QuizCreate'}">
          {{$t('admin.quizesPage.quizListPage.createQuizButtonText')}}
        </router-link>
      </div>
      <v-container
        fluid
      >
        <v-row>
          <v-col cols="3" v-for="quiz in quizzes" :key="quiz.id">
            <router-link class="quiz-link admin-shadow d-block px-4 py-5 rounded word-break-all" :to="{name: 'QuizEditor', params :{id: quiz.id}}">
              <div class="font-size-18 mb-2 pr-12 pb-4 text-fb-navy">
                <Quiz2DIcon class="type-icon" v-if="quiz.type === ActivityType.Quizz2D"/>
                <Quiz3DIcon class="type-icon" v-if="quiz.type === ActivityType.Quizz3D"/>
                <span>{{$l(quiz.content.name)}}</span>
              </div>
              <div class="text-grey-2d font-size-14">
                <div>{{$t('admin.quizesPage.quizListPage.peopleAllowed')}} : <b class="font-size-14">{{quiz.content.maxPlayers}}</b></div>
                <div>{{$t('admin.quizesPage.quizEditor.sidebar.title')}} : <b class="font-size-14">{{quiz.content.questions.length}}</b></div>
              </div>
              <p class="text-grey-2d font-size-14 mt-3 word-break-all" :innerHTML="$l(quiz.content.description)" />
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quizes-list-wrapper{
  padding: 20px;
}
.quiz-link{
  color: inherit;
  position: relative;

  &:active{
    color: inherit;
  }
  &:hover, &.router-link-exact-active{
    background-color: rgb(var(--v-theme-admin-grey-3));
  }

  .type-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.2;
  }
}
.create-new-quize-button{
  background-color: rgb(var(--v-theme-fb-navy));
  color: rgb(var(--v-theme-white));
  padding: 10px 20px;
  border-radius: 4px;
}


</style>
