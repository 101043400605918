<script>
import CKEditorField from '@/components/fields/LocalizedCKEditor'
import LocalizedTextField from '@/components/fields/LocalizedText'

export default {
  name: 'ActivityMap',
  components:{
    CKEditorField,
    LocalizedTextField,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        name: {},
        description: {},
      })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data(){
    return {
      content: this.modelValue
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler(next) {
        this.content = next
      }
    },
    content: {
      deep: true,
      handler(next) {
        //console.log(next)
        this.$emit('update:modelValue', next)
      }
    }
  },
  created() {
    let upd = false
    if (!this.content.name) {
      this.content.name = {}
      upd = true
    }
    if (!this.content.description) {
      this.content.description = {}
      upd = true
    }
    if (upd)
      this.$emit('update:modelValue', this.content)
  },
  setup() {
    return {}
  }
}
</script>

<template>
  <div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.name') }}
      </div>
      <div>
        <LocalizedTextField :modelValue="content.name" />
      </div>
    </div>
    <div class="create-activity-field mb-6">
      <div>
        {{ $t('admin.activityPage.createActivityPage.fileds.description') }}
      </div>
      <div class="">
        <CKEditorField v-model="content.description"/>
      </div>
    </div>
 
  </div>
</template>
